import React from "react";
import { AppBar, Toolbar, Grid, Avatar, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../contexts/AuthContext";
import NavButtonBar from "./NavButtonBar";
import NavDrawer from "./NavDrawer";
import { localVersion } from "../../config/localVersion";
import logo from "../../assets/kbaselogo.png";

const KbNavbar = () => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const engageDrawer = useMediaQuery("(max-width:1010px)");
  const tight = useMediaQuery(theme.breakpoints.down("md"));
  const tighter = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar position='fixed'>
      <Toolbar style={{ height: "65px" }}>
        <Grid item container direction='row' alignItems='center'>
          <Grid item container direction='column' xs={3}>
            <Grid item container direction='row' alignItems='center'>
              <Grid item>
                <Typography
                  variant='body2'
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {`v${localVersion}`}
                </Typography>
              </Grid>
              <Grid item>
                {currentUser && (
                  <Grid
                    item
                    container
                    direction='row'
                    alignItems='left'
                    spacing='6'
                  >
                    <Grid item>
                      <Avatar
                        alt='logo'
                        src={logo}
                        sx={{
                          width: tighter ? 40 : tight ? 45 : 50,
                          height: tighter ? 40 : tight ? 45 : 50,
                        }}
                      />
                    </Grid>
                    <Grid item xl={2} lg={3} sm={4} xs={4} align='center'>
                      <Typography
                        sx={{
                          fontSize: tighter ? 25 : tight ? 35 : 40,
                          fontWeight: 900,
                          fontFamily: "Architects Daughter",
                          color: "#C9DCD1",
                        }}
                      >
                        Kinderbase
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction='column' alignItems='flex-end' xs={9}>
            {engageDrawer ? <NavDrawer /> : <NavButtonBar />}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default KbNavbar;
