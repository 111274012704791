import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import RhymIdPanel from "./RhymIdPanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { LearnerContext } from "../../contexts/LearnerContext";
import { useContext, useEffect } from "react";

const RhymIdContainer = ({
  rhymIdScores,
  learnerId,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const [showNewRhymIdPanel, setShowNewRhymIdPanel] = useState(false);
  const { currentLearner } = useContext(LearnerContext);
  useEffect(() => {
    setShowNewRhymIdPanel(false);
  }, [currentLearner]);
  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">Rhyming Identification</Typography>
          </Grid>
          <Grid item>
            {showNewRhymIdPanel ? (
              <CancelButton onClick={() => setShowNewRhymIdPanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton onClick={() => setShowNewRhymIdPanel(true)}>
                Add New Score
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewRhymIdPanel && (
          <Grid item container direction="column">
            <RhymIdPanel
              purpose={"addNew"}
              learnerId={learnerId}
              setShowNewRhymIdPanel={setShowNewRhymIdPanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {rhymIdScores && rhymIdScores.length ? (
          _.orderBy(rhymIdScores, ["createdAt"], ["desc"]).map(
            (rhymIdScoreSet) => (
              <RhymIdPanel
                key={rhymIdScoreSet.createdAt}
                learnerId={learnerId}
                rhymIdScoreSet={rhymIdScoreSet}
                purpose={"view"}
                handleLoadingOpen={handleLoadingOpen}
                handleLoadingClose={handleLoadingClose}
              />
            )
          )
        ) : (
          <Typography>
            No Rhyming Identification Scores recorded yet...
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default RhymIdContainer;
