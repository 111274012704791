import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Container, Typography, Grid, TextField, Button } from "@mui/material";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import { useReactToPrint } from "react-to-print";
import LearnerScreenerReportContainer from "./LearnerScreenerReportContainer";
import PrintAllScreenedLearnersContainer from "./PrintAllScreenedLearnersContainer";
import { useAuth } from "../../contexts/AuthContext";
import { LearnerContext } from "../../contexts/LearnerContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import { LoadingContext } from "../../contexts/LoadingContext";

const LearnerScreeningSelector = () => {
  const { currentUser } = useAuth();
  const { setIsLoading } = useContext(LoadingContext);
  const { getScreeners } = useContext(TransactionContext);
  const { currentLearner, setCurrentLearnerId } = useContext(LearnerContext);
  const [selectedLearner, setSelectedLearner] = useState(null);
  const [learnersToPrintList, setLearnersToPrintList] = useState([]);
  const [learnerList, setLearnerList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetchScreeners = async () => {
    setIsLoading(true);
    const screeners = await getScreeners();
    setLearnerList(screeners);
    const listing = screeners.map((learner) => {
      return {
        learnerName: `${learner.lastName}, ${learner.firstName}`,
        learnerId: learner.learnerId,
      };
    });
    setIsLoading(false);
    setLearnerList(_.orderBy(listing, ["learnerName"], ["asc"]));
    setLearnersToPrintList(_.orderBy(screeners, ["lastName"], ["asc"]));
  };
  useEffect(() => {
    setIsLoading(false);
  }, [currentLearner]);
  useEffect(() => {
    setCurrentLearnerId(null);
    fetchScreeners();
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleLearnerSelect = (event, newLearner) => {
    if (newLearner) {
      setCurrentLearnerId(newLearner.learnerId);
    } else setCurrentLearnerId(null);
    setSelectedLearner(newLearner);
  };
  return (
    <Container>
      <Grid item container direction="column">
        <Grid item style={{ marginTop: "65px" }}>
          <Typography variant="h2">Learner Screening Reports</Typography>
        </Grid>
        {currentUser && learnerList.length ? (
          <Fragment>
            <Grid item style={{ marginTop: "20px" }}>
              <div>
                {currentUser && learnersToPrintList.length > 0 ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handlePrint();
                    }}
                  >
                    Print All Screened Learners
                  </Button>
                ) : null}
                <div style={{ display: "none" }}>
                  <PrintAllScreenedLearnersContainer
                    printReportComponent={componentRef}
                    learnersToPrintList={learnersToPrintList}
                  />
                </div>
              </div>
            </Grid>
            <Grid item style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Autocomplete
                id="learners"
                value={selectedLearner}
                onChange={handleLearnerSelect}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={learnerList}
                getOptionLabel={(option) => option.learnerName}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Learner"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {currentLearner && (
              <Grid item container>
                <LearnerScreenerReportContainer
                  currentLearner={currentLearner}
                />
              </Grid>
            )}
          </Fragment>
        ) : (
          <Typography component="p">No Data to Load...</Typography>
        )}
      </Grid>
    </Container>
  );
};

export default LearnerScreeningSelector;
