import React, { useContext, useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import _ from "lodash";
import LearnerScreenerReportPanel from "./LearnerScreenerReportPanel";
import { SystemSettingsContext } from "../../contexts/SystemSettingsContext";

const LearnerProgressReportContainer = ({ currentLearner }) => {
  const { systemSettings } = useContext(SystemSettingsContext);

  const [isAbleToGenerate, setIsAbleToGenerate] = useState(false);
  const [compiledLNUScores, setCompiledLNUScores] = useState([]);
  const [compiledLNLScores, setCompiledLNLScores] = useState([]);
  const [compiledLSIScores, setCompiledLSIScores] = useState([]);
  const [compiledSWScores, setCompiledSWScores] = useState([]);
  const [compiledRhymIdScores, setCompiledRhymIdScores] = useState([]);
  const [compiledRhymProdScores, setCompiledRhymProdScores] = useState([]);
  const [compiledNumIdScores, setCompiledNumIdScores] = useState([]);
  const [readingReadyScore, setReadingReadyScore] = useState([]);
  const [numberReadyScore, setNumberReadyScore] = useState([]);
  const [compiledBehaviorScores, setCompiledBehaviorScores] = useState([]);
  const [compiledComments, setCompiledComments] = useState([]);
  const [compiledCountingScores, setCompiledCountingScores] = useState([]);
  const [compiledFmScores, setCompiledFmScores] = useState([]);

  useEffect(() => {
    if (
      currentLearner.lnuScores &&
      currentLearner.lnuScores.length &&
      currentLearner.lnlScores &&
      currentLearner.lnlScores.length &&
      currentLearner.lsiScores &&
      currentLearner.lsiScores.length &&
      //   currentLearner.swScores &&
      //   currentLearner.swScores.length &&
      currentLearner.rhymIdScores &&
      currentLearner.rhymIdScores.length &&
      currentLearner.rhymProdScores &&
      currentLearner.rhymProdScores.length &&
      currentLearner.numIdScores &&
      currentLearner.numIdScores.length &&
      currentLearner.countingScores &&
      currentLearner.countingScores.length &&
      currentLearner.behaviorScores &&
      currentLearner.behaviorScores.length &&
      currentLearner.fmScores &&
      currentLearner.fmScores.length
    ) {
      // the report can be generated, so...
      setIsAbleToGenerate(true);

      // Compile Reading Readiness and NUmber Readiness for Screened Learners

      if (!currentLearner.numberReadiness && !currentLearner.readingReadiness) {
        // get latest lnl, lnu, and lsi scores
        const latestLNU = _.orderBy(currentLearner.lnuScores, [
          "createdAt",
          "asc",
        ]).pop();
        const latestLNL = _.orderBy(currentLearner.lnlScores, [
          "createdAt",
          "asc",
        ]).pop();
        const latestLSI = _.orderBy(currentLearner.lsiScores, [
          "createdAt",
          "asc",
        ]).pop();

        const rReadinessScore =
          latestLNU.score + latestLNL.score + latestLSI.score > 36
            ? {
                score: latestLNU.score + latestLNL.score + latestLSI.score,
                text: `${currentLearner.firstName} is overall prepared for Kindergarten in terms of reading readiness.`,
              }
            : {
                score: latestLNU.score + latestLNL.score + latestLSI.score,
                text: `${currentLearner.firstName} will require some practice to reach Kindergarten reading readiness.  Practice identifying uppercase and lowercase letters.  Practice identifying letter sounds from picture cues, for instance showing a picture of a monkey and having them tell you the letter it begins with.`,
              };

        // use the score values to compile the readiness set and return
        setReadingReadyScore(rReadinessScore);

        // get latest numId and counting scores
        const latestNumId = _.orderBy(currentLearner.numIdScores, [
          "createdAt",
          "asc",
        ]).pop();
        const latestCounting = _.orderBy(currentLearner.countingScores, [
          "createdAt",
          "asc",
        ]).pop();

        const nReadinessScore =
          parseInt(latestCounting.score, 10) + parseInt(latestNumId.score, 10) >
          16
            ? {
                text: `${currentLearner.firstName} is overall prepared for kindergarten in terms of number readiness.`,
                score:
                  parseInt(latestCounting.score, 10) +
                  parseInt(latestNumId.score, 10),
              }
            : {
                text: `${currentLearner.firstName} will require some practice to reach kindergarten number readiness.  Practice identifying the numbers 0-9.`,
                score:
                  parseInt(latestCounting.score, 10) +
                  parseInt(latestNumId.score, 10),
              };

        // use the score values to compile the readiness set and return
        setNumberReadyScore(nReadinessScore);
      }

      // compile the LNU score set for display
      const lnuScores =
        currentLearner.lnuScores && currentLearner.lnuScores.length
          ? currentLearner.lnuScores.map((lnuScoreSet) => {
              return {
                createdAt: lnuScoreSet.createdAt,
                score: lnuScoreSet.score,
                missedLetters: determineMissedLetters(lnuScoreSet),
              };
            })
          : null;
      setCompiledLNUScores(lnuScores);

      // compile the LNL score set for display
      const lnlScores = currentLearner.lnlScores.map((lnlScoreSet) => {
        return {
          createdAt: lnlScoreSet.createdAt,
          score: lnlScoreSet.score,
          missedLetters: determineMissedLetters(lnlScoreSet),
        };
      });
      setCompiledLNLScores(lnlScores);

      // compile the LSI score set for display
      const lsiScores = currentLearner.lsiScores.map((lsiScoreSet) => {
        return {
          createdAt: lsiScoreSet.createdAt,
          score: lsiScoreSet.score,
          missedLetters: determineMissedLetters(lsiScoreSet),
        };
      });
      setCompiledLSIScores(lsiScores);

      // compile the SW score set for display
      const swScores = currentLearner.swScores.map((swScoreSet) => {
        return {
          createdAt: swScoreSet.createdAt,
          score: swScoreSet.score,
          missedWords: determineMissedIrregularWords(swScoreSet),
        };
      });
      setCompiledSWScores(swScores);

      // compile the Rhyming Id score set for display
      // Add variable Text based on Score
      const rhymIdScores = currentLearner.rhymIdScores.map((rhymIdScoreSet) => {
        return {
          createdAt: rhymIdScoreSet.createdAt,
          score: rhymIdScoreSet.score,
          missedWords: determineMissedRhymIdWords(rhymIdScoreSet),
          text:
            rhymIdScoreSet.score > 4
              ? `${currentLearner.firstName} was able to successfully identify rhyming and non-rhyming words.`
              : `${currentLearner.firstName} could use some practice identifying rhyming words such as he/me and dad/sad.  ${currentLearner.firstName} should also be able to identify a word pair such as house/moon do not rhyme.`,
        };
      });
      setCompiledRhymIdScores(rhymIdScores);

      // compile the Rhyming Prod score set for display
      const rhymProdScores = currentLearner.rhymProdScores.map(
        (rhymProdScoreSet) => {
          return {
            createdAt: rhymProdScoreSet.createdAt,
            score: rhymProdScoreSet.score,
            missedWords: determineMissedRhymProdWords(rhymProdScoreSet),
            text:
              rhymProdScoreSet.score > 4
                ? `${currentLearner.firstName} was able to successfully produce rhyming and non-rhyming words.`
                : `${currentLearner.firstName} could use some practice producing rhyming a word with a given word. For instance if "top" is the given word, responding with a word that rhymes with top like "mop."`,
          };
        }
      );
      setCompiledRhymProdScores(rhymProdScores);

      // compile the Comment set for display
      const compiledComments = currentLearner.commScores.map((comments) => {
        return {
          createdAt: comments.createdAt,
          comment: comments.generalComment,
        };
      });
      setCompiledComments(compiledComments);

      // compile the counting score set for display
      const compiledCountingScores = currentLearner.countingScores.map(
        (countingScoreSet) => {
          return {
            createdAt: countingScoreSet.createdAt,
            score: countingScoreSet.score,
            oneToOneMatching: countingScoreSet.oneToOne,
          };
        }
      );
      setCompiledCountingScores(compiledCountingScores);

      // compile the numId score set for display
      const numIdScores = currentLearner.numIdScores.map((numIdScoreSet) => {
        return {
          createdAt: numIdScoreSet.createdAt,
          score: numIdScoreSet.score,
          missedNumbers: determineMissedNumbers(numIdScoreSet),
        };
      });
      setCompiledNumIdScores(numIdScores);

      // compile the behavior score set for display
      const behaviorScores = currentLearner.behaviorScores.map(
        (behaviorScoreSet) => {
          return {
            createdAt: behaviorScoreSet.createdAt,
            isUnsatisfactory: behaviorScoreSet.behaviorIsUnsatisfactory
              ? "Unsatisfactory"
              : "Satisfactory",
            score: behaviorScoreSet.behaviorIsUnsatisfactory
              ? `${currentLearner.firstName} exhibited the following behavior(s) while performing tasks. `
              : `${currentLearner.firstName} appears socially and emotionally prepared for Kindergarten. `,
            missed: determineMissedBehaviors(behaviorScoreSet),
          };
        }
      );
      setCompiledBehaviorScores(behaviorScores);

      // compile the Fine Motor score set for display
      // Add variable Text based on Score
      const fmScores = currentLearner.fmScores.map((fmScoreSet) => {
        return {
          createdAt: fmScoreSet.createdAt,
          dominant:
            fmScoreSet.fmDominantHand.charAt(0).toUpperCase() +
            fmScoreSet.fmDominantHand.slice(1),
          isUnsatisfactory: fmScoreSet.fmIsUnsatisfactory
            ? "Unsatisfactory"
            : "Satisfactory",
          text: fmScoreSet.fmIsUnsatisfactory
            ? `${
                currentLearner.firstName
              } will require some practice for Kindergarten in terms of fine motor ability.  ${determineMissedFMScores(
                fmScoreSet
              )}`
            : `${
                currentLearner.firstName
              } is overall prepared for Kindergarten in terms of fine motor ability.  ${determineMissedFMScores(
                fmScoreSet
              )}`,
          missed: determineMissedFMScores(fmScoreSet),
        };
      });
      setCompiledFmScores(fmScores);
    } else {
      setIsAbleToGenerate(false);
    }
  }, [currentLearner]);

  // determines missed sight words from a literacy score set
  const determineMissedIrregularWords = (scoreSet) => {
    const missedWordsArray = _.compact(
      systemSettings.irregularWords.map((sightWord) => {
        const word = sightWord.toLowerCase();
        if (scoreSet[`${word}IsMissed`]) {
          return word[0].toUpperCase() + word.substr(1);
        }
        return null;
      })
    );
    return missedWordsArray.toString();
  };

  // determine words missed from Rhyming Id Score Set
  const determineMissedRhymIdWords = (scoreSet) => {
    const missedWordsArray = [];
    if (scoreSet.bedFedMissed) {
      missedWordsArray.push("bed/fed");
    }
    if (scoreSet.beepDeepMissed) {
      missedWordsArray.push("beep/deep");
    }
    if (scoreSet.dadSadMissed) {
      missedWordsArray.push("dad/sad");
    }
    if (scoreSet.handCarMissed) {
      missedWordsArray.push("hand/car");
    }
    if (scoreSet.houseMoonMissed) {
      missedWordsArray.push("house/moon");
    }
    if (scoreSet.pigBigMissed) {
      missedWordsArray.push("pig/big");
    }
    if (scoreSet.sunCakeMissed) {
      missedWordsArray.push("sun/cake");
    }

    return missedWordsArray.toString();
  };

  // determine Fine Motor Score Set
  const determineMissedFMScores = (scoreSet) => {
    const missedFMScoresArray = [];
    if (scoreSet.fmCutHold === "awkward" || scoreSet.fmCutLine === "unable") {
      missedFMScoresArray.push(
        `${currentLearner.firstName} should practice holding scissors correctly and cutting along a line. `
      );
    }
    // if (scoreSet.fmCutLine === "unable") {
    //   missedFMScoresArray.push(
    //     `${currentLearner.firstName} is unable to cut a straight line. `
    //   );
    // }
    if (
      scoreSet.fmWritingName === "noLetters" ||
      scoreSet.fmWritingName === "atLeast2Letters" ||
      scoreSet.fmPenGrasp === "awkward"
    ) {
      missedFMScoresArray.push(
        `${currentLearner.firstName} should practice writing first name. `
      );
    }
    // if (scoreSet.fmWritingName === "atLeast2Letters") {
    //   missedFMScoresArray.push(
    //     `${currentLearner.firstName} could write at least two letters of their name. `
    //   );
    // }
    // if (scoreSet.fmPenGrasp === "awkward") {
    //   missedFMScoresArray.push(`${currentLearner.firstName} awkwardly holds a pen. `);
    // }
    if (
      (scoreSet.circleIsMissed === true || scoreSet.rectIsMissed === true,
      scoreSet.triangleIsMissed === true ||
        scoreSet.crossIsMissed === true ||
        scoreSet.dashIsMissed === true)
    ) {
      missedFMScoresArray.push(
        `${currentLearner.firstName} should practice drawing or tracing the outline of shapes, especially circles, squares, rectangles and triangles. `
      );
    }
    // if (scoreSet.circleIsMissed === true) {
    //   missedFMScoresArray.push(`circle, `);
    // }
    // if (scoreSet.crossIsMissed === true) {
    //   missedFMScoresArray.push(`cross, `);
    // }
    // if (scoreSet.triangleIsMissed === true) {
    //   missedFMScoresArray.push(`triangle, `);
    // }
    // if (scoreSet.rectisMissed === true) {
    //   missedFMScoresArray.push(`rectangle, `);
    // }
    // if (scoreSet.dashIsMissed === true) {
    //   missedFMScoresArray.push("dash, ");
    // }

    return missedFMScoresArray.join(" ");
  };

  // determine Behavior Score Set
  const determineMissedBehaviors = (scoreSet) => {
    const missedBehaviorsArray = [];
    if (scoreSet.behaviorCrying === "yes") {
      missedBehaviorsArray.push(
        `${currentLearner.firstName} exhibited crying during tasks. `
      );
    }
    if (scoreSet.behaviorImpulsive === "yes") {
      missedBehaviorsArray.push(
        "Impulsive behaviors were significantly present while performing tasks. "
      );
    }
    if (scoreSet.behaviorNeedsPrompting === "yes") {
      missedBehaviorsArray.push(
        "Repeated prompts were needed during testing. "
      );
    }
    if (scoreSet.behaviorNotTrying === "yes") {
      missedBehaviorsArray.push(
        `${currentLearner.firstName} was unwilling to perform some of the tasks. `
      );
    }
    if (scoreSet.behaviorSeparating === "yes") {
      missedBehaviorsArray.push(
        `${currentLearner.firstName} had difficulty leaving their guardian to perform screening tasks. `
      );
    }
    if (scoreSet.behaviorTaskDifficulty === "yes") {
      missedBehaviorsArray.push(
        `${currentLearner.firstName} had trouble staying on task. `
      );
    }
    if (scoreSet.behaviorWiggling === "yes") {
      missedBehaviorsArray.push(
        `${currentLearner.firstName} exhibited notable fidgeting while trying to complete tasks.`
      );
    }

    return missedBehaviorsArray.join(" ");
  };

  // determine words missed from Rhyming Prod Score Set
  const determineMissedRhymProdWords = (scoreSet) => {
    const missedWordsArray = [];
    if (scoreSet.henMissed) {
      missedWordsArray.push("hen");
    }
    if (scoreSet.mugMissed) {
      missedWordsArray.push("mug");
    }
    if (scoreSet.panMissed) {
      missedWordsArray.push("pan");
    }
    if (scoreSet.topMissed) {
      missedWordsArray.push("top");
    }
    if (scoreSet.wigMissed) {
      missedWordsArray.push("wig");
    }

    return missedWordsArray.toString();
  };

  // determines missing letters from a literacy score set
  const determineMissedLetters = (scoreSet) => {
    const missedLetterArray = [];
    if (scoreSet.aIsMissed) {
      missedLetterArray.push("a");
    }
    if (scoreSet.bIsMissed) {
      missedLetterArray.push("b");
    }
    if (scoreSet.cIsMissed) {
      missedLetterArray.push("c");
    }
    if (scoreSet.dIsMissed) {
      missedLetterArray.push("d");
    }
    if (scoreSet.eIsMissed) {
      missedLetterArray.push("e");
    }
    if (scoreSet.fIsMissed) {
      missedLetterArray.push("f");
    }
    if (scoreSet.gIsMissed) {
      missedLetterArray.push("g");
    }
    if (scoreSet.hIsMissed) {
      missedLetterArray.push("h");
    }
    if (scoreSet.iIsMissed) {
      missedLetterArray.push("i");
    }
    if (scoreSet.jIsMissed) {
      missedLetterArray.push("j");
    }
    if (scoreSet.kIsMissed) {
      missedLetterArray.push("k");
    }
    if (scoreSet.lIsMissed) {
      missedLetterArray.push("l");
    }
    if (scoreSet.mIsMissed) {
      missedLetterArray.push("m");
    }
    if (scoreSet.nIsMissed) {
      missedLetterArray.push("n");
    }
    if (scoreSet.oIsMissed) {
      missedLetterArray.push("o");
    }
    if (scoreSet.pIsMissed) {
      missedLetterArray.push("p");
    }
    if (scoreSet.qIsMissed) {
      missedLetterArray.push("q");
    }
    if (scoreSet.rIsMissed) {
      missedLetterArray.push("r");
    }
    if (scoreSet.sIsMissed) {
      missedLetterArray.push("s");
    }
    if (scoreSet.tIsMissed) {
      missedLetterArray.push("t");
    }
    if (scoreSet.uIsMissed) {
      missedLetterArray.push("u");
    }
    if (scoreSet.vIsMissed) {
      missedLetterArray.push("v");
    }
    if (scoreSet.wIsMissed) {
      missedLetterArray.push("w");
    }
    if (scoreSet.xIsMissed) {
      missedLetterArray.push("x");
    }
    if (scoreSet.yIsMissed) {
      missedLetterArray.push("y");
    }
    if (scoreSet.zIsMissed) {
      missedLetterArray.push("z");
    }

    return missedLetterArray.toString();
  };

  // determines missing numbers from a numeracy score set
  const determineMissedNumbers = (scoreSet) => {
    const missedNumbersArray = [];
    for (let i = 0; i < 21; i++) {
      if (scoreSet[`missed${i}`]) {
        missedNumbersArray.push(`${i}`);
      }
    }

    return missedNumbersArray.toString();
  };

  return isAbleToGenerate ? (
    <LearnerScreenerReportPanel
      currentLearner={currentLearner}
      readingReadyScore={readingReadyScore}
      numberReadyScore={numberReadyScore}
      compiledLNUScores={compiledLNUScores}
      compiledLNLScores={compiledLNLScores}
      compiledLSIScores={compiledLSIScores}
      compiledSWScores={compiledSWScores}
      compiledRhymIdScores={compiledRhymIdScores}
      compiledRhymProdScores={compiledRhymProdScores}
      compiledNumIdScores={compiledNumIdScores}
      compiledBehaviorScores={compiledBehaviorScores}
      compiledComments={compiledComments}
      compiledCountingScores={compiledCountingScores}
      compiledFmScores={compiledFmScores}
    />
  ) : (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="h6">
          Not all scores are recorded to generate this report...
        </Typography>
      </Grid>
      <Grid item xs={5} style={{ marginTop: "20px" }}>
        <Typography variant="body1">
          At lease one of each of the following score sets are required for a
          complete progress report for a learner: Uppercase Letternames,
          Lowercase Letternames, Letter Sounds, Sight Words, Rhyming
          Identification, Rhyming Production, Number Identification, Number
          Counting, Behavior Observations, Fine Motor Ability.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LearnerProgressReportContainer;
