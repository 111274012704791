import React, { PureComponent } from "react";

export default class PrintAllScreenedLearners extends PureComponent {
  render() {
    const { learnerList } = this.props;
    return (
      <div>
        {learnerList.map((learner) => (
          <div key={learner.learnerId} style={{ pageBreakAfter: "always" }}>
            <h1>{`KinderBase Student Screening Report for ${learner.firstName} ${learner.lastName}`}</h1>
            Birthday: {new Date(learner.birthday).toLocaleString().slice(0, 9)}{" "}
            Recommend Kindergarten Camp: {learner.campRecommend ? "Yes" : "No"}
            <p>
              Kinderbase is a system that we use in the Aurora Schools to screen
              and track the progress of all learners and their early
              foundational skills. This report summarizes your child's screening
              results and shows you what we will be targeting at the beginning
              of kindergarten. This screening report is an opportunity to get a
              glimpse into what your child already knows and what we will target
              during the first months of their educational journey.
            </p>
            <h2>
              Reading Readiness Score: {learner.readingReadyScore.score}/78
            </h2>
            {learner.readingReadyScore.text}
            <h2>
              Rhyming Identification Score:{" "}
              {learner.compiledRhymIdScores[0].score}/7
            </h2>
            {learner.compiledRhymIdScores[0].text}
            <h2>
              Rhyming Production Score:{" "}
              {learner.compiledRhymProdScores[0].score}/5
            </h2>
            {learner.compiledRhymProdScores[0].text}
            <h2>Number Readiness Score: {learner.numberReadyScore.score}/41</h2>
            {learner.numberReadyScore.text}
            <h2>Behavior Observations Result: </h2>
            {learner.compiledBehaviorScores[0].missed.length === 0
              ? learner.compiledBehaviorScores[0].score
              : learner.compiledBehaviorScores[0].missed}
            <h2>
              Fine Motor Ability Result:
              {/* {learner.compiledFmScores[0].isUnsatisfactory} */}
            </h2>
            {learner.firstName} is{" "}
            {learner.compiledFmScores[0].dominant.toLowerCase()} hand dominant.{" "}
            {learner.compiledFmScores[0].text}
            {/* {learner.compiledComments.length > 0 ? <h2>Comments</h2> : null }
            {learner.compiledComments.length > 0 ? learner.compiledComments[0].comment : null} */}
            <p>
              Parent/Guardian Signature:
              ________________________________________
            </p>{" "}
            <p>
              Date of Signature:
              _________________________________________________
            </p>
          </div>
        ))}
      </div>
    );
  }
}
