import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import _ from "lodash";
import {
  Typography,
  Grid,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SaveButton } from "./StyledButtons";
import PrintableLearnerScreenerReport from "./PrintableLearnerScreenerReport";

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

const LearnerScreenerReportPanel = ({
  currentLearner,
  readingReadyScore,
  numberReadyScore,
  compiledLNUScores,
  compiledLNLScores,
  compiledLSIScores,
  //   compiledSWScores,
  compiledRhymIdScores,
  compiledRhymProdScores,
  compiledNumIdScores,
  compiledBehaviorScores,
  //   compiledComments,
  compiledCountingScores,
  compiledFmScores,
}) => {
  const componentRef = useRef();

  const classes = useStyles();
  return (
    <Grid item container direction="column">
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h6">
            KinderBase Student Screening Report for{" "}
            {`${currentLearner.firstName} ${currentLearner.lastName}`}
          </Typography>
        </Grid>
        <Grid item>
          <ReactToPrint
            trigger={() => <SaveButton>Printable Version</SaveButton>}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <PrintableLearnerScreenerReport
              ref={componentRef}
              currentLearner={currentLearner}
              readingReadyScore={readingReadyScore}
              numberReadyScore={numberReadyScore}
              compiledLNUScores={compiledLNUScores}
              compiledLNLScores={compiledLNLScores}
              compiledLSIScores={compiledLSIScores}
              //   compiledSWScores={compiledSWScores}
              compiledRhymIdScores={compiledRhymIdScores}
              compiledRhymProdScores={compiledRhymProdScores}
              compiledNumIdScores={compiledNumIdScores}
              compiledBehaviorScores={compiledBehaviorScores}
              //   compiledComments={compiledComments}
              compiledCountingScores={compiledCountingScores}
              compiledFmScores={compiledFmScores}
            />
          </div>
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item style={{ marginRight: "10px" }}>
          <Typography>
            Birthday:{" "}
            {new Date(currentLearner.birthday).toLocaleString().slice(0, 9)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Recommend Kindergarten Camp:{" "}
            {currentLearner.campRecommend ? "Yes" : "No"}
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: "14px", marginBottom: "7px" }}>
        <Typography variant="body1">
          KinderBase is a system we use to measure the foundational skills of
          our learners. This report summarizes {currentLearner.firstName}
          {"'s"} current measure of literacy and numeracy.
        </Typography>
      </Grid>
      <Grid item container direction="column">
        <Grid
          item
          container
          direction="column"
          style={{ marginTop: "5px", marginBottom: "5px", maxWidth: "800px" }}
        >
          <Grid item style={{ marginTop: "7px" }}>
            <Typography>
              Reading Readiness Score: {readingReadyScore.score}
            </Typography>
            {readingReadyScore.text}
            <Typography>
              Number Readiness Score: {numberReadyScore.score}
            </Typography>
            {numberReadyScore.text}

            <Typography>Uppercase Letter Names</Typography>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  maxWidth: "800px",
                }}
                aria-label="uppercase lettername scores table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Overall Score</TableCell>
                    <TableCell>Letters Missed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledLNUScores && compiledLNUScores.length ? (
                    _.orderBy(compiledLNUScores, ["createdAt"], ["desc"]).map(
                      (lnuScoreSet) => {
                        return (
                          <TableRow key={lnuScoreSet.createdAt}>
                            <TableCell>
                              {new Date(
                                lnuScoreSet.createdAt
                              ).toLocaleDateString("en-US")}
                            </TableCell>
                            <TableCell>{lnuScoreSet.score}</TableCell>
                            <TableCell>{lnuScoreSet.missedLetters}</TableCell>
                          </TableRow>
                        );
                      }
                    )
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ marginTop: "5px", marginBottom: "5px", maxWidth: "800px" }}
        >
          <Grid item style={{ marginTop: "7px" }}>
            <Typography>Lowercase Letter Names</Typography>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="lowercase lettername scores table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Overall Score</TableCell>
                    <TableCell>Letters Missed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledLNLScores && compiledLNLScores.length ? (
                    _.orderBy(compiledLNLScores, ["createdAt"], ["desc"]).map(
                      (lnlScoreSet) => {
                        return (
                          <TableRow key={lnlScoreSet.createdAt}>
                            <TableCell>
                              {new Date(
                                lnlScoreSet.createdAt
                              ).toLocaleDateString("en-US")}
                            </TableCell>
                            <TableCell>{lnlScoreSet.score}</TableCell>
                            <TableCell>{lnlScoreSet.missedLetters}</TableCell>
                          </TableRow>
                        );
                      }
                    )
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ marginTop: "5px", marginBottom: "5px", maxWidth: "800px" }}
        >
          <Grid item style={{ marginTop: "7px" }}>
            <Typography>Letter Sounds</Typography>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="letter sounds scores table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Overall Score</TableCell>
                    <TableCell>Letters Missed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledLSIScores && compiledLSIScores.length ? (
                    _.orderBy(compiledLSIScores, ["createdAt"], ["desc"]).map(
                      (lsiScoreSet) => {
                        return (
                          <TableRow key={lsiScoreSet.createdAt}>
                            <TableCell>
                              {new Date(
                                lsiScoreSet.createdAt
                              ).toLocaleDateString("en-US")}
                            </TableCell>
                            <TableCell>{lsiScoreSet.score}</TableCell>
                            <TableCell>{lsiScoreSet.missedLetters}</TableCell>
                          </TableRow>
                        );
                      }
                    )
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {/* <Grid
          item
          container
          direction="column"
          style={{ marginTop: "5px", marginBottom: "5px", maxWidth: "800px" }}
        > */}
        {/* <Grid item style={{ marginTop: "7px" }}>
            <Typography>Sight Words</Typography>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="sight words scores table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Overall Score</TableCell>
                    <TableCell>Words Missed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledSWScores && compiledSWScores.length ? (
                    _.orderBy(compiledSWScores, ["createdAt"], ["desc"]).map(
                      (swScoreSet) => {
                        return (
                          <TableRow key={swScoreSet.createdAt}>
                            <TableCell>
                              {new Date(
                                swScoreSet.createdAt
                              ).toLocaleDateString("en-US")}
                            </TableCell>
                            <TableCell>{swScoreSet.score}</TableCell>
                            <TableCell>{swScoreSet.missedWords}</TableCell>
                          </TableRow>
                        );
                      }
                    )
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> */}
        {/* </Grid> */}
        <Grid
          item
          container
          direction="column"
          style={{ marginTop: "5px", marginBottom: "5px", maxWidth: "800px" }}
        >
          <Grid item style={{ marginTop: "7px" }}>
            <Typography>Rhyming Identification</Typography>
          </Grid>
          <Grid item>{compiledRhymIdScores[0].text}</Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="Rhyming Identification scores table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Overall Score</TableCell>
                    <TableCell>Word Combinations Missed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledRhymIdScores && compiledRhymIdScores.length ? (
                    _.orderBy(
                      compiledRhymIdScores,
                      ["createdAt"],
                      ["desc"]
                    ).map((rhymIdScoreSet) => {
                      return (
                        <TableRow key={rhymIdScoreSet.createdAt}>
                          <TableCell>
                            {new Date(
                              rhymIdScoreSet.createdAt
                            ).toLocaleDateString("en-US")}
                          </TableCell>
                          <TableCell>{rhymIdScoreSet.score}</TableCell>
                          <TableCell>{rhymIdScoreSet.missedWords}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ marginTop: "5px", marginBottom: "5px", maxWidth: "800px" }}
        >
          <Grid item style={{ marginTop: "7px" }}>
            <Typography>Rhyming Production</Typography>
          </Grid>
          <Grid>{compiledRhymProdScores[0].text}</Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="Rhyming Production scores table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Overall Score</TableCell>
                    <TableCell>Words Missed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledRhymProdScores && compiledRhymProdScores.length ? (
                    _.orderBy(
                      compiledRhymProdScores,
                      ["createdAt"],
                      ["desc"]
                    ).map((rhymProdScoreSet) => {
                      return (
                        <TableRow key={rhymProdScoreSet.createdAt}>
                          <TableCell>
                            {new Date(
                              rhymProdScoreSet.createdAt
                            ).toLocaleDateString("en-US")}
                          </TableCell>
                          <TableCell>{rhymProdScoreSet.score}</TableCell>
                          <TableCell>{rhymProdScoreSet.missedWords}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ marginTop: "5px", marginBottom: "5px", maxWidth: "800px" }}
        >
          <Grid item style={{ marginTop: "7px" }}>
            <Typography>Number Identification</Typography>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="sight words scores table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Overall Score</TableCell>
                    <TableCell>Numbers Missed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledNumIdScores && compiledNumIdScores.length ? (
                    _.orderBy(compiledNumIdScores, ["createdAt"], ["desc"]).map(
                      (numIdScoreSet) => {
                        return (
                          <TableRow key={numIdScoreSet.createdAt}>
                            <TableCell>
                              {new Date(
                                numIdScoreSet.createdAt
                              ).toLocaleDateString("en-US")}
                            </TableCell>
                            <TableCell>{numIdScoreSet.score}</TableCell>
                            <TableCell>{numIdScoreSet.missedNumbers}</TableCell>
                          </TableRow>
                        );
                      }
                    )
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item style={{ marginTop: "7px" }}>
              <Typography>Number Counting</Typography>
            </Grid>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="counting scores table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Score</TableCell>
                    <TableCell>One to One Matching</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledCountingScores && compiledCountingScores.length ? (
                    _.orderBy(
                      compiledCountingScores,
                      ["createdAt"],
                      ["desc"]
                    ).map((CountingScoreSet) => {
                      return (
                        <TableRow key={CountingScoreSet.createdAt}>
                          <TableCell>
                            {new Date(
                              CountingScoreSet.createdAt
                            ).toLocaleDateString("en-US")}
                          </TableCell>
                          <TableCell>{CountingScoreSet.score}</TableCell>
                          <TableCell>
                            {CountingScoreSet.oneToOneMatching ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid item style={{ marginTop: "7px" }}>
              <Typography>Behavior Observations</Typography>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="Behaviors table">
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Behaviors Observed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledBehaviorScores && compiledBehaviorScores.length ? (
                    _.orderBy(
                      compiledBehaviorScores,
                      ["createdAt"],
                      ["desc"]
                    ).map((behaviors) => {
                      return (
                        <TableRow key={behaviors.createdAt}>
                          <TableCell>
                            {new Date(behaviors.createdAt).toLocaleDateString(
                              "en-US"
                            )}
                          </TableCell>
                          <TableCell>
                            {behaviors.missed.length === 0
                              ? behaviors.score
                              : behaviors.missed}
                            {/* {behaviors.score} */}
                            {/* {behaviors.missed} */}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item style={{ marginTop: "7px" }}>
              <Typography>Fine Motor Ability</Typography>
            </Grid>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="Fine Motor Ability table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Dominant Hand</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Recommendations</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledFmScores && compiledFmScores.length ? (
                    _.orderBy(compiledFmScores, ["createdAt"], ["desc"]).map(
                      (fmAbility) => {
                        return (
                          <TableRow key={fmAbility.createdAt}>
                            <TableCell>
                              {new Date(fmAbility.createdAt).toLocaleDateString(
                                "en-US"
                              )}
                            </TableCell>
                            <TableCell>{fmAbility.dominant}</TableCell>
                            <TableCell>{fmAbility.isUnsatisfactory}</TableCell>
                            <TableCell>{fmAbility.text}</TableCell>
                          </TableRow>
                        );
                      }
                    )
                  ) : (
                    <TableRow>
                      <TableCell>No scores have been collected yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Grid item style={{ marginTop: "7px" }}>
              <Typography>Comments</Typography>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="comments table">
                <TableHead>
                  <TableRow>
                    <TableCell>Recorded</TableCell>
                    <TableCell>Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compiledComments && compiledComments.length ? (
                    _.orderBy(compiledComments, ["createdAt"], ["desc"]).map(
                      (comment) => {
                        return (
                          <TableRow key={comment.createdAt}>
                            <TableCell>
                              {new Date(comment.createdAt).toLocaleDateString(
                                "en-US"
                              )}
                            </TableCell>
                            <TableCell>{comment.comment}</TableCell>
                          </TableRow>
                        );
                      }
                    )
                  ) : (
                    <TableRow>
                      <TableCell>
                        No additional comments were provided beyond those
                        provided in other sections of this report.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LearnerScreenerReportPanel;
