import React, { createContext, useContext } from "react";
import _ from "lodash";
import { firestore } from "../firebaseInit";
import {
  doc,
  query,
  getDocs,
  where,
  collection,
  runTransaction,
  getDocFromCache,
} from "firebase/firestore";
import { arrayUnion, arrayRemove } from "firebase/firestore";
import { LearnerContext } from "../contexts/LearnerContext";
import { MessageContext } from "../contexts/MessageContext";
import { currentSchoolYear } from "../config/currentSchoolYear";
import { useAuth } from "../contexts/AuthContext";

export const TransactionContext = createContext();

export const TransactionProvider = (props) => {
  const { currentUser } = useAuth();
  const { currentLearner } = useContext(LearnerContext);
  const { setMessage } = useContext(MessageContext);
  // helper function to determine if we are working with dashboard data
  const determineIfDashboardData = (scoreType) => {
    if (
      scoreType === "lnuScores" ||
      scoreType === "lnlScores" ||
      scoreType === "lsiScores" ||
      scoreType === "iwScores" ||
      scoreType === "countingScores" ||
      scoreType === "numIdScores" ||
      scoreType === "spcScores" ||
      scoreType === "rhymIdScores" ||
      scoreType === "rhymProdScores"
    ) {
      return true;
    }
    return false;
  };

  // helper function to determine if we are working with RReadiness data
  const determineIfRReadinessData = (scoreType) => {
    if (
      scoreType === "lnuScores" ||
      scoreType === "lnlScores" ||
      scoreType === "lsiScores"
    ) {
      return true;
    }
    return false;
  };

  // helper function to determine if we are working with NReadiness data
  const determineIfNReadinessData = (scoreType) => {
    if (scoreType === "countingScores" || scoreType === "numIdScores") {
      return true;
    }
    return false;
  };

  // helper function to fetch a teacher profile by teacherId
  const fetchTeacherProfile = async (teacherId) => {
    const q = query(
      collection(firestore, "users"),
      where("schoolYear", "==", currentSchoolYear),
      where("teacherId", "==", teacherId)
    );
    const querySnapshot = await getDocs(q);
    const teacherRef = querySnapshot.docs[0].data();
    return teacherRef;
  };

  // function to create screening learner search
  const getScreeners = async () => {
    const q = query(
      collection(firestore, "learners"),
      where("teacherId", "==", "screener"),
      where("schoolYear", "==", currentSchoolYear)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return [];
    }
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  };

  // function to create printable list of rostered Learners
  const getLearners = async () => {
    const q = query(
      collection(firestore, "learners"),
      where("teacherId", "==", currentUser.teacherId)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return [];
    }
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  };

  //Function to get all Current School Year Learners
  const getAllLearners = async () => {
    const q = query(
      collection(firestore, "learners"),
      where("schoolYear", "==", currentSchoolYear)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return [];
    }
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  };

  // helper function to produce an updated number readiness score set
  const produceNewNRScoreSet = (
    scoreType,
    operationType = "create",
    currentLearner,
    newScoreSet
  ) => {
    // initialize newLearner
    let newLearner = [];

    if (operationType === "create" || operationType === "edit") {
      // bring new score into learner object, paying attention to if we have
      // any scores of the new type or not
      if (currentLearner[`${scoreType}`]) {
        newLearner = {
          ...currentLearner,
          [`${scoreType}`]: [...currentLearner[`${scoreType}`], newScoreSet],
        };
      } else {
        newLearner = {
          ...currentLearner,
          [`${scoreType}`]: [newScoreSet],
        };
      }
    } else {
      // eliminate the newScoreSet from the array
      const correctedScoreArray = _.filter(
        currentLearner[`${scoreType}`],
        (scoreSet) => scoreSet.docRefId !== newScoreSet.docRefId
      );
      newLearner = { ...currentLearner, [`${scoreType}`]: correctedScoreArray };
    }

    // if we have all metrics, compile the score set and return it
    if (
      newLearner.numIdScores &&
      newLearner.numIdScores.length &&
      newLearner.countingScores &&
      newLearner.countingScores.length
    ) {
      // get latest numId and counting scores
      const latestNumId = _.orderBy(newLearner.numIdScores, [
        "createdAt",
        "asc",
      ]).pop();
      const latestCounting = _.orderBy(newLearner.countingScores, [
        "createdAt",
        "asc",
      ]).pop();

      const nReadinessScore =
        parseInt(latestCounting.score, 10) + parseInt(latestNumId.score, 10);

      // use the score values to compile the readiness set and return
      return {
        score: nReadinessScore,
        status:
          parseInt(latestCounting.score, 10) + parseInt(latestNumId.score, 10) >
          16
            ? "s"
            : "u",
        createdAt: Date.now(),
      };
    }
    // not enought data for metric, so null
    return null;
  };

  // helper function to produce an updated reading readiness score set
  const produceNewRRScoreSet = (
    scoreType,
    operationType = "create",
    currentLearner,
    newScoreSet
  ) => {
    // initialize newLearner
    let newLearner = [];

    if (operationType === "create" || operationType === "edit") {
      // bring new score into learner object, checking if this is the first score being set
      if (currentLearner[`${scoreType}`]) {
        newLearner = {
          ...currentLearner,
          [`${scoreType}`]: [...currentLearner[`${scoreType}`], newScoreSet],
        };
      } else {
        newLearner = {
          ...currentLearner,
          [`${scoreType}`]: [newScoreSet],
        };
      }
    } else {
      // eliminate the newScoreSet from the array
      const correctedScoreArray = _.filter(
        currentLearner[`${scoreType}`],
        (scoreSet) => scoreSet.docRefId !== newScoreSet.docRefId
      );
      newLearner = { ...currentLearner, [`${scoreType}`]: correctedScoreArray };
    }

    // if we have all metrics, compile the score set and return it
    if (
      newLearner.lnuScores &&
      newLearner.lnuScores.length &&
      newLearner.lnlScores &&
      newLearner.lnlScores.length &&
      newLearner.lsiScores &&
      newLearner.lsiScores.length
    ) {
      // get latest lnl, lnu, and lsi scores
      const latestLNU = _.orderBy(newLearner.lnuScores, [
        "createdAt",
        "asc",
      ]).pop();
      const latestLNL = _.orderBy(newLearner.lnlScores, [
        "createdAt",
        "asc",
      ]).pop();
      const latestLSI = _.orderBy(newLearner.lsiScores, [
        "createdAt",
        "asc",
      ]).pop();

      const rReadinessScore =
        latestLNU.score + latestLNL.score + latestLSI.score;

      // use the score values to compile the readiness set and return
      return {
        score: rReadinessScore,
        status: rReadinessScore > 36 ? "s" : "u",
        createdAt: Date.now(),
      };
    }
    // not enought data for metric, so null
    return null;
  };

  // save new score set using an atomic transaction
  const saveNewScore = async (activeScoreSet, scoreType) => {
    const isDashboardData = determineIfDashboardData(scoreType);
    const isRReadinessData = determineIfRReadinessData(scoreType);
    const isNReadinessData = determineIfNReadinessData(scoreType);
    let existingRoster = null;
    let existingRosterElement = null;
    let updatedRosterElement = null;
    let associatedTeacher = null;
    let newRReadinessSet = null;
    let newNReadinessSet = null;

    // determine if the user saving data is the associated teacher
    if (currentUser && currentLearner.teacherId === "screener") {
      associatedTeacher = "screener";
    }

    if (currentUser.id && currentUser.id === currentLearner.teacherId) {
      associatedTeacher = currentUser;
    }

    // find the associated teacher, if needed
    if (!associatedTeacher) {
      associatedTeacher = await fetchTeacherProfile(currentLearner.teacherId);
    }

    // establish an existing roster from the associated teacher
    existingRoster = associatedTeacher.roster;

    // establish a new score set for saving to score collection
    const newScoreSet = {
      ...activeScoreSet,
      createdAt: Date.now(),
      learnerId: currentLearner.learnerId,
      associatedTeacherId: currentLearner.teacherId,
      schoolYear: currentSchoolYear,
      recordedBy: currentUser.teacherId,
      recordedByName: currentUser.firstName + " " + currentUser.lastName,
    };

    // determine if this is dashboard data and that we have an associated teaacher
    if (isDashboardData && existingRoster) {
      // we need both the original data, to find and remove, and the new data, to add, so...
      // first, isolate the existing learner in the roster array (original data, to remove)
      existingRosterElement = existingRoster.find((foundLearner) => {
        return foundLearner.learnerId === currentLearner.learnerId;
      });
    }

    // determine if this is nReadiness or rReadiness data, and if it is,
    // produce a new readiness object for the learner in the teacher's userProfile
    if (isRReadinessData) {
      newRReadinessSet = produceNewRRScoreSet(
        scoreType,
        "create",
        currentLearner,
        newScoreSet
      );
    }
    if (isNReadinessData) {
      newNReadinessSet = produceNewNRScoreSet(
        scoreType,
        "create",
        currentLearner,
        newScoreSet
      );
    }
    // Refactor for Firebase 9 syntax starting here 6/14
    // establish new doc ref for saving into the scores collection
    //const newScoreDocRef = addDoc(firestore, collection(`${scoreType}`).doc());
    const newScoreDocRef = doc(collection(firestore, `${scoreType}`));
    try {
      // establish a transaction to encapsulate all operations
      // all elements of the transaction will fire, or none at all
      await runTransaction(firestore, async (transaction) => {
        // save score data to score collection
        await transaction.set(newScoreDocRef, {
          ...newScoreSet,
        });

        // save score data to appropriate array in learner doc
        const learnerRef = doc(
          firestore,
          "/learners/",
          `${currentLearner.learnerId}`
        );

        await transaction.update(learnerRef, {
          [scoreType]: arrayUnion({
            ...newScoreSet,
            docRefId: newScoreDocRef.id,
            [scoreType + "LatestDocRef"]: newScoreDocRef.id,
          }),
        });

        // save dashboard-level data elements to /userprofiles/roster
        if (isDashboardData && existingRosterElement) {
          // establish a doc ref to the user profile
          const teacherProfileRef = doc(
            firestore,
            "/users/",
            `${associatedTeacher.teacherId}-${currentSchoolYear}`
          );

          // set an updated version of the learner object (new data, to merge back in)
          updatedRosterElement = {
            ...existingRosterElement,
            [scoreType + "LatestScore"]: newScoreSet.score,
            [scoreType + "LatestScoreDate"]: newScoreSet.createdAt,
            [scoreType + "LatestDocRef"]: newScoreDocRef.id,
          };

          // if needed, update rReadiness or nReadiness in the roster object of the
          // userProfile doc as well
          if (isRReadinessData) {
            updatedRosterElement = {
              ...updatedRosterElement,
              latestReadingReadinessData: newRReadinessSet,
            };
          }
          if (isNReadinessData) {
            updatedRosterElement = {
              ...updatedRosterElement,
              latestNumberReadinessData: newNReadinessSet,
            };
          }

          // remove the old element from the roster array
          await transaction.update(teacherProfileRef, {
            roster: arrayRemove({
              ...existingRosterElement,
            }),
          });

          // add the new element to the roster array
          await transaction.update(teacherProfileRef, {
            roster: arrayUnion({
              ...updatedRosterElement,
            }),
          });
        }
      });

      // transaction fulfilled, share the good news and close the new LNU score panel
      setMessage("success", "New information saved successfully.", 3000);
      return {
        status: "success",
        message: "New information saved successfully.",
      };
    } catch (err) {
      setMessage(
        "error",
        `Error trying to save new information of type ${scoreType}: ${err.message}`,
        6000
      );
      return {
        status: "success",
        message: `Error trying to save new information of type ${scoreType}: ${err.message}`,
      };
    }
  };

  const editScore = async (activeScoreData, scoreType) => {
    const isDashboardData = determineIfDashboardData(scoreType);
    const isRReadinessData = determineIfRReadinessData(scoreType);
    const isNReadinessData = determineIfNReadinessData(scoreType);
    let associatedTeacher = null;
    let newRReadinessSet = null;
    let existingRoster = null;
    let newNReadinessSet = null;

    // determine if the user saving data is the associated teacher
    if (currentUser && currentLearner.teacherId === "screener") {
      associatedTeacher = "screener";
    }

    if (currentUser.id && currentUser.id === currentLearner.teacherId) {
      associatedTeacher = currentUser;
    }

    // find the associated teacher, if needed
    if (!associatedTeacher) {
      associatedTeacher = await fetchTeacherProfile(currentLearner.teacherId);
    }

    // establish an existing roster from the associated teacher
    existingRoster = associatedTeacher.roster;

    // establish a score set for updating the score collection
    const updatedScoreSet = {
      ...activeScoreData,
      associatedTeacherId: currentLearner.teacherId,
      lastEdited: Date.now(),
      lastEditedBy: currentUser.teacherId,
      lastEditedName: currentUser.firstName + " " + currentUser.lastName,
    };
    // establish doc ref for the score being edited

    const editedDocRef = doc(
      firestore,
      `/${scoreType}`,
      `${activeScoreData.docRefId}`
    );

    try {
      // establish a transaction to encapsulate all operations
      // all elements of the transaction will fire, or none at all
      await runTransaction(firestore, async (transaction) => {
        // edit the doc in the score collection
        await transaction.update(editedDocRef, {
          ...updatedScoreSet,
        });

        // update the score instance in learners doc
        // we need both the original data, to find and remove, and the new data, to add, so...
        // setup the doc ref
        const learnersDocRef = doc(
          firestore,
          "/learners/",
          `${currentLearner.learnerId}`
        );

        // isolate the existing score in the learner score array (original data, to remove)
        // get the existing learner data set
        const existingLearnerDoc = await getDocFromCache(learnersDocRef);
        // const existingLearnerDoc = learnersDocRef.get();
        const existingLearner = { ...existingLearnerDoc.data() };

        // get the score that needs removed
        const existingScoreSet = existingLearner[scoreType].find(
          (scoreSet) => scoreSet.docRefId === activeScoreData.docRefId
        );

        // remove the old element from the learner score array
        await transaction.update(learnersDocRef, {
          [scoreType]: arrayRemove({
            ...existingScoreSet,
          }),
        });

        // add the new element to the learner score array
        await transaction.update(learnersDocRef, {
          [scoreType]: arrayUnion({
            ...updatedScoreSet,
          }),
        });

        // check if we need to update the latest score instance in userProfile doc
        // (latest score is the one being edited and we have an actual score change)
        // (also indicates needing to update the readiness score set)
        const foundRosterElement = _.find(associatedTeacher.roster, {
          [scoreType + "LatestDocRef"]: activeScoreData.docRefId,
        });
        if (
          isDashboardData &&
          foundRosterElement &&
          foundRosterElement[scoreType + "LatestScore"] !==
            activeScoreData.score
        ) {
          // establish a doc ref to the user profile
          const userProfileRef = doc(
            firestore,
            "/users/",
            `${associatedTeacher.teacherId}-${currentSchoolYear}`
          );

          // remove the old element from the learner score array
          await transaction.update(userProfileRef, {
            roster: arrayRemove({
              ...foundRosterElement,
            }),
          });

          // establish an updated roster element
          let updatedRosterElement = {
            ...foundRosterElement,
            [scoreType + "LatestScore"]: activeScoreData.score,
          };

          // if needed, update rReadiness or nReadiness in the roster object of the
          // userProfile doc as well
          if (isRReadinessData) {
            newRReadinessSet = produceNewRRScoreSet(
              scoreType,
              "edit",
              currentLearner,
              updatedScoreSet
            );
            updatedRosterElement = {
              ...updatedRosterElement,
              latestReadingReadinessData: newRReadinessSet,
            };
          }
          if (isNReadinessData) {
            newNReadinessSet = produceNewNRScoreSet(
              scoreType,
              "edit",
              currentLearner,
              updatedScoreSet
            );
            updatedRosterElement = {
              ...updatedRosterElement,
              latestNumberReadinessData: newNReadinessSet,
            };
          }

          // add the new element to the learner score array
          await transaction.update(userProfileRef, {
            roster: arrayUnion({
              ...updatedRosterElement,
            }),
          });
        }
      });
      // transaction fulfilled, share the good news and deactivate editing on the LNU score panel
      setMessage("success", "Score information was edited successfully.", 3000);
      return {
        status: "success",
        message: "Score information was edited successfully.",
      };
    } catch (err) {
      setMessage(
        "error",
        `Error trying to edit the score information: ${err.message}`,
        6000
      );
      return {
        status: "error",
        message: `Error trying to edit the score information: ${err.message}`,
      };
    }
  };

  const deleteScore = async (activeScoreData, scoreType) => {
    const isDashboardData = determineIfDashboardData(scoreType);
    const isRReadinessData = determineIfRReadinessData(scoreType);
    const isNReadinessData = determineIfNReadinessData(scoreType);
    let associatedTeacher = null;
    let existingRoster = null;
    let updatedRosterElement = null;
    let existingScoreSet = null;
    let newRReadinessSet = null;
    let newNReadinessSet = null;

    if (currentUser && currentLearner.teacherId === "screener") {
      associatedTeacher = "screener";
    }

    if (currentUser.id && currentUser.id === currentLearner.teacherId) {
      associatedTeacher = currentUser;
    }

    // find the associated teacher, if needed
    if (!associatedTeacher) {
      associatedTeacher = await fetchTeacherProfile(currentLearner.teacherId);
    }

    // establish an existing roster from the associated teacher
    existingRoster = associatedTeacher.roster;

    // establish doc ref for the score being edited
    const deletedDocRef = doc(
      firestore,
      `/${scoreType}`,
      `${activeScoreData.docRefId}`
    );

    // delete the score (if it is a latest score) from the learner array within the userProfile of the teacher
    // also update the readiness score (if it is a latest score)
    if (deletedDocRef.schoolYear === currentSchoolYear) {
      try {
        // establish a transaction to encapsulate all operations
        // all elements of the transaction will fire, or none at all
        await runTransaction(firestore, async (transaction) => {
          // delete the score from the score collection
          await transaction.delete(deletedDocRef);

          // delete the score in the learners score array
          // we need the original data, to find and remove, so...
          // setup the doc ref
          const learnersDocRef = doc(
            firestore,
            "/learners/",
            `${currentLearner.learnerId}`
          );

          // get the existing learner data set
          const existingLearnerDoc = await getDocFromCache(learnersDocRef);
          const existingLearner = { ...existingLearnerDoc.data() };

          // isolate the existing score in the learner score array (score to remove)
          existingScoreSet = existingLearner[scoreType].find(
            (scoreSet) => scoreSet.docRefId === activeScoreData.docRefId
          );

          // delete the score from the learner's score-specific array
          await transaction.update(learnersDocRef, {
            [scoreType]: arrayRemove({
              ...existingScoreSet,
            }),
          });

          // determine if we need a new latest score in the userProfile roster array
          // try to find the docId of the deleted score in the user.roster array
          const foundRosterElement = _.find(associatedTeacher.roster, {
            [scoreType + "LatestDocRef"]: activeScoreData.docRefId,
          });

          if (isDashboardData && foundRosterElement) {
            // we need to replace the latest score info in the roster array, so...
            // start by removing the latest score data from the userProfile roster array element

            // get the user profile doc ref
            const userProfileRef = doc(
              firestore,
              "/users/",
              `${associatedTeacher.teacherId}-${currentSchoolYear}`
            );

            // remove the learner data from the userProfile roster element (we have to put this back)
            await transaction.update(userProfileRef, {
              roster: arrayRemove({
                ...foundRosterElement,
              }),
            });

            // find the new latest score and, if there is one, add it to the array
            // get an ordered array of scores
            const orderedScores = _.orderBy(
              existingLearner[scoreType],
              ["createdAt"],
              ["desc"]
            );

            // the ordered array also contains the soon to be deleted score,
            // so grab the second in the orderedScores array...
            if (orderedScores && orderedScores.length > 1) {
              // establish the updated latest score data
              const newLatestScoreData = orderedScores[1];

              // establish the updated learner element for the roster array
              updatedRosterElement = {
                ...foundRosterElement,
                [scoreType + "LatestDocRef"]: newLatestScoreData.docRefId,
                [scoreType + "LatestScore"]: newLatestScoreData.score,
                [scoreType + "LatestScoreDate"]: newLatestScoreData.createdAt,
              };

              // if needed, update rReadiness or nReadiness in the roster object of the
              // userProfile doc as well
              if (isRReadinessData) {
                newRReadinessSet = produceNewRRScoreSet(
                  scoreType,
                  "delete",
                  currentLearner,
                  existingScoreSet
                );
                updatedRosterElement = {
                  ...updatedRosterElement,
                  latestReadingReadinessData: newRReadinessSet,
                };
              }
              if (isNReadinessData) {
                newNReadinessSet = produceNewNRScoreSet(
                  scoreType,
                  "delete",
                  currentLearner,
                  existingScoreSet
                );
                updatedRosterElement = {
                  ...updatedRosterElement,
                  latestNumberReadinessData: newNReadinessSet,
                };
              }

              // add the completely updated learner roster element back
              await transaction.update(userProfileRef, {
                roster: arrayUnion({
                  ...updatedRosterElement,
                }),
              });
            } else {
              // we deleted the only score of this type in the system for this Learner,
              // so put the learner back into the roster with null score data
              updatedRosterElement = {
                ...foundRosterElement,
                [scoreType + "LatestDocRef"]: null,
                [scoreType + "LatestScore"]: null,
                [scoreType + "LatestScoreDate"]: null,
              };

              // if corresponding readiness data is involved, it will automatically be null
              if (isRReadinessData) {
                updatedRosterElement = {
                  ...updatedRosterElement,
                  latestReadingReadinessData: null,
                };
              }
              if (isNReadinessData) {
                updatedRosterElement = {
                  ...updatedRosterElement,
                  latestNumberReadinessData: null,
                };
              }

              // add the corrected learner element back into the roster
              await transaction.update(userProfileRef, {
                roster: arrayUnion({
                  ...updatedRosterElement,
                }),
              });
            }
          }
        });
        // transaction fulfilled, share the good news and deactivate editing on the LNU score panel
        setMessage(
          "success",
          "Score information was deleted successfully.",
          3000
        );
        return {
          status: "success",
          message: "Score information was deleted successfully.",
        };
      } catch (err) {
        setMessage(
          "error",
          `Error trying to delete the score information: ${err.message}`,
          6000
        );
        return {
          status: "error",
          message: `Error trying to delete the score information: ${err.message}`,
        };
      }
    } else {
      try {
        // This is to delete a previous year score item, it will have no roster elements.
        // establish a transaction to encapsulate all operations
        // all elements of the transaction will fire, or none at all
        await runTransaction(firestore, async (transaction) => {
          // delete the score from the score collection
          await transaction.delete(deletedDocRef);

          // delete the score in the learners score array
          // we need the original data, to find and remove, so...
          // setup the doc ref
          const learnersDocRef = doc(
            firestore,
            "/learners/",
            `${currentLearner.learnerId}`
          );

          // get the existing learner data set
          const existingLearnerDoc = await getDocFromCache(learnersDocRef);
          const existingLearner = { ...existingLearnerDoc.data() };

          // isolate the existing score in the learner score array (score to remove)
          existingScoreSet = existingLearner[scoreType].find(
            (scoreSet) => scoreSet.docRefId === activeScoreData.docRefId
          );

          // delete the score from the learner's score-specific array
          await transaction.update(learnersDocRef, {
            [scoreType]: arrayRemove({
              ...existingScoreSet,
            }),
          });
        });
        // transaction fulfilled, share the good news and deactivate editing on the LNU score panel
        setMessage(
          "success",
          "Score information was deleted successfully.",
          3000
        );
        return {
          status: "success",
          message: "Score information was deleted successfully.",
        };
      } catch (err) {
        setMessage(
          "error",
          `Error trying to delete the score information: ${err.message}`,
          6000
        );
        return {
          status: "error",
          message: `Error trying to delete the score information: ${err.message}`,
        };
      }
    }
  };

  const toggleLearnerCampRecommend = async (newValue) => {
    await runTransaction(firestore, async (transaction) => {
      // setup the doc ref
      const learnersDocRef = doc(
        firestore,
        "/learners/",
        `${currentLearner.learnerId}`
      );
      try {
        await transaction.update(learnersDocRef, {
          campRecommend: newValue,
        });
        return {
          status: "success",
          message: `camp status updated successfully`,
        };
      } catch (err) {
        setMessage(
          "error",
          `Error trying to update camp status information: ${err.message}`,
          6000
        );
        return {
          status: "error",
          message: `Error trying to update camp status information: ${err.message}`,
        };
      }
    });
  };

  return (
    <TransactionContext.Provider
      value={{
        toggleLearnerCampRecommend,
        saveNewScore,
        editScore,
        deleteScore,
        getScreeners,
        getLearners,
        getAllLearners,
      }}
    >
      {props.children}
    </TransactionContext.Provider>
  );
};
