import React, { Component } from "react";
import _ from "lodash";

class PrintableLearnerProgressReport extends Component {
  render() {
    const {
      currentLearner,
      compiledLNUScores,
      compiledLNLScores,
      compiledLSIScores,
      compiledRhymIdScores,
      compiledRhymProdScores,
      compiledNumIdScores,
      compiledCountingScores,
      compiledFmScores,
      compiledSgwhScores,
    } = this.props;

    return (
      <div style={{ pageBreakAfter: "always" }}>
        <h1>{`KinderBase Student Progress Report for ${currentLearner.firstName} ${currentLearner.lastName}`}</h1>
        Birthday:{" "}
        {new Date(currentLearner.birthday).toLocaleString().slice(0, 9)}{" "}
        <p>
          KinderBase is a system we use to track the progress in the
          foundational skills of our learners. This report summarizes this
          year's current progress in measures of literacy and numeracy.
        </p>
        <h2>Letternames Uppercase</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Letters Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledLNUScores && compiledLNUScores.length ? (
              _.orderBy(compiledLNUScores, ["createdAt"], ["desc"]).map(
                (lnuScoreSet) => {
                  return (
                    <tr key={lnuScoreSet.createdAt}>
                      <td align="left">
                        {new Date(lnuScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{lnuScoreSet.score}</td>
                      <td align="left">{lnuScoreSet.missedLetters}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Letternames Lowercase</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Letters Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledLNLScores && compiledLNLScores.length ? (
              _.orderBy(compiledLNLScores, ["createdAt"], ["desc"]).map(
                (lnlScoreSet) => {
                  return (
                    <tr key={lnlScoreSet.createdAt}>
                      <td align="left">
                        {new Date(lnlScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{lnlScoreSet.score}</td>
                      <td align="left">{lnlScoreSet.missedLetters}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Letter Sounds</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Letters Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledLSIScores && compiledLSIScores.length ? (
              _.orderBy(compiledLSIScores, ["createdAt"], ["desc"]).map(
                (lsiScoreSet) => {
                  return (
                    <tr key={lsiScoreSet.createdAt}>
                      <td align="left">
                        {new Date(lsiScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{lsiScoreSet.score}</td>
                      <td align="left">{lsiScoreSet.missedLetters}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Rhyming Identification Score: {compiledRhymIdScores[0].score}/7</h2>
        {compiledRhymIdScores[0].text}
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Word Combinations Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledRhymIdScores && compiledRhymIdScores.length ? (
              _.orderBy(compiledRhymIdScores, ["createdAt"], ["desc"]).map(
                (rhymIdScoreSet) => {
                  return (
                    <tr key={rhymIdScoreSet.createdAt}>
                      <td align="left">
                        {new Date(rhymIdScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{rhymIdScoreSet.score}</td>
                      <td align="left">{rhymIdScoreSet.missedWords}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Rhyming Production Score: {compiledRhymProdScores[0].score}/5</h2>
        {compiledRhymProdScores[0].text}
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Words Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledRhymProdScores && compiledRhymProdScores.length ? (
              _.orderBy(compiledRhymProdScores, ["createdAt"], ["desc"]).map(
                (rhymProdScoreSet) => {
                  return (
                    <tr key={rhymProdScoreSet.createdAt}>
                      <td align="left">
                        {new Date(
                          rhymProdScoreSet.createdAt
                        ).toLocaleDateString("en-US")}
                      </td>
                      <td align="left">{rhymProdScoreSet.score}</td>
                      <td align="left">{rhymProdScoreSet.missedWords}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Number Identification</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Numbers Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledNumIdScores && compiledNumIdScores.length ? (
              _.orderBy(compiledNumIdScores, ["createdAt"], ["desc"]).map(
                (numIdScoreSet) => {
                  return (
                    <tr key={numIdScoreSet.createdAt}>
                      <td align="left">
                        {new Date(numIdScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{numIdScoreSet.score}</td>
                      <td align="left">{numIdScoreSet.missedNumbers}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Number Counting</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Score</th>
              <th align="left">One to One</th>
            </tr>
          </thead>
          <tbody>
            {compiledCountingScores && compiledCountingScores.length ? (
              _.orderBy(compiledCountingScores, ["createdAt"], ["desc"]).map(
                (CountingScoreSet) => {
                  return (
                    <tr key={CountingScoreSet.createdAt}>
                      <td align="left">
                        {new Date(
                          CountingScoreSet.createdAt
                        ).toLocaleDateString("en-US")}
                      </td>
                      <td align="left">{CountingScoreSet.score}</td>
                      <td align="left">{CountingScoreSet.oneToOne}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Fine Motor Ability Result:</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Score</th>
            </tr>
          </thead>
          <tbody>
            {compiledFmScores && compiledFmScores.length ? (
              _.orderBy(compiledFmScores, ["createdAt"], ["desc"]).map(
                (fmAbility) => {
                  return (
                    <tr key={fmAbility.createdAt}>
                      <td align="left">
                        {new Date(fmAbility.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{fmAbility.text}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Social Growth and Work Habits</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th>Date</th>
              <th>Skill</th>
            </tr>
          </thead>
          <tbody>
            {compiledSgwhScores && compiledSgwhScores.length ? (
              _.orderBy(compiledSgwhScores, ["createdAt"], ["desc"]).map(
                (sgwhScoreSet) => (
                  <tr key={sgwhScoreSet.createdAt}>
                    <td rowSpan={sgwhScoreSet.scores.length + 2}>
                      {new Date(sgwhScoreSet.createdAt).toLocaleDateString(
                        "en-US"
                      )}
                    </td>
                    <td>
                      <table border="1px solid #dddddd" width="100%">
                        <tbody>
                          {compiledSgwhScores.selfHelp ? (
                            <tr>
                              <th>
                                "Working toward independent self help skills"
                              </th>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                      <table border="1px solid #dddddd" width="100%">
                        <tbody>
                          {sgwhScoreSet.scores.map((score) => (
                            <tr key={score.skill}>
                              <td>{score.skill}</td>
                              <td>{score.score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PrintableLearnerProgressReport;
