import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Container, Typography, Grid, TextField, Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import PrintAllRosteredLearnersContainer from "./PrintAllRosteredLearnersContainer";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import { useAuth } from "../../contexts/AuthContext";
import { LearnerContext } from "../../contexts/LearnerContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import { LoadingContext } from "../../contexts/LoadingContext";
import LearnerProgressReportContainer from "./LearnerProgressReportContainer";

const LearnerProgressSelector = () => {
  const { currentUser } = useAuth();
  const { setIsLoading } = useContext(LoadingContext);
  const { getLearners } = useContext(TransactionContext);
  const { getAllLearners } = useContext(TransactionContext);
  const { currentLearner, setCurrentLearnerId } = useContext(LearnerContext);
  const [selectedLearner, setSelectedLearner] = useState(null);
  const [learnersToPrintList, setLearnersToPrintList] = useState([]);
  const [learnerList, setLearnerList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetchLearners = async () => {
    //  Fetch data to print rostered learners
    setIsLoading(true);
    const learners = await getLearners();
    setIsLoading(false);
    setLearnersToPrintList(_.orderBy(learners, ["lastName"], ["asc"]));
  };

  const fetchAllLearners = async () => {
    //  Fetch relevant data to chose from all available learners and print all available learners
    setIsLoading(true);
    const learners = await getAllLearners();
    const listing = learners.map((learner) => {
      return {
        learnerName: `${learner.lastName}, ${learner.firstName}`,
        learnerId: learner.learnerId,
      };
    });
    setIsLoading(false);
    setLearnerList(_.orderBy(listing, ["lastName"], ["asc"]));
    setLearnersToPrintList(_.orderBy(learners, ["lastName"], ["asc"]));
  };

  useEffect(() => {
    // Load data and map for learners in both the drop down list and the payload for printing all rostered learners
    setCurrentLearnerId(null);
    if (currentUser && currentUser.role === "teacher") {
      fetchLearners();
      const listing = currentUser.roster.map((learner) => {
        return {
          learnerName: `${learner.lastName}, ${learner.firstName}`,
          learnerId: learner.learnerId,
        };
      });
      setLearnerList(_.orderBy(listing, ["learnerName"], ["asc"]));
    }
    if (currentUser && currentUser.role === "interventionist") {
      //  This could be interventionist and/or admin, not sure at this time...
      fetchAllLearners();
    }
    if (currentUser && currentUser.role === "admin") {
      // possible difference in learners loaded for admin????
      fetchAllLearners();
    }
  }, [currentUser]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleLearnerSelect = (event, newLearner) => {
    if (newLearner) {
      setCurrentLearnerId(newLearner.learnerId);
    } else setCurrentLearnerId(null);
    setSelectedLearner(newLearner);
  };
  return (
    <Container>
      <Grid item container direction="column">
        <Grid item style={{ marginTop: "65px" }}>
          <Typography variant="h2">Learner Progress Report</Typography>
        </Grid>
        {currentUser && (learnerList.length || currentUser.learnerDirectory) ? (
          <Fragment>
            <Grid item style={{ marginTop: "20px" }}>
              <div>
                {currentUser &&
                currentUser.role === "teacher" &&
                learnersToPrintList.length > 0 ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handlePrint();
                    }}
                  >
                    Print All Rostered Learners
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handlePrint();
                    }}
                  >
                    Print All Learners
                  </Button>
                )}
                <div style={{ display: "none" }}>
                  <PrintAllRosteredLearnersContainer
                    printReportComponent={componentRef}
                    learnersToPrintList={learnersToPrintList}
                  />
                </div>
              </div>
            </Grid>
            <Grid item style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Autocomplete
                id="learners"
                value={selectedLearner}
                onChange={handleLearnerSelect}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={
                  currentUser.learnerDirectory
                    ? currentUser.learnerDirectory
                    : learnerList
                }
                getOptionLabel={(option) => option.learnerName}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Learner"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {currentLearner && (
              <Grid item container>
                <LearnerProgressReportContainer
                  currentLearner={currentLearner}
                />
              </Grid>
            )}
          </Fragment>
        ) : (
          <Typography component="p">No Data to Load...</Typography>
        )}
      </Grid>
    </Container>
  );
};

export default LearnerProgressSelector;
