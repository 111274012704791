import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a new LNL score (purpose === 'addNew')
2.) used to view / edit an existing LNL score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: 26,
  aIsMissed: false,
  bIsMissed: false,
  cIsMissed: false,
  dIsMissed: false,
  eIsMissed: false,
  fIsMissed: false,
  gIsMissed: false,
  hIsMissed: false,
  iIsMissed: false,
  jIsMissed: false,
  kIsMissed: false,
  lIsMissed: false,
  mIsMissed: false,
  nIsMissed: false,
  oIsMissed: false,
  pIsMissed: false,
  qIsMissed: false,
  rIsMissed: false,
  sIsMissed: false,
  tIsMissed: false,
  uIsMissed: false,
  vIsMissed: false,
  wIsMissed: false,
  xIsMissed: false,
  yIsMissed: false,
  zIsMissed: false,
};

const upDateInitialState = {
  score: 0,
  aIsMissed: true,
  bIsMissed: true,
  cIsMissed: true,
  dIsMissed: true,
  eIsMissed: true,
  fIsMissed: true,
  gIsMissed: true,
  hIsMissed: true,
  iIsMissed: true,
  jIsMissed: true,
  kIsMissed: true,
  lIsMissed: true,
  mIsMissed: true,
  nIsMissed: true,
  oIsMissed: true,
  pIsMissed: true,
  qIsMissed: true,
  rIsMissed: true,
  sIsMissed: true,
  tIsMissed: true,
  uIsMissed: true,
  vIsMissed: true,
  wIsMissed: true,
  xIsMissed: true,
  yIsMissed: true,
  zIsMissed: true,
};

const LNLScorePanel = ({
  lnlScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewLNLPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeLNLScoreData, setActiveLNLScoreData] = useState(null);
  const [originalLNLScoreData, setOriginalLNLScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalLNLScoreData({ ...lnlScoreSet });
      setActiveLNLScoreData({ ...lnlScoreSet });
    } else {
      setActiveLNLScoreData({ ...lnlScoreSet });
      setIsEditing(true);
    }
  }, [lnlScoreSet, purpose]);

  const saveNewLNLScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeLNLScoreData, "lnlScores");
    handleLoadingClose();
    setShowNewLNLPanel(false);
  };

  const editLNLScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalLNLScoreData, activeLNLScoreData)) {
      setMessage("warning", `No changes were made to the LNL Score...`, 3000);
    } else {
      handleLoadingOpen();
      await editScore(activeLNLScoreData, "lnlScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteLNLScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeLNLScoreData, "lnlScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveLNLScoreData({
      ...activeLNLScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeLNLScoreData.score - 1
          : activeLNLScoreData.score + 1,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleToggle = () => {
    if (activeLNLScoreData.score === 26) {
      setActiveLNLScoreData(upDateInitialState);
    } else {
      setActiveLNLScoreData(addNewInitialState);
    }
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewLNLScore(activeLNLScoreData);
    } else {
      editLNLScore();
    }
  };

  const handleCancel = () => {
    setActiveLNLScoreData(originalLNLScoreData);
    setIsEditing(false);
  };
  return activeLNLScoreData ? (
    
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction='column' alignItems='flex-start' xs={6}>
          <Grid item>
            <Typography>Score: {activeLNLScoreData.score}</Typography>
          </Grid>
          {activeLNLScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeLNLScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeLNLScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeLNLScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeLNLScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeLNLScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent='flex-end' xs={6}>
          {isEditing ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeLNLScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeLNLScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-lnlscore'
                aria-describedby='alert-dialog-delete-lnlscore-description'
              >
                <DialogTitle id='alert-dialog-delete-lnlscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-lnlscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteLNLScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-lnlscore'
                aria-describedby='alert-dialog-delete-lnlscore-description'
              >
                <DialogTitle id='alert-dialog-delete-lnlscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-lnlscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteLNLScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction='column'>
        <Grid item container>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='cIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='c'
                labelPlacement='bottom'
                checked={activeLNLScoreData.cIsMissed}
                value={activeLNLScoreData.cIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='gIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='g'
                labelPlacement='bottom'
                checked={activeLNLScoreData.gIsMissed}
                value={activeLNLScoreData.gIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='sIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='s'
                labelPlacement='bottom'
                checked={activeLNLScoreData.sIsMissed}
                value={activeLNLScoreData.sIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='iIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='i'
                labelPlacement='bottom'
                checked={activeLNLScoreData.iIsMissed}
                value={activeLNLScoreData.iIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='bIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='b'
                labelPlacement='bottom'
                checked={activeLNLScoreData.bIsMissed}
                value={activeLNLScoreData.bIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='xIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='x'
                labelPlacement='bottom'
                checked={activeLNLScoreData.xIsMissed}
                value={activeLNLScoreData.xIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='lIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='l'
                labelPlacement='bottom'
                checked={activeLNLScoreData.lIsMissed}
                value={activeLNLScoreData.lIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='qIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='q'
                labelPlacement='bottom'
                checked={activeLNLScoreData.qIsMissed}
                value={activeLNLScoreData.qIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='hIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='h'
                labelPlacement='bottom'
                checked={activeLNLScoreData.hIsMissed}
                value={activeLNLScoreData.hIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='wIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='w'
                labelPlacement='bottom'
                checked={activeLNLScoreData.wIsMissed}
                value={activeLNLScoreData.wIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='fIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='f'
                labelPlacement='bottom'
                checked={activeLNLScoreData.fIsMissed}
                value={activeLNLScoreData.fIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='rIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='r'
                labelPlacement='bottom'
                checked={activeLNLScoreData.rIsMissed}
                value={activeLNLScoreData.rIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='jIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='j'
                labelPlacement='bottom'
                checked={activeLNLScoreData.jIsMissed}
                value={activeLNLScoreData.jIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='mIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='m'
                labelPlacement='bottom'
                checked={activeLNLScoreData.mIsMissed}
                value={activeLNLScoreData.mIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='oIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='o'
                labelPlacement='bottom'
                checked={activeLNLScoreData.oIsMissed}
                value={activeLNLScoreData.oIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='vIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='v'
                labelPlacement='bottom'
                checked={activeLNLScoreData.vIsMissed}
                value={activeLNLScoreData.vIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='pIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='p'
                labelPlacement='bottom'
                checked={activeLNLScoreData.pIsMissed}
                value={activeLNLScoreData.pIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='tIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='t'
                labelPlacement='bottom'
                checked={activeLNLScoreData.tIsMissed}
                value={activeLNLScoreData.tIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='dIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='d'
                labelPlacement='bottom'
                checked={activeLNLScoreData.dIsMissed}
                value={activeLNLScoreData.dIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='uIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='u'
                labelPlacement='bottom'
                checked={activeLNLScoreData.uIsMissed}
                value={activeLNLScoreData.uIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='aIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='a'
                labelPlacement='bottom'
                checked={activeLNLScoreData.aIsMissed}
                value={activeLNLScoreData.aIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='yIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='y'
                labelPlacement='bottom'
                checked={activeLNLScoreData.yIsMissed}
                value={activeLNLScoreData.yIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='nIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='n'
                labelPlacement='bottom'
                checked={activeLNLScoreData.nIsMissed}
                value={activeLNLScoreData.nIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='zIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='z'
                labelPlacement='bottom'
                checked={activeLNLScoreData.zIsMissed}
                value={activeLNLScoreData.zIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='kIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='k'
                labelPlacement='bottom'
                checked={activeLNLScoreData.kIsMissed}
                value={activeLNLScoreData.kIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='eIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='e'
                labelPlacement='bottom'
                checked={activeLNLScoreData.eIsMissed}
                value={activeLNLScoreData.eIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default LNLScorePanel;
