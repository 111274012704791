import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import SPCScorePanel from "./SPCScorePanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { LearnerContext } from "../../contexts/LearnerContext";
import { useContext, useEffect } from "react";

const SPCScoresContainer = ({
  spcScores,
  learnerId,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const [showNewSPCPanel, setShowNewSPCPanel] = useState(false);
  const { currentLearner } = useContext(LearnerContext);
  useEffect(() => {
    setShowNewSPCPanel(false);
  }, [currentLearner]);
  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">Sounds from Picture Cues</Typography>
          </Grid>
          <Grid item>
            {showNewSPCPanel ? (
              <CancelButton onClick={() => setShowNewSPCPanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton onClick={() => setShowNewSPCPanel(true)}>
                Add New Score
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewSPCPanel && (
          <Grid item container direction="column">
            <SPCScorePanel
              purpose={"addNew"}
              learnerId={learnerId}
              setShowNewSPCPanel={setShowNewSPCPanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {spcScores && spcScores.length ? (
          _.orderBy(spcScores, ["createdAt"], ["desc"]).map((spcScoreSet) => (
            <SPCScorePanel
              key={spcScoreSet.createdAt}
              learnerId={learnerId}
              spcScoreSet={spcScoreSet}
              purpose={"view"}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          ))
        ) : (
          <Typography>No SPC Scores recorded yet...</Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default SPCScoresContainer;
