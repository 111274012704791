import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import { SystemSettingsContext } from "../../contexts/SystemSettingsContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

// This is legacy, should be removed after the 2023/2024 School Year as Sightwords will no longer be recorded being replaced by Irregular Words -Billy 5/24/23
/*
This component has two potential uses:
1.) used to display a panel to create a new Sight Words score (purpose === 'addNew')
2.) used to view / edit an existing Sight Words score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: 25,
  IIsMissed: false,
  aIsMissed: false,
  amIsMissed: false,
  andIsMissed: false,
  areIsMissed: false,
  atIsMissed: false,
  canIsMissed: false,
  forIsMissed: false,
  goIsMissed: false,
  heIsMissed: false,
  hereIsMissed: false,
  inIsMissed: false,
  isIsMissed: false,
  itIsMissed: false,
  likeIsMissed: false,
  lookIsMissed: false,
  myIsMissed: false,
  onIsMissed: false,
  saidIsMissed: false,
  seeIsMissed: false,
  theIsMissed: false,
  theyIsMissed: false,
  toIsMissed: false,
  wentIsMissed: false,
  youIsMissed: false,
};

const upDateInitialState = {
  score: 0,
  IIsMissed: true,
  aIsMissed: true,
  amIsMissed: true,
  andIsMissed: true,
  areIsMissed: true,
  atIsMissed: true,
  canIsMissed: true,
  forIsMissed: true,
  goIsMissed: true,
  heIsMissed: true,
  hereIsMissed: true,
  inIsMissed: true,
  isIsMissed: true,
  itIsMissed: true,
  likeIsMissed: true,
  lookIsMissed: true,
  myIsMissed: true,
  onIsMissed: true,
  saidIsMissed: true,
  seeIsMissed: true,
  theIsMissed: true,
  theyIsMissed: true,
  toIsMissed: true,
  wentIsMissed: true,
  youIsMissed: true,
};

const SightWordsPanel = ({
  swScoreSet = null,
  purpose = "view",
  setShowNewSWPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { systemSettings } = useContext(SystemSettingsContext);
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeSWScoreData, setActiveSWScoreData] = useState(null);
  const [originalSWScoreData, setOriginalSWScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalSWScoreData({ ...swScoreSet });
      setActiveSWScoreData({ ...swScoreSet });
    } else {
      setActiveSWScoreData(setInitialState());
      setIsEditing(true);
    }
  }, [swScoreSet, purpose]);

  const setInitialState = () => {
    let addNewInitialState = {};
    addNewInitialState["score"] = 25;
    systemSettings.sightWords.map(
      (sightWord) => (addNewInitialState[`${sightWord}IsMissed`] = false)
    );
    return addNewInitialState;
  };

  const saveNewSWScore = async () => {
    saveNewScore(activeSWScoreData, "swScores");
    handleLoadingOpen();
    await setShowNewSWPanel(false);
    handleLoadingClose();
  };

  const editSWScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalSWScoreData, activeSWScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the Sight Words Score...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeSWScoreData, "swScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteSWScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeSWScoreData, "swScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveSWScoreData({
      ...activeSWScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeSWScoreData.score - 1
          : activeSWScoreData.score + 1,
    });
  };

  const handleToggle = () => {
    if (activeSWScoreData.score === 25) {
      setActiveSWScoreData(upDateInitialState);
    } else {
      setActiveSWScoreData(addNewInitialState);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewSWScore(activeSWScoreData);
    } else {
      editSWScore();
    }
  };

  const handleCancel = () => {
    setActiveSWScoreData(originalSWScoreData);
    setIsEditing(false);
  };

  return activeSWScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction="column" alignItems="flex-start" xs={6}>
          <Grid item>
            <Typography variant="h6">Legacy Sight Words Scores</Typography>
          </Grid>
          <Grid item>
            <Typography>Score: {activeSWScoreData.score}</Typography>
          </Grid>
          {activeSWScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeSWScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeSWScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeSWScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeSWScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeSWScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent="flex-end" xs={6}>
          {isEditing ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeSWScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeSWScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent="flex-end">
              {/* <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid> */}
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-swscore"
                aria-describedby="alert-dialog-delete-swscore-description"
              >
                <DialogTitle id="alert-dialog-delete-swscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-swscore-description">
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteSWScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-swscore"
                aria-describedby="alert-dialog-delete-swscore-description"
              >
                <DialogTitle id="alert-dialog-delete-swscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-swscore-description">
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteSWScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction="column">
        <Grid item container>
          <Grid item>
            <FormGroup row>
              {systemSettings && systemSettings.sightWords ? (
                systemSettings.sightWords.map((sightWord) => (
                  <FormControlLabel
                    key={sightWord}
                    control={
                      <StyledSwitch
                        name={`${sightWord}IsMissed`}
                        onChange={handleSwitchToggle}
                      />
                    }
                    label={`${sightWord}`}
                    labelPlacement="bottom"
                    checked={activeSWScoreData[`${sightWord}IsMissed`]}
                    value={activeSWScoreData[`${sightWord}IsMissed`]}
                    disabled={!isEditing}
                  />
                ))
              ) : (
                <span>Loading...</span>
              )}
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default SightWordsPanel;
