import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import LNUScorePanel from "./LNUScorePanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { LearnerContext } from "../../contexts/LearnerContext";
import { useContext, useEffect } from "react";

const LNUScoresContainer = ({
  lnuScores,
  learnerId,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const [showNewLNUPanel, setShowNewLNUPanel] = useState(false);
  const { currentLearner } = useContext(LearnerContext);
  useEffect(() => {
    setShowNewLNUPanel(false);
  }, [currentLearner]);
  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">
              Uppercase Letter Name Identification
            </Typography>
          </Grid>
          <Grid item>
            {showNewLNUPanel ? (
              <CancelButton onClick={() => setShowNewLNUPanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton onClick={() => setShowNewLNUPanel(true)}>
                Add New Score
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewLNUPanel && (
          <Grid item container direction="column">
            <LNUScorePanel
              purpose={"addNew"}
              learnerId={learnerId}
              setShowNewLNUPanel={setShowNewLNUPanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {lnuScores && lnuScores.length ? (
          _.orderBy(lnuScores, ["createdAt"], ["desc"]).map((lnuScoreSet) => (
            <LNUScorePanel
              key={lnuScoreSet.createdAt}
              learnerId={learnerId}
              lnuScoreSet={lnuScoreSet}
              purpose={"view"}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          ))
        ) : (
          <Typography>No LNU Scores recorded yet...</Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default LNUScoresContainer;
