import React, { useState, useEffect, createContext } from "react";
import { firestore } from "../firebaseInit";
import { useAuth } from "./AuthContext";
import { currentSchoolYear } from "../config/currentSchoolYear";
import { doc, onSnapshot } from "firebase/firestore";

export const LearnerContext = createContext();

export const LearnerProvider = (props) => {
  const [currentLearnerId, setCurrentLearnerId] = useState(null);
  const [currentLearner, setCurrentLearner] = useState(null);

  // subscribe to learner changes
  useEffect(() => {
    const unsubscribe = setLearnerListener(currentLearnerId);
    return () => {
      unsubscribe();
    };
  }, [currentLearnerId]);

  // establishes a snapshot listener, which gets changes to the user profile in realtime
  const setLearnerListener = (currentLearnerId) => {
    if (!currentLearnerId) {
      setCurrentLearner(null);
      return () => setCurrentLearner(null);
    }
    return (
      onSnapshot(doc(firestore, "learners", `${currentLearnerId}`), (doc) => {
        return setCurrentLearner({
          ...doc.data(),
        });
      }),
      (error) => {
        // ...
      }
    );
  };

  return (
    <LearnerContext.Provider
      value={{ currentLearnerId, currentLearner, setCurrentLearnerId }}
    >
      {props.children}
    </LearnerContext.Provider>
  );
};
