import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { SystemSettingsContext } from "../../contexts/SystemSettingsContext";
import PrintAllScreenedLearners from "./PrintAllScreenedLearners";

const PrintAllScreenedLearnersContainer = ({
  learnersToPrintList,
  printReportComponent,
}) => {
  const [learnerList, setLearnerList] = useState([]);
  const { systemSettings } = useContext(SystemSettingsContext);

  useEffect(() => {
    formatLearnerList(learnersToPrintList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnersToPrintList]);

  // Compile Reading Readiness and Number Readiness for Screened Learners
  const formatLearnerList = (learners) => {
    const formattedLearners = learners.map((learner) => {
      if (
        learner.lnuScores &&
        learner.lnuScores.length &&
        learner.lnlScores &&
        learner.lnlScores.length &&
        learner.lsiScores &&
        learner.lsiScores.length &&
        // learner.swScores &&
        // learner.swScores.length &&
        learner.rhymIdScores &&
        learner.rhymIdScores.length &&
        learner.rhymProdScores &&
        learner.rhymProdScores.length &&
        learner.numIdScores &&
        learner.numIdScores.length &&
        learner.countingScores &&
        learner.countingScores.length &&
        learner.behaviorScores &&
        learner.behaviorScores.length &&
        learner.fmScores &&
        learner.fmScores.length
      ) {
        if (!learner.numberReadiness && !learner.readingReadiness) {
          // get latest lnl, lnu, and lsi scores
          const latestLNU = _.orderBy(learner.lnuScores, [
            "createdAt",
            "asc",
          ]).pop();
          const latestLNL = _.orderBy(learner.lnlScores, [
            "createdAt",
            "asc",
          ]).pop();
          const latestLSI = _.orderBy(learner.lsiScores, [
            "createdAt",
            "asc",
          ]).pop();

          const rReadinessScore =
            latestLNU.score + latestLNL.score + latestLSI.score > 36
              ? {
                  score: latestLNU.score + latestLNL.score + latestLSI.score,
                  text: `${learner.firstName} is overall prepared for Kindergarten in terms of reading readiness.`,
                }
              : {
                  score: latestLNU.score + latestLNL.score + latestLSI.score,
                  text: `${learner.firstName} will require some practice to reach Kindergarten reading readiness.  Practice identifying uppercase and lowercase letters.  Practice identifying letter sounds from picture cues, for instance showing a picture of a monkey and having them tell you the letter it begins with.`,
                };

          // use the score values to compile the readiness set and return

          // get latest numId and counting scores
          const latestNumId = _.orderBy(learner.numIdScores, [
            "createdAt",
            "asc",
          ]).pop();
          const latestCounting = _.orderBy(learner.countingScores, [
            "createdAt",
            "asc",
          ]).pop();

          const nReadinessScore =
            parseInt(latestCounting.score, 10) +
              parseInt(latestNumId.score, 10) >
            16
              ? {
                  text: `${learner.firstName} is overall prepared for Kindergarten in terms of number readiness.`,
                  score:
                    parseInt(latestCounting.score, 10) +
                    parseInt(latestNumId.score, 10),
                }
              : {
                  text: `${learner.firstName} will require some practice to reach Kindergarten number readiness.  Practice identifying the numbers 0-9.`,
                  score:
                    parseInt(latestCounting.score, 10) +
                    parseInt(latestNumId.score, 10),
                };

          // use the score values to compile the readiness set and return

          //   // determines missed sight words from a literacy score set
          //   const determineMissedIrregularWords = (scoreSet) => {
          //     const missedWordsArray = _.compact(
          //       systemSettings.irregularWords.map((sightWord) => {
          //         const word = sightWord.toLowerCase();
          //         if (scoreSet[`${word}IsMissed`]) {
          //           return word[0].toUpperCase() + word.substr(1);
          //         }
          //         return null;
          //       })
          //     );
          //     return missedWordsArray.toString();
          //   };

          // determine words missed from Rhyming Id Score Set
          const determineMissedRhymIdWords = (scoreSet) => {
            const missedWordsArray = [];
            if (scoreSet.bedFedMissed) {
              missedWordsArray.push("bed/fed");
            }
            if (scoreSet.beepDeepMissed) {
              missedWordsArray.push("beep/deep");
            }
            if (scoreSet.dadSadMissed) {
              missedWordsArray.push("dad/sad");
            }
            if (scoreSet.handCarMissed) {
              missedWordsArray.push("hand/car");
            }
            if (scoreSet.houseMoonMissed) {
              missedWordsArray.push("house/moon");
            }
            if (scoreSet.pigBigMissed) {
              missedWordsArray.push("pig/big");
            }
            if (scoreSet.sunCakeMissed) {
              missedWordsArray.push("sun/cake");
            }

            return missedWordsArray.toString();
          };

          // determine Fine Motor Score Set
          const determineMissedFMScores = (scoreSet) => {
            const missedFMScoresArray = [];
            if (
              scoreSet.fmCutHold === "awkward" ||
              scoreSet.fmCutLine === "unable"
            ) {
              missedFMScoresArray.push(
                `${learner.firstName} should practice holding scissors correctly and cutting along a line. `
              );
            }
            // if (scoreSet.fmCutLine === "unable") {
            //   missedFMScoresArray.push(
            //     `${learner.firstName} is unable to cut a straight line. `
            //   );
            // }
            if (
              scoreSet.fmWritingName === "noLetters" ||
              scoreSet.fmWritingName === "atLeast2Letters" ||
              scoreSet.fmPenGrasp === "awkward"
            ) {
              missedFMScoresArray.push(
                `${learner.firstName} should practice writing first name. `
              );
            }
            // if (scoreSet.fmWritingName === "atLeast2Letters") {
            //   missedFMScoresArray.push(
            //     `${learner.firstName} could write at least two letters of their name. `
            //   );
            // }
            // if (scoreSet.fmPenGrasp === "awkward") {
            //   missedFMScoresArray.push(`${learner.firstName} awkwardly holds a pen. `);
            // }
            if (
              (scoreSet.circleIsMissed === true ||
                scoreSet.rectIsMissed === true,
              scoreSet.triangleIsMissed === true ||
                scoreSet.crossIsMissed === true ||
                scoreSet.dashIsMissed === true)
            ) {
              missedFMScoresArray.push(
                `${learner.firstName} should practice drawing or tracing the outline of shapes, especially circles, squares, rectangles and triangles. `
              );
            }
            // if (scoreSet.circleIsMissed === true) {
            //   missedFMScoresArray.push(`circle, `);
            // }
            // if (scoreSet.crossIsMissed === true) {
            //   missedFMScoresArray.push(`cross, `);
            // }
            // if (scoreSet.triangleIsMissed === true) {
            //   missedFMScoresArray.push(`triangle, `);
            // }
            // if (scoreSet.rectisMissed === true) {
            //   missedFMScoresArray.push(`rectangle, `);
            // }
            // if (scoreSet.dashIsMissed === true) {
            //   missedFMScoresArray.push("dash, ");
            // }

            return missedFMScoresArray.join(" ");
          };

          // determine Behavior Score Set
          const determineMissedBehaviors = (scoreSet) => {
            const missedBehaviorsArray = [];
            if (scoreSet.behaviorCrying === "yes") {
              missedBehaviorsArray.push(
                `${learner.firstName} exhibited crying during tasks. `
              );
            }
            if (scoreSet.behaviorImpulsive === "yes") {
              missedBehaviorsArray.push(
                "Impulsive behaviors were significantly present while performing tasks. "
              );
            }
            if (scoreSet.behaviorNeedsPrompting === "yes") {
              missedBehaviorsArray.push(
                "Repeated prompts were needed during testing. "
              );
            }
            if (scoreSet.behaviorNotTrying === "yes") {
              missedBehaviorsArray.push(
                `${learner.firstName} was unwilling to perform some of the tasks. `
              );
            }
            if (scoreSet.behaviorSeparating === "yes") {
              missedBehaviorsArray.push(
                `${learner.firstName} had difficulty leaving their guardian to perform screening tasks. `
              );
            }
            if (scoreSet.behaviorTaskDifficulty === "yes") {
              missedBehaviorsArray.push(
                `${learner.firstName} had trouble staying on task. `
              );
            }
            if (scoreSet.behaviorWiggling === "yes") {
              missedBehaviorsArray.push(
                `${learner.firstName} exhibited notable fidgeting while trying to complete tasks.`
              );
            }

            return missedBehaviorsArray.join(" ");
          };

          // determine words missed from Rhyming Prod Score Set
          const determineMissedRhymProdWords = (scoreSet) => {
            const missedWordsArray = [];
            if (scoreSet.henMissed) {
              missedWordsArray.push("hen");
            }
            if (scoreSet.mugMissed) {
              missedWordsArray.push("mug");
            }
            if (scoreSet.panMissed) {
              missedWordsArray.push("pan");
            }
            if (scoreSet.topMissed) {
              missedWordsArray.push("top");
            }
            if (scoreSet.wigMissed) {
              missedWordsArray.push("wig");
            }

            return missedWordsArray.toString();
          };

          // determines missing letters from a literacy score set
          const determineMissedLetters = (scoreSet) => {
            const missedLetterArray = [];
            if (scoreSet.aIsMissed) {
              missedLetterArray.push("a");
            }
            if (scoreSet.bIsMissed) {
              missedLetterArray.push("b");
            }
            if (scoreSet.cIsMissed) {
              missedLetterArray.push("c");
            }
            if (scoreSet.dIsMissed) {
              missedLetterArray.push("d");
            }
            if (scoreSet.eIsMissed) {
              missedLetterArray.push("e");
            }
            if (scoreSet.fIsMissed) {
              missedLetterArray.push("f");
            }
            if (scoreSet.gIsMissed) {
              missedLetterArray.push("g");
            }
            if (scoreSet.hIsMissed) {
              missedLetterArray.push("h");
            }
            if (scoreSet.iIsMissed) {
              missedLetterArray.push("i");
            }
            if (scoreSet.jIsMissed) {
              missedLetterArray.push("j");
            }
            if (scoreSet.kIsMissed) {
              missedLetterArray.push("k");
            }
            if (scoreSet.lIsMissed) {
              missedLetterArray.push("l");
            }
            if (scoreSet.mIsMissed) {
              missedLetterArray.push("m");
            }
            if (scoreSet.nIsMissed) {
              missedLetterArray.push("n");
            }
            if (scoreSet.oIsMissed) {
              missedLetterArray.push("o");
            }
            if (scoreSet.pIsMissed) {
              missedLetterArray.push("p");
            }
            if (scoreSet.qIsMissed) {
              missedLetterArray.push("q");
            }
            if (scoreSet.rIsMissed) {
              missedLetterArray.push("r");
            }
            if (scoreSet.sIsMissed) {
              missedLetterArray.push("s");
            }
            if (scoreSet.tIsMissed) {
              missedLetterArray.push("t");
            }
            if (scoreSet.uIsMissed) {
              missedLetterArray.push("u");
            }
            if (scoreSet.vIsMissed) {
              missedLetterArray.push("v");
            }
            if (scoreSet.wIsMissed) {
              missedLetterArray.push("w");
            }
            if (scoreSet.xIsMissed) {
              missedLetterArray.push("x");
            }
            if (scoreSet.yIsMissed) {
              missedLetterArray.push("y");
            }
            if (scoreSet.zIsMissed) {
              missedLetterArray.push("z");
            }

            return missedLetterArray.toString();
          };

          // determines missing numbers from a numeracy score set
          const determineMissedNumbers = (scoreSet) => {
            const missedNumbersArray = [];
            for (let i = 0; i < 21; i++) {
              if (scoreSet[`missed${i}`]) {
                missedNumbersArray.push(`${i}`);
              }
            }

            return missedNumbersArray.toString();
          };

          // compile the LNU score set for display
          const lnuScores =
            learner.lnuScores && learner.lnuScores.length
              ? learner.lnuScores.map((lnuScoreSet) => {
                  return {
                    createdAt: lnuScoreSet.createdAt,
                    score: lnuScoreSet.score,
                    missedLetters: determineMissedLetters(lnuScoreSet),
                  };
                })
              : null;

          // compile the LNL score set for display
          const lnlScores = learner.lnlScores.map((lnlScoreSet) => {
            return {
              createdAt: lnlScoreSet.createdAt,
              score: lnlScoreSet.score,
              missedLetters: determineMissedLetters(lnlScoreSet),
            };
          });

          // compile the LSI score set for display
          const lsiScores = learner.lsiScores.map((lsiScoreSet) => {
            return {
              createdAt: lsiScoreSet.createdAt,
              score: lsiScoreSet.score,
              missedLetters: determineMissedLetters(lsiScoreSet),
            };
          });

          //   // compile the SW score set for display
          //   const swScores = learner.swScores.map((swScoreSet) => {
          //     return {
          //       createdAt: swScoreSet.createdAt,
          //       score: swScoreSet.score,
          //       missedWords: determineMissedIrregularWords(swScoreSet),
          //     };
          //   });

          // compile the Rhyming Id score set for display
          // Add variable Text based on Score
          const rhymIdScores = learner.rhymIdScores.map((rhymIdScoreSet) => {
            return {
              createdAt: rhymIdScoreSet.createdAt,
              score: rhymIdScoreSet.score,
              missedWords: determineMissedRhymIdWords(rhymIdScoreSet),
              text:
                rhymIdScoreSet.score > 4
                  ? `${learner.firstName} was able to successfully identify rhyming and non-rhyming words.`
                  : `${learner.firstName} could use some practice identifying rhyming words such as he/me and dad/sad.  ${learner.firstName} should also be able to identify a word pair such as house/moon do not rhyme.`,
            };
          });

          // compile the Rhyming Prod score set for display
          const rhymProdScores = learner.rhymProdScores.map(
            (rhymProdScoreSet) => {
              return {
                createdAt: rhymProdScoreSet.createdAt,
                score: rhymProdScoreSet.score,
                missedWords: determineMissedRhymProdWords(rhymProdScoreSet),
                text:
                  rhymProdScoreSet.score > 4
                    ? `${learner.firstName} was able to successfully produce rhyming and non-rhyming words.`
                    : `${learner.firstName} could use some practice rhyming a word with a given word. For instance if "top" is the given word, responding with a word that rhymes with top like "mop."`,
              };
            }
          );

          //   // compile the Comment set for display
          //   const comments = learner.commScores.map((comments) => {
          //     return {
          //       createdAt: comments.createdAt,
          //       comment: comments.generalComment,
          //     };
          //   });

          // compile the counting score set for display
          const countingScores = learner.countingScores.map(
            (countingScoreSet) => {
              return {
                createdAt: countingScoreSet.createdAt,
                score: countingScoreSet.score,
              };
            }
          );

          // compile the numId score set for display
          const numIdScores = learner.numIdScores.map((numIdScoreSet) => {
            return {
              createdAt: numIdScoreSet.createdAt,
              score: numIdScoreSet.score,
              missedNumbers: determineMissedNumbers(numIdScoreSet),
            };
          });

          // compile the behavior score set for display
          const behaviorScores = learner.behaviorScores.map(
            (behaviorScoreSet) => {
              return {
                createdAt: behaviorScoreSet.createdAt,
                isUnsatisfactory: behaviorScoreSet.behaviorIsUnsatisfactory
                  ? "Unsatisfactory"
                  : "Satisfactory",
                score: behaviorScoreSet.behaviorIsUnsatisfactory
                  ? `${learner.firstName} exhibited the following behavior(s) while performing tasks. `
                  : `${learner.firstName} appears socially and emotionally prepared for Kindergarten. `,
                missed: determineMissedBehaviors(behaviorScoreSet),
              };
            }
          );

          // compile the Fine Motor score set for display
          // Add variable Text based on Score
          const fmScores = learner.fmScores.map((fmScoreSet) => {
            return {
              createdAt: fmScoreSet.createdAt,
              dominant:
                fmScoreSet.fmDominantHand.charAt(0).toUpperCase() +
                fmScoreSet.fmDominantHand.slice(1),
              isUnsatisfactory: fmScoreSet.fmIsUnsatisfactory
                ? "Unsatisfactory"
                : "Satisfactory",
              text: fmScoreSet.fmIsUnsatisfactory
                ? `${
                    learner.firstName
                  } will require some practice for Kindergarten in terms of fine motor ability.  ${determineMissedFMScores(
                    fmScoreSet
                  )}`
                : `${
                    learner.firstName
                  } is overall prepared for Kindergarten in terms of fine motor ability.  ${determineMissedFMScores(
                    fmScoreSet
                  )}`,
              missed: determineMissedFMScores(fmScoreSet),
            };
          });

          return {
            firstName: learner.firstName,
            lastName: learner.lastName,
            learnerId: learner.learnerId,
            birthday: learner.birthday,
            campRecommend: learner.campRecommend,
            readingReadyScore: rReadinessScore,
            numberReadyScore: nReadinessScore,
            compiledLNUScores: lnuScores,
            compiledLNLScores: lnlScores,
            compiledLSIScores: lsiScores,
            // compiledSWScores: swScores,
            compiledRhymIdScores: rhymIdScores,
            compiledRhymProdScores: rhymProdScores,
            compiledNumIdScores: numIdScores,
            compiledBehaviorScores: behaviorScores,
            // compiledComments: comments,
            compiledCountingScores: countingScores,
            compiledFmScores: fmScores,
          };
        }
      }
    });

    setLearnerList(_.compact(formattedLearners));
  };

  return (
    learnerList &&
    learnerList.length > 0 && (
      <PrintAllScreenedLearners
        learnerList={learnerList}
        ref={printReportComponent}
      />
    )
  );
};

export default PrintAllScreenedLearnersContainer;
