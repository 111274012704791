import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Container,
  Grid,
  TableContainer,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useAuth } from "../../../contexts/AuthContext";
import ReadingOverviewTable from "../ReadingOverviewTable";
import NumberOverviewTable from "../NumberOverviewTable";

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    backgroundColor: theme.palette.common.darkOrange,
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginWidth: "25px",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <TableContainer component={Paper}>{children}</TableContainer>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const TeacherLandingPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { currentUser } = useAuth();

  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return currentUser && currentUser.roster && currentUser.roster.length ? (
    <Container>
      <Grid container direction='column'>
        <Grid
          item
          container
          direction='column'
          style={{ marginTop: "65px", marginBottom: "20px" }}
        >
          <Grid item>
            <Typography variant='h3' color={theme.palette.primary.dark}>
              Roster Overview
            </Typography>
          </Grid>
          <Grid item>
            <Typography component='p' color={theme.palette.primary.dark}>
              (only the latest scores are displayed here...)
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Paper className={classes.tabRoot}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='secondary'
              textColor='secondary'
            >
              <Tab className={classes.tab} label='Reading Readiness' />
              <Tab className={classes.tab} label='Number Readiness' />
            </Tabs>
          </Paper>
          <TabPanel value={value} index={0}>
            <ReadingOverviewTable />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NumberOverviewTable />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Container>
      <Grid container style={{ marginTop: "100px" }}>
        <Grid item>
          <Typography variant='h3'>No Rostered Learners Available</Typography>
        </Grid>
        <Grid item style={{ marginTop: "30px" }}>
          <Typography variant='body1'>
            If you don't see a dashboard or list of learners, contact support by
            emailing kinderbase_support@aurora-schools.org when able.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeacherLandingPage;
