import React, { useState, useContext } from "react";
import {
  FormControlLabel,
  Grid,
  Typography,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { withStyles } from "@mui/styles";
import LNUScoreContainer from "./LNUScoreContainer";
import LNLScoreContainer from "./LNLScoreContainer";
import IrregularWordsContainer from "./IrregularWordsContainer";
import FineMotorContainer from "./FineMotorContainer";
import BehavioralContainer from "./BehavioralContainer";
import GenCommContainer from "./GenCommContainer";
import SpeechContainer from "./SpeechContainer";
import LSIScoreContainer from "./LSIScoreContainer";
import CountingScoreContainer from "./CountingScoreContainer";
import NumIdScoreContainer from "./NumIdScoreContainer";
import SPCScoreContainer from "./SPCScoreContainer";
import RhymIdContainer from "./RhymIdContainer";
import RhymProdContainer from "./RhymProdContainer";
import SGWHContainer from "./SGWHContainer";
import { TransactionContext } from "../../contexts/TransactionContext";

const LearnerDetailsPage = ({ currentLearner }) => {
  const theme = useTheme();
  const { toggleLearnerCampRecommend } = useContext(TransactionContext);
  const fullScreen = "true"; /*useMediaQuery(theme.breakpoints.down("sm"));*/

  const [isLoading, setIsLoading] = useState(false);
  const handleLoadingOpen = () => {
    setIsLoading(true);
  };

  const handleLoadingClose = () => {
    setIsLoading(false);
  };

  const StyledSwitch = withStyles({
    switchBase: {
      color: "green",
      "&$checked": {
        color: "red",
      },
      "&$checked + $track": {
        backgroundColor: "red",
      },
      "&$disabled": {
        color: "green",
        "&$checked + $track": {
          backgroundColor: "red",
        },
      },
    },
    checked: {},
    track: {},
    disabled: {},
  })(Switch);

  const handleSwitchToggle = async () => {
    setIsLoading(true);
    if (currentLearner.campRecommend === true) {
      await toggleLearnerCampRecommend(false);
    } else {
      await toggleLearnerCampRecommend(true);
    }
    setIsLoading(false);
  };

  return (
    <Grid container direction="column">
      <Dialog fullscreen={fullScreen} open={isLoading}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          style={{
            justifyContent: "center",
            marginTop: "80px",
            marginBottom: "20px",
          }}
        >
          <Grid item>
            <DialogTitle id="simple-dialog-title">Working...</DialogTitle>
          </Grid>
          <Grid item>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
      <Grid item>
        <Typography style={{ fontSize: "3em", marginTop: "100px" }}>
          {currentLearner.nickName
            ? `${currentLearner.nickName} ${currentLearner.lastName}`
            : `${currentLearner.firstName} ${currentLearner.lastName}`}
        </Typography>
      </Grid>
      {/* container for all score blocks */}
      <Grid item container direction="column">
        {currentLearner.teacherId == "screener" ? (
          <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Typography>Recommend Kindergarten Camp</Typography>
            <FormControlLabel
              control={
                <Switch
                  name="Kindergarten Camp"
                  color="secondary"
                  onChange={handleSwitchToggle}
                />
              }
              label={currentLearner.campRecommend ? "Yes" : "No"}
              labelPlacement="bottom"
              checked={currentLearner.campRecommend}
              value={currentLearner.campRecommend}
            />
          </Grid>
        ) : null}
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <LNUScoreContainer
            lnuScores={currentLearner.lnuScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <LNLScoreContainer
            lnlScores={currentLearner.lnlScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <LSIScoreContainer
            lsiScores={currentLearner.lsiScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <SPCScoreContainer
            spcScores={currentLearner.spcScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        {/* <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <IrregularWordsContainer
            iwScores={currentLearner.iwScores}
            swScores={currentLearner.swScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid> */}
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <RhymIdContainer
            rhymIdScores={currentLearner.rhymIdScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <RhymProdContainer
            rhymProdScores={currentLearner.rhymProdScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <CountingScoreContainer
            countingScores={currentLearner.countingScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <NumIdScoreContainer
            numIdScores={currentLearner.numIdScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <FineMotorContainer
            fmScores={currentLearner.fmScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid>
        {currentLearner.teacherId == "screener" ? (
          <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
            <BehavioralContainer
              behaviorScores={currentLearner.behaviorScores}
              learnerId={currentLearner.learnerId}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        ) : (
          <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
            <SGWHContainer
              sgwhScores={currentLearner.sgwhScores}
              learnerId={currentLearner.learnerId}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {/* <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <GenCommContainer
            commScores={currentLearner.commScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid> */}
        {/* <Grid item style={{ marginTop: "20px", marginBottom: "20px" }}>
          <SpeechContainer
            speechScores={currentLearner.speechScores}
            learnerId={currentLearner.learnerId}
            handleLoadingOpen={handleLoadingOpen}
            handleLoadingClose={handleLoadingClose}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default LearnerDetailsPage;
