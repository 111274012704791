import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a Fine Motor observation (purpose === 'addNew')
2.) used to view / edit an existing Fione Motor observation (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  // behaviorIsUnsatisfactory: false,
  behaviorSeparating: "no",
  behaviorCrying: "no",
  behaviorTaskDifficulty: "no",
  behaviorImpulsive: "no",
  behaviorNotTrying: "no",
  behaviorWiggling: "no",
  behaviorNeedsPrompting: "no",
};

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      width: "5.5em",
      [theme.breakpoints.down("md")]: { width: "5em" },
      [theme.breakpoints.down("xs")]: { width: "4em" },
    },
    root: {
      flexGrow: 1,
    },
  };
});

const BehavioralPanel = ({
  behaviorScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewBehaviorPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeBehaviorScoreData, setActiveBehaviorScoreData] = useState(null);
  const [originalBehaviorScoreData, setOriginalBehaviorScoreData] =
    useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalBehaviorScoreData({ ...behaviorScoreSet });
      setActiveBehaviorScoreData({ ...behaviorScoreSet });
    } else {
      setActiveBehaviorScoreData({ ...behaviorScoreSet });
      setIsEditing(true);
    }
  }, [behaviorScoreSet, purpose]);

  const saveNewBehaviorScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeBehaviorScoreData, "behaviorScores");
    handleLoadingClose();
    setShowNewBehaviorPanel(false);
  };

  const editBehaviorScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalBehaviorScoreData, activeBehaviorScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the Behaviors observation...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeBehaviorScoreData, "behaviorScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteBehaviorScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeBehaviorScoreData, "behaviorScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveBehaviorScoreData({
      ...activeBehaviorScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };

  const handleRadioChange = (e) => {
    setActiveBehaviorScoreData({
      ...activeBehaviorScoreData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewBehaviorScore(activeBehaviorScoreData);
    } else {
      editBehaviorScore();
    }
  };

  const handleCancel = () => {
    setActiveBehaviorScoreData(originalBehaviorScoreData);
    setIsEditing(false);
  };

  return activeBehaviorScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction="column" alignItems="flex-start" xs={6}>
          {activeBehaviorScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeBehaviorScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeBehaviorScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeBehaviorScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(
                  activeBehaviorScoreData.lastEdited
                ).toLocaleDateString("en-US")}{" "}
                by: {activeBehaviorScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent="flex-end" xs={6}>
          {isEditing ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeBehaviorScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-behscore"
                aria-describedby="alert-dialog-delete-behscore-description"
              >
                <DialogTitle id="alert-dialog-delete-behscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-behscore-description">
                    You are about to blow away this observation in a forever
                    kind of way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteBehaviorScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-behscore"
                aria-describedby="alert-dialog-delete-behscore-description"
              >
                <DialogTitle id="alert-dialog-delete-behscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-behscore-description">
                    You are about to blow away this observation in a forever
                    kind of way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteBehaviorScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid container>
        <Grid item container align="center" justifyContent="center">
          {/* <Grid item>
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
              Overall Behavioral Status:
            </Typography>
          </Grid> */}
          {/* <Grid item>
            <FormControlLabel
              control={
                <StyledSwitch
                  name='behaviorIsUnsatisfactory'
                  onChange={handleSwitchToggle}
                />
              }
              style={{ margin: "0px" }}
              label={
                activeBehaviorScoreData.behaviorIsUnsatisfactory
                  ? "Unsatisfactory"
                  : "Satisfactory"
              }
              checked={activeBehaviorScoreData.behaviorIsUnsatisfactory}
              value={activeBehaviorScoreData.behaviorIsUnsatisfactory}
              disabled={!isEditing}
            />
          </Grid> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography
            variant="h5"
            style={{ marginBottom: "10px", marginLeft: "20px" }}
          >
            Observed Behaviors
          </Typography>
        </Grid>
        <Grid item container direction="column" style={{ marginLeft: "40px" }}>
          <Grid item>
            <Typography variant="h6">
              Difficulty Seperating from Guardian
            </Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="separation-behavior"
                name="behaviorSeparating"
                value={activeBehaviorScoreData.behaviorSeparating}
                onChange={handleRadioChange}
              >
                <Grid item container>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="h6">Crying</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="crying-behavior"
                name="behaviorCrying"
                value={activeBehaviorScoreData.behaviorCrying}
                onChange={handleRadioChange}
              >
                <Grid item container>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="h6">Difficulty Attending to Task</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="task-difficulty-behavior"
                name="behaviorTaskDifficulty"
                value={activeBehaviorScoreData.behaviorTaskDifficulty}
                onChange={handleRadioChange}
              >
                <Grid item container>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="h6">Impulsive</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="impulsive-behavior"
                name="behaviorImpulsive"
                value={activeBehaviorScoreData.behaviorImpulsive}
                onChange={handleRadioChange}
              >
                <Grid item container>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="h6">Unwillingness to Try</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="not-trying-behavior"
                name="behaviorNotTrying"
                value={activeBehaviorScoreData.behaviorNotTrying}
                onChange={handleRadioChange}
              >
                <Grid item container>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="h6">Excessive Wiggling</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="wiggling-behavior"
                name="behaviorWiggling"
                value={activeBehaviorScoreData.behaviorWiggling}
                onChange={handleRadioChange}
              >
                <Grid item container>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="h6">Needs Prompting Repeatedly</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="needs-prompting-behavior"
                name="behaviorNeedsPrompting"
                value={activeBehaviorScoreData.behaviorNeedsPrompting}
                onChange={handleRadioChange}
              >
                <Grid item container>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      disabled={!isEditing}
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default BehavioralPanel;
