import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { ConstructionOutlined } from "@mui/icons-material";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a new LNU score (purpose === 'addNew')
2.) used to view / edit an existing LNU score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: 26,
  aIsMissed: false,
  bIsMissed: false,
  cIsMissed: false,
  dIsMissed: false,
  eIsMissed: false,
  fIsMissed: false,
  gIsMissed: false,
  hIsMissed: false,
  iIsMissed: false,
  jIsMissed: false,
  kIsMissed: false,
  lIsMissed: false,
  mIsMissed: false,
  nIsMissed: false,
  oIsMissed: false,
  pIsMissed: false,
  qIsMissed: false,
  rIsMissed: false,
  sIsMissed: false,
  tIsMissed: false,
  uIsMissed: false,
  vIsMissed: false,
  wIsMissed: false,
  xIsMissed: false,
  yIsMissed: false,
  zIsMissed: false,
};

const upDateInitialState = {
  score: 0,
  aIsMissed: true,
  bIsMissed: true,
  cIsMissed: true,
  dIsMissed: true,
  eIsMissed: true,
  fIsMissed: true,
  gIsMissed: true,
  hIsMissed: true,
  iIsMissed: true,
  jIsMissed: true,
  kIsMissed: true,
  lIsMissed: true,
  mIsMissed: true,
  nIsMissed: true,
  oIsMissed: true,
  pIsMissed: true,
  qIsMissed: true,
  rIsMissed: true,
  sIsMissed: true,
  tIsMissed: true,
  uIsMissed: true,
  vIsMissed: true,
  wIsMissed: true,
  xIsMissed: true,
  yIsMissed: true,
  zIsMissed: true,
};

const LNUScorePanel = ({
  lnuScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewLNUPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { editScore, deleteScore, saveNewScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeLNUScoreData, setActiveLNUScoreData] = useState(null);
  const [originalLNUScoreData, setOriginalLNUScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalLNUScoreData({ ...lnuScoreSet });
      setActiveLNUScoreData({ ...lnuScoreSet });
    } else {
      setActiveLNUScoreData({ ...lnuScoreSet });
      setIsEditing(true);
    }
  }, [lnuScoreSet, purpose]);
  const saveNewLNUScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeLNUScoreData, "lnuScores");
    handleLoadingClose();
    setShowNewLNUPanel(false);
  };

  const editLNUScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalLNUScoreData, activeLNUScoreData)) {
      setMessage("warning", `No changes were made to the LNU Score...`, 3000);
    } else {
      handleLoadingOpen();
      await editScore(activeLNUScoreData, "lnuScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteLNUScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeLNUScoreData, "lnuScores");
    handleLoadingClose();
  };
  const handleSwitchToggle = (e) => {
    setActiveLNUScoreData({
      ...activeLNUScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeLNUScoreData.score - 1
          : activeLNUScoreData.score + 1,
    });
  };

  const handleToggle = () => {
    if (activeLNUScoreData.score === 26) {
      setActiveLNUScoreData(upDateInitialState);
    } else {
      setActiveLNUScoreData(addNewInitialState);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewLNUScore(activeLNUScoreData);
    } else {
      editLNUScore();
    }
  };

  const handleCancel = () => {
    setActiveLNUScoreData(originalLNUScoreData);
    setIsEditing(false);
  };
  return activeLNUScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction="column" alignItems="flex-start" xs={6}>
          <Grid item>
            <Typography>Score: {activeLNUScoreData.score}</Typography>
          </Grid>
          {activeLNUScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeLNUScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeLNUScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeLNUScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeLNUScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeLNUScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent="flex-end" xs={6}>
          {isEditing ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeLNUScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeLNUScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-lnuscore"
                aria-describedby="alert-dialog-delete-lnuscore-description"
              >
                <DialogTitle id="alert-dialog-delete-lnuscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-lnuscore-description">
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteLNUScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-lnuscore"
                aria-describedby="alert-dialog-delete-lnuscore-description"
              >
                <DialogTitle id="alert-dialog-delete-lnuscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-lnuscore-description">
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteLNUScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction="column">
        <Grid item container>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="sIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="S"
                labelPlacement="bottom"
                checked={activeLNUScoreData.sIsMissed}
                value={activeLNUScoreData.sIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="gIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="G"
                labelPlacement="bottom"
                checked={activeLNUScoreData.gIsMissed}
                value={activeLNUScoreData.gIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="mIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="M"
                labelPlacement="bottom"
                checked={activeLNUScoreData.mIsMissed}
                value={activeLNUScoreData.mIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="iIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="I"
                labelPlacement="bottom"
                checked={activeLNUScoreData.iIsMissed}
                value={activeLNUScoreData.iIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="bIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="B"
                labelPlacement="bottom"
                checked={activeLNUScoreData.bIsMissed}
                value={activeLNUScoreData.bIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="xIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="X"
                labelPlacement="bottom"
                checked={activeLNUScoreData.xIsMissed}
                value={activeLNUScoreData.xIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="zIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="Z"
                labelPlacement="bottom"
                checked={activeLNUScoreData.zIsMissed}
                value={activeLNUScoreData.zIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="qIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="Q"
                labelPlacement="bottom"
                checked={activeLNUScoreData.qIsMissed}
                value={activeLNUScoreData.qIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="hIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="H"
                labelPlacement="bottom"
                checked={activeLNUScoreData.hIsMissed}
                value={activeLNUScoreData.hIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="wIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="W"
                labelPlacement="bottom"
                checked={activeLNUScoreData.wIsMissed}
                value={activeLNUScoreData.wIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="tIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="T"
                labelPlacement="bottom"
                checked={activeLNUScoreData.tIsMissed}
                value={activeLNUScoreData.tIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="rIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="R"
                labelPlacement="bottom"
                checked={activeLNUScoreData.rIsMissed}
                value={activeLNUScoreData.rIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="jIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="J"
                labelPlacement="bottom"
                checked={activeLNUScoreData.jIsMissed}
                value={activeLNUScoreData.jIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="cIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="C"
                labelPlacement="bottom"
                checked={activeLNUScoreData.cIsMissed}
                value={activeLNUScoreData.cIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="oIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="O"
                labelPlacement="bottom"
                checked={activeLNUScoreData.oIsMissed}
                value={activeLNUScoreData.oIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="vIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="V"
                labelPlacement="bottom"
                checked={activeLNUScoreData.vIsMissed}
                value={activeLNUScoreData.vIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="pIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="P"
                labelPlacement="bottom"
                checked={activeLNUScoreData.pIsMissed}
                value={activeLNUScoreData.pIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="fIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="F"
                labelPlacement="bottom"
                checked={activeLNUScoreData.fIsMissed}
                value={activeLNUScoreData.fIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="dIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="D"
                labelPlacement="bottom"
                checked={activeLNUScoreData.dIsMissed}
                value={activeLNUScoreData.dIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="uIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="U"
                labelPlacement="bottom"
                checked={activeLNUScoreData.uIsMissed}
                value={activeLNUScoreData.uIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="aIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="A"
                labelPlacement="bottom"
                checked={activeLNUScoreData.aIsMissed}
                value={activeLNUScoreData.aIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="yIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="Y"
                labelPlacement="bottom"
                checked={activeLNUScoreData.yIsMissed}
                value={activeLNUScoreData.yIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="nIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="N"
                labelPlacement="bottom"
                checked={activeLNUScoreData.nIsMissed}
                value={activeLNUScoreData.nIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="lIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="L"
                labelPlacement="bottom"
                checked={activeLNUScoreData.lIsMissed}
                value={activeLNUScoreData.lIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="kIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="K"
                labelPlacement="bottom"
                checked={activeLNUScoreData.kIsMissed}
                value={activeLNUScoreData.kIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="eIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="E"
                labelPlacement="bottom"
                checked={activeLNUScoreData.eIsMissed}
                value={activeLNUScoreData.eIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default LNUScorePanel;
