import { currentSchoolYear } from "../config/currentSchoolYear";
import { auth, firestore } from "../firebaseInit";
import { createContext, useContext, useEffect, useState } from "react";
import {
  doc,
  query,
  getDocs,
  onSnapshot,
  where,
  collection,
} from "firebase/firestore";
import {
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

// Google as the provider
const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

// establishing a var to hold the unsub function for the userDoc listener
let unsubscribeUserListener = null;

export default function AuthContextProvider({ children }) {
  // const { setMessage } = useContext(MessageContext);
  const [currentUser, setCurrentUser] = useState(null);

  const fetchUserDoc = async (user) => {
    // setup the query
    const q = query(
      collection(firestore, "users"),
      where("schoolYear", "==", currentSchoolYear),
      where("email", "==", user.email)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 0) {
      // we did not find a user to authenticate here...
      return null;
    } else {
      // setup the user doc to be returned,
      // fusing user auth data with firebase user account data
      const userDoc = querySnapshot.docs[0].data();

      return {
        ...userDoc,
        uid: querySnapshot.docs[0].id,
        photoURL: user.photoURL ? user.photoURL : null,
      };
    }
  };

  // establishes a snapshot listener, which gets changes to the userDoc
  // related data in realtime
  const setUserProfileListener = (user) => {
    if (!user || !user.email) {
      return null;
    }
    if (user.role === "guardian" || user.role === "admin") {
      return (
        onSnapshot(doc(firestore, "users", `${user.uid}`), (doc) => {
          // be sure to keep auth provider data integrated into userDoc
          return setCurrentUser({
            ...doc.data(),
            photoURL: user.photoURL ? user.photoURL : null,
          });
        }),
        (error) => {
          // ...
        }
      );
    } else {
      // we are dealing with a teacher, who has a school-year specific account
      return onSnapshot(
        doc(firestore, "users", `${user.uid}`),
        (doc) => {
          // be sure to keep auth provider data integrated into userDoc
          return setCurrentUser({
            ...doc.data(),
            photoURL: user.photoURL ? user.photoURL : null,
          });
        },
        (error) => {
          // ...
        }
      );
    }
  };

  const googleSignIn = async () => {
    await signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        // login success
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const googleSignOut = async () => {
    if (unsubscribeUserListener) {
      const result = unsubscribeUserListener();
    }

    await signOut(auth)
      .then(() => {
        // clear current user
        setCurrentUser(null);
      })
      .catch((error) => {
        console.error("There was an error signing out: ", error.message);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        // fetch their application specific user profile
        const userDoc = await fetchUserDoc(authUser);
        // perform post-login operations
        if (userDoc) {
          // establish a listener for changes to userDoc related data
          unsubscribeUserListener = await setUserProfileListener(userDoc);

          // update latest login information on the user doc
          // await updateLoginInfo(userDoc);
        } else {
          console.log(
            "We need to handle the case of an unidentified user hitting the app."
          );
        }
        //   // post a warning regarding the lack of a user profile
        //   setMessage(
        //     "warning",
        //     <Typography>
        //       The email used for sign up is not registered with our system.
        //       Please{" "}
        //       <Link style={{ color: "blue" }} to='/login-assist'>
        //         click this link
        //       </Link>{" "}
        //       for assistance if you feel that this is not correct.
        //     </Typography>,
        //     12000
        //   );
        //   auth.signOut();
        // }
        return setCurrentUser(userDoc);
      }
      return setCurrentUser(null);
    });

    return () => {
      if (unsubscribeUserListener) {
        return unsubscribeUserListener();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    currentUser,
    googleSignIn,
    googleSignOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
