import React from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { styled, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../assets/kbaselogo.png";

const features = [
  {
    key: 1,
    heading: "Designed by its users...",
    content:
      "Every aspect of Kinderbase has been designed and created to fit the needs of the very people who use it to collect data for optimal decision making.",
  },
  {
    key: 2,
    heading: "Historical Data...",
    content:
      "Growth over time and and quick addition of new data points by any facilitator.",
  },
  {
    key: 3,
    heading: "Data sharing...",
    content:
      "Data accruded over the course of Kindergarten is exported and provided to the next set of facilitators to provide as much useful insight into the growth of any learner.",
  },
];

const HeroTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: 60,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 80,
  },
  fontWeight: 900,
  fontFamily: "Architects Daughter",
  color: theme.palette.primary.main,
  lineHeight: "0.80em",
  marginTop: "15px",
  marginBottom: "25px",
}));

const MantraTypography = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  fontWeight: 900,
  fontFamily: "Architects Daughter",
  color: theme.palette.secondary.main,
}));

const LeadTypography = styled(Typography)(({ theme }) => ({
  fontSize: 25,
  fontWeight: 900,
  fontFamily: "Architects Daughter",
  color: theme.palette.secondary.dark,
}));

const LandingPage = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Container style={{ marginTop: "8em" }}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Avatar
              alt="logo"
              src={logo}
              sx={{
                width: matchesSm ? "10em" : "15em",
                height: matchesSm ? "10em" : "15em",
              }}
            />
          </Grid>
          <Grid item align="center">
            <HeroTypography variant="h10">KinderBase</HeroTypography>
          </Grid>
          <Grid item align="center">
            <MantraTypography variant="h8">
              Laying a solid foundation for a lifetime of learning...
            </MantraTypography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" sx={{ marginTop: "3.5em" }}>
        <Grid container spacing={4}>
          {features.map((card) => (
            <Grid item key={card.key} md={4} sm={12}>
              <Card>
                <CardContent>
                  <LeadTypography
                    gutterbottom="true"
                    variant="h5"
                    component="h2"
                  >
                    {card.heading}
                  </LeadTypography>
                  <Typography variant="body1">{card.content}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default LandingPage;
