import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a Fine Motor observation (purpose === 'addNew')
2.) used to view / edit an existing Fione Motor observation (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      backgroundColor: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  languageScreening: null,
  articulation: null,
};

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      width: "5.5em",
      [theme.breakpoints.down("md")]: { width: "5em" },
      [theme.breakpoints.down("xs")]: { width: "4em" },
    },
    root: {
      flexGrow: 1,
    },
  };
});

const SpeechPanel = ({
  speechScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewSpeechPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeSpeechScoreData, setActiveSpeechScoreData] = useState(null);
  const [originalSpeechScoreData, setOriginalSpeechScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalSpeechScoreData({ ...speechScoreSet });
      setActiveSpeechScoreData({ ...speechScoreSet });
    } else {
      setActiveSpeechScoreData({ ...speechScoreSet });
      setIsEditing(true);
    }
  }, [speechScoreSet, purpose]);

  const saveNewSpeechScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeSpeechScoreData, "speechScores");
    handleLoadingClose();
    setShowNewSpeechPanel(false);
  };

  const editSpeechScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalSpeechScoreData, activeSpeechScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the Speechs observation...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeSpeechScoreData, "speechScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteSpeechScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeSpeechScoreData, "speechScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveSpeechScoreData({
      ...activeSpeechScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };

  const handleRadioChange = (e) => {
    setActiveSpeechScoreData({
      ...activeSpeechScoreData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewSpeechScore(activeSpeechScoreData);
    } else {
      editSpeechScore();
    }
  };

  const handleCancel = () => {
    setActiveSpeechScoreData(originalSpeechScoreData);
    setIsEditing(false);
  };

  return activeSpeechScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction='column' alignItems='flex-start' xs={6}>
          {activeSpeechScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeSpeechScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeSpeechScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeSpeechScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeSpeechScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeSpeechScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent='flex-end' xs={6}>
          {isEditing ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeSpeechScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-behscore'
                aria-describedby='alert-dialog-delete-behscore-description'
              >
                <DialogTitle id='alert-dialog-delete-behscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-behscore-description'>
                    You are about to blow away this observation in a forever
                    kind of way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteSpeechScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-behscore'
                aria-describedby='alert-dialog-delete-behscore-description'
              >
                <DialogTitle id='alert-dialog-delete-behscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-behscore-description'>
                    You are about to blow away this observation in a forever
                    kind of way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteSpeechScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction='column' style={{ marginLeft: "40px" }}>
        <Grid item>
          <Typography variant='h6'>Language Screening Performance:</Typography>
        </Grid>
        <Grid item>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='screening-speech'
              name='languageScreening'
              value={activeSpeechScoreData.languageScreening}
              onChange={handleRadioChange}
            >
              <Grid item container>
                <Grid item>
                  <FormControlLabel
                    disabled={!isEditing}
                    value='unsatisfactory'
                    control={<Radio />}
                    label='Unsatisfactory'
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    disabled={!isEditing}
                    value='satisfactory'
                    control={<Radio />}
                    label='Satisfactory'
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography variant='h6'>Articulation</Typography>
        </Grid>
        <Grid item>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='articulation-speech'
              name='articulation'
              value={activeSpeechScoreData.articulation}
              onChange={handleRadioChange}
            >
              <Grid item container>
                <Grid item>
                  <FormControlLabel
                    disabled={!isEditing}
                    value='unsatisfactory'
                    control={<Radio />}
                    label='Unsatisfactory'
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    disabled={!isEditing}
                    value='satisfactory'
                    control={<Radio />}
                    label='Satisfactory'
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default SpeechPanel;
