import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import BehavioralPanel from "./BehavioralPanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { useContext, useEffect } from "react";
import { LearnerContext } from "../../contexts/LearnerContext";

const BehavioralContainer = ({
  behaviorScores,
  learnerId,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { currentLearner } = useContext(LearnerContext);
  const [showNewBehaviorPanel, setShowNewBehaviorPanel] = useState(false);
  useEffect(() => {
    setShowNewBehaviorPanel(false);
  }, [currentLearner]);

  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">Behavioral Observations</Typography>
          </Grid>
          <Grid item>
            {showNewBehaviorPanel ? (
              <CancelButton onClick={() => setShowNewBehaviorPanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton onClick={() => setShowNewBehaviorPanel(true)}>
                Add New Observation
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewBehaviorPanel && (
          <Grid item container direction="column">
            <BehavioralPanel
              purpose={"addNew"}
              learnerId={learnerId}
              setShowNewBehaviorPanel={setShowNewBehaviorPanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {behaviorScores && behaviorScores.length ? (
          _.orderBy(behaviorScores, ["createdAt"], ["desc"]).map(
            (behaviorScoreSet) => (
              <BehavioralPanel
                key={behaviorScoreSet.createdAt}
                learnerId={learnerId}
                behaviorScoreSet={behaviorScoreSet}
                purpose={"view"}
                handleLoadingOpen={handleLoadingOpen}
                handleLoadingClose={handleLoadingClose}
              />
            )
          )
        ) : (
          <Typography>No Behavioral Observations recorded yet...</Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default BehavioralContainer;
