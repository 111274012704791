import React, { useState, useEffect, createContext, useContext } from "react";
import { firestore } from "../firebaseInit";
import { MessageContext } from "../contexts/MessageContext";
import { currentSchoolYear } from "../config/currentSchoolYear";
import { doc, onSnapshot } from "firebase/firestore";

export const SystemSettingsContext = createContext();

export const SystemSettingsProvider = (props) => {
  const { setMessage } = useContext(MessageContext);
  const [systemSettings, setSystemSettings] = useState(null);

  // subscribe to system settings changes
  useEffect(() => {
    const unsubscribe = setSettingsListener();
    return () => {
      unsubscribe();
    };
  }, []);

  // establishes a snapshot listener, which gets changes to the user profile in realtime
  const setSettingsListener = () => {
  return (
    onSnapshot(doc(firestore, "systemSettings", `${currentSchoolYear}`), (doc) => {    
      return setSystemSettings({
        ...doc.data(),
        
      });
    }),
    (error) => {
      // ...
    }
  );  
  };

  return (
    <SystemSettingsContext.Provider
      value={{ systemSettings, setSystemSettings }}
    >
      {props.children}
    </SystemSettingsContext.Provider>
  );
  };
