import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a Social Growth and Work Habit observation (purpose === 'addNew')
2.) used to view / edit an existing Social Growth and Work Habit observation (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  safeBody: "mostOfTheTime",
  readyToLearn: "mostOfTheTime",
  share: "mostOfTheTime",
  listen: "mostOfTheTime",
  goodFriend: "mostOfTheTime",
  myself: "mostOfTheTime",
  thoughts: "mostOfTheTime",
  organized: "mostOfTheTime",
  participates: "mostOfTheTime",
  independant: "mostOfTheTime",
  selfHelp: false,
};

const SGWHPanel = ({
  sgwhScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewSGWHPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeSGWHScoreData, setActiveSGWHScoreData] = useState(null);
  const [originalSGWHScoreData, setOriginalSGWHScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalSGWHScoreData({ ...sgwhScoreSet });
      setActiveSGWHScoreData({ ...sgwhScoreSet });
    } else {
      setActiveSGWHScoreData({ ...sgwhScoreSet });
      setIsEditing(true);
    }
  }, [sgwhScoreSet, purpose]);

  const saveNewSGWHScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeSGWHScoreData, "sgwhScores");
    handleLoadingClose();
    setShowNewSGWHPanel(false);
  };

  const editSGWHScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalSGWHScoreData, activeSGWHScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the SGWH observation...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeSGWHScoreData, "sgwhScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteSGWHScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeSGWHScoreData, "sgwhScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveSGWHScoreData({
      ...activeSGWHScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };

  const handleRadioChange = (e) => {
    setActiveSGWHScoreData({
      ...activeSGWHScoreData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewSGWHScore(activeSGWHScoreData);
    } else {
      editSGWHScore();
    }
  };

  const handleCancel = () => {
    setActiveSGWHScoreData(originalSGWHScoreData);
    setIsEditing(false);
  };
  return activeSGWHScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction="column" alignItems="flex-start" xs={6}>
          {activeSGWHScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeSGWHScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeSGWHScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeSGWHScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeSGWHScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeSGWHScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent="flex-end" xs={6}>
          {isEditing ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeSGWHScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-behscore"
                aria-describedby="alert-dialog-delete-behscore-description"
              >
                <DialogTitle id="alert-dialog-delete-behscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-behscore-description">
                    You are about to blow away this observation in a forever
                    kind of way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteSGWHScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-behscore"
                aria-describedby="alert-dialog-delete-behscore-description"
              >
                <DialogTitle id="alert-dialog-delete-behscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-behscore-description">
                    You are about to blow away this observation in a forever
                    kind of way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteSGWHScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid container>
        <Grid item container align="center" justifyContent="center"></Grid>
      </Grid>
      <Grid container>
        <Grid item>
          {/* <Typography
            variant="h5"
            style={{ marginBottom: "10px", marginLeft: "20px" }}
          >
            Observed Social Growth and Work Habits
          </Typography> */}
        </Grid>
        <Grid item container direction="column" style={{ marginLeft: "40px" }}>
          <Grid item>
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
              Self Help Skills
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <StyledSwitch name="selfHelp" onChange={handleSwitchToggle} />
              }
              style={{ margin: "0px" }}
              label={
                !activeSGWHScoreData.selfHelp
                  ? "Not applicable"
                  : "Working toward independent self help skills"
              }
              checked={activeSGWHScoreData.selfHelp}
              value={activeSGWHScoreData.selfHelp}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Safe body
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.safeBody}
                onChange={handleRadioChange}
                aria-labelledby="safeBody"
                name="safeBody"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Ready to learn
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.readyToLearn}
                onChange={handleRadioChange}
                aria-labelledby="readyToLearn"
                name="readyToLearn"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Share, take turns and wait our turn
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.share}
                onChange={handleRadioChange}
                aria-labelledby="share"
                name="share"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Listen and follow directions{" "}
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.listen}
                onChange={handleRadioChange}
                aria-labelledby="listen"
                name="listen"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Good friend/kind words
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.goodFriend}
                onChange={handleRadioChange}
                aria-labelledby="goodFriend"
                name="goodFriend"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Only worry about myself
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.myself}
                onChange={handleRadioChange}
                aria-labelledby="myself"
                name="myself"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Manages thoughts and feelings
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.thoughts}
                onChange={handleRadioChange}
                aria-labelledby="thoughts"
                name="thoughts"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Organizes and cleans up work and materials
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.organized}
                onChange={handleRadioChange}
                aria-labelledby="organized"
                name="organized"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Participates in learning
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.participates}
                onChange={handleRadioChange}
                aria-labelledby="participates"
                name="participates"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormLabel id="sgwh radio buttons">
              <Typography variant="h6" color="black">
                Completes work independently
              </Typography>
            </FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={activeSGWHScoreData.independant}
                onChange={handleRadioChange}
                aria-labelledby="independant"
                name="independant"
              >
                <FormControlLabel
                  disabled={!isEditing}
                  value="notYet"
                  control={<Radio />}
                  label="Not Yet"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="someOfTheTime"
                  control={<Radio />}
                  label="Some of The Time"
                />
                <FormControlLabel
                  disabled={!isEditing}
                  value="mostOfTheTime"
                  control={<Radio />}
                  label="Most of the Time"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default SGWHPanel;
