import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import KbNavbar from "./components/navbar/KbNavbar";
import UnauthedRoute from "./components/routes/UnauthedRoute";
import MessagingPanel from "./components/MessagingPanel";
import AdminRoute from "./components/routes/AdminRoute";
import TeacherRoute from "./components/routes/TeacherRoute";
import InterventionistRoute from "./components/routes/InterventionistRoute";
import LandingPage from "./components/LandingPage";
import LandingContainer from "./components/LandingContainer";
import AdminLandingPage from "./components/staff/admin/AdminLandingPage";
import TeacherLandingPage from "./components/staff/teacher/TeacherLandingPage";
import WorkingIndicator from "./components/WorkingIndicator";
import InterventionistLandingPage from "./components/staff/interventionist/InterventionistLandingPage";
import LearnerDetailsContainer from "./components/staff/LearnerDetailsContainer";
import LearnerProgressSelector from "./components/staff/LearnerProgressSelector";
import LearnerScreeningSelector from "./components/staff/LearnerScreeningSelector";
import LearnerScreenerReportSelector from "./components/staff/LearnerScreenerReportSelector";

function App() {
  return (
    <>
      <KbNavbar />
      <MessagingPanel />
      <WorkingIndicator />
      <Routes>
        <Route exact path="/" element={<LandingContainer />} />
        <Route
          path="/staff-signin"
          element={
            <UnauthedRoute>
              <LandingPage />
            </UnauthedRoute>
          }
        />
        <Route
          path="/admin-landing"
          element={
            <AdminRoute>
              <AdminLandingPage />
            </AdminRoute>
          }
        />
        <Route
          path="/teacher-landing"
          element={
            <TeacherRoute>
              <TeacherLandingPage />
            </TeacherRoute>
          }
        />
        <Route
          path="/interventionist-landing"
          element={
            <InterventionistRoute>
              <InterventionistLandingPage />
            </InterventionistRoute>
          }
        />
        <Route
          path="/learner-details/:learnerId"
          element={
            <TeacherRoute>
              <LearnerDetailsContainer />
            </TeacherRoute>
          }
        />
        <Route
          exact
          path="/learner-progress-report"
          element={
            <TeacherRoute>
              <LearnerProgressSelector />
            </TeacherRoute>
          }
        />
        <Route
          exact
          path="/learner-screening-selector"
          element={<LearnerScreeningSelector />}
        />
        <Route
          exact
          path="/learner-screening-reports"
          element={<LearnerScreenerReportSelector />}
        />
      </Routes>
    </>
  );
}

export default App;
