import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a new Rhyming Production score (purpose === 'addNew')
2.) used to view / edit an existing Rhyming Production score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: 5,
  wigMissed: false,
  topMissed: false,
  mugMissed: false,
  panMissed: false,
  henMissed: false,
};

const upDateInitialState = {
  score: 0,
  wigMissed: true,
  topMissed: true,
  mugMissed: true,
  panMissed: true,
  henMissed: true,
};

const RhymProdPanel = ({
  rhymProdScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewRhymProdPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeRhymProdScoreData, setActiveRhymProdScoreData] = useState(null);
  const [originalRhymProdScoreData, setOriginalRhymProdScoreData] =
    useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalRhymProdScoreData({ ...rhymProdScoreSet });
      setActiveRhymProdScoreData({ ...rhymProdScoreSet });
    } else {
      setActiveRhymProdScoreData({ ...rhymProdScoreSet });
      setIsEditing(true);
    }
  }, [rhymProdScoreSet, purpose]);

  const saveNewRhymProdScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeRhymProdScoreData, "rhymProdScores");
    handleLoadingClose();
    setShowNewRhymProdPanel(false);
  };

  const editRhymProdScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalRhymProdScoreData, activeRhymProdScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the RhymProd Score...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeRhymProdScoreData, "rhymProdScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteRhymProdScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeRhymProdScoreData, "rhymProdScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveRhymProdScoreData({
      ...activeRhymProdScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeRhymProdScoreData.score - 1
          : activeRhymProdScoreData.score + 1,
    });
  };

  const handleToggle = () => {
    if (activeRhymProdScoreData.score === 5) {
      setActiveRhymProdScoreData(upDateInitialState);
    } else {
      setActiveRhymProdScoreData(addNewInitialState);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewRhymProdScore(activeRhymProdScoreData);
    } else {
      editRhymProdScore();
    }
  };

  const handleCancel = () => {
    setActiveRhymProdScoreData(originalRhymProdScoreData);
    setIsEditing(false);
  };

  return activeRhymProdScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction='column' alignItems='flex-start' xs={6}>
          <Grid item>
            <Typography>Score: {activeRhymProdScoreData.score}</Typography>
          </Grid>
          {activeRhymProdScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeRhymProdScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeRhymProdScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeRhymProdScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(
                  activeRhymProdScoreData.lastEdited
                ).toLocaleDateString("en-US")}{" "}
                by: {activeRhymProdScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent='flex-end' xs={6}>
          {isEditing ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeRhymProdScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeRhymProdScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-rhymprodscore'
                aria-describedby='alert-dialog-delete-rhymprodscore-description'
              >
                <DialogTitle id='alert-dialog-delete-rhymprodscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-rhymprodscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteRhymProdScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-rhymprodscore'
                aria-describedby='alert-dialog-delete-rhymprodscore-description'
              >
                <DialogTitle id='alert-dialog-delete-rhymprodscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-rhymprodscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteRhymProdScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction='column'>
        <Grid item container>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='wigMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='wig'
                labelPlacement='bottom'
                checked={activeRhymProdScoreData.wigMissed}
                value={activeRhymProdScoreData.wigMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='topMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='top'
                labelPlacement='bottom'
                checked={activeRhymProdScoreData.topMissed}
                value={activeRhymProdScoreData.topMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='mugMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='mug'
                labelPlacement='bottom'
                checked={activeRhymProdScoreData.mugMissed}
                value={activeRhymProdScoreData.mugMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='panMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='pan'
                labelPlacement='bottom'
                checked={activeRhymProdScoreData.panMissed}
                value={activeRhymProdScoreData.panMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='henMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='hen'
                labelPlacement='bottom'
                checked={activeRhymProdScoreData.henMissed}
                value={activeRhymProdScoreData.henMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default RhymProdPanel;
