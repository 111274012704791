import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a Counting Score (purpose === 'addNew')
2.) used to view / edit an existing Counting Score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: "",
  oneToOne: true,
};

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      width: "5.5em",
      [theme.breakpoints.down("md")]: { width: "5em" },
      [theme.breakpoints.down("xs")]: { width: "4em" },
    },
    root: {
      flexGrow: 1,
    },
  };
});

const CountingScorePanel = ({
  countingScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewCountingScorePanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [countScoreInError, setCountScoreInError] = useState(false);
  const [countScoreHelperText, setCountScoreHelperText] = useState("");
  const [activeCountingScoreData, setActiveCountingScoreData] = useState(null);
  const [originalCountingScoreData, setOriginalCountingScoreData] =
    useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalCountingScoreData({ ...countingScoreSet });
      setActiveCountingScoreData({ ...countingScoreSet });
    } else {
      setActiveCountingScoreData({ ...countingScoreSet });
      setIsEditing(true);
    }
  }, [countingScoreSet, purpose]);

  const saveNewCountingScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeCountingScoreData, "countingScores");
    handleLoadingClose();
    setShowNewCountingScorePanel(false);
  };

  const editCountingScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalCountingScoreData, activeCountingScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the current counting score...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeCountingScoreData, "countingScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteCountingScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeCountingScoreData, "countingScores");
    handleLoadingClose();
  };

  const handleTextChange = (e) => {
    // check for a valid number
    const regex = RegExp("^(0|[1-9][0-9]*)$");
    if (!regex.test(e.target.value)) {
      setCountScoreInError(true);
      setCountScoreHelperText("Only valid numbers please.");
    } else {
      setCountScoreInError(false);
      setCountScoreHelperText("");
    }
    setActiveCountingScoreData({
      ...activeCountingScoreData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitchToggle = (e) => {
    setActiveCountingScoreData({
      ...activeCountingScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      if (countScoreInError) {
        setCountScoreInError(true);
        setCountScoreHelperText("Only valid numbers please.");
      } else {
        setCountScoreInError(false);
        setCountScoreHelperText("");
        saveNewCountingScore(activeCountingScoreData);
      }
    } else {
      editCountingScore();
    }
  };

  const handleCancel = () => {
    setActiveCountingScoreData(originalCountingScoreData);
    setIsEditing(false);
  };
  return activeCountingScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction="column" alignItems="flex-start" xs={6}>
          {activeCountingScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeCountingScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeCountingScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeCountingScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(
                  activeCountingScoreData.lastEdited
                ).toLocaleDateString("en-US")}{" "}
                by: {activeCountingScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent="flex-end" xs={6}>
          {isEditing ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeCountingScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-counting-score"
                aria-describedby="alert-dialog-delete-counting-score"
              >
                <DialogTitle id="alert-dialog-delete-counting-score">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-counting-score">
                    You are about to blow away this counting score in a forever
                    kind of way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteCountingScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-counting-score"
                aria-describedby="alert-dialog-delete-counting-score"
              >
                <DialogTitle id="alert-dialog-delete-counting-score">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-counting-score">
                    You are about to blow away this counting score in a forever
                    kind of way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteCountingScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid container>
        <Grid item xs={8}>
          <TextField
            error={countScoreInError}
            disabled={!isEditing}
            helperText={countScoreHelperText}
            variant="outlined"
            margin="normal"
            label="Counting Score"
            name="score"
            value={activeCountingScoreData.score}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography>One to One Matching</Typography>
          <FormControlLabel
            control={
              <StyledSwitch name="oneToOne" onChange={handleSwitchToggle} />
            }
            style={{ margin: "0px" }}
            label={activeCountingScoreData.oneToOne ? "Yes" : "No"}
            checked={!activeCountingScoreData.oneToOne}
            value={activeCountingScoreData.oneToOne}
            disabled={!isEditing}
          />
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default CountingScorePanel;
