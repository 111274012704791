import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import LNLScorePanel from "./LNLScorePanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { LearnerContext } from "../../contexts/LearnerContext";
import { useContext, useEffect } from "react";

const LNLScoresContainer = ({
  lnlScores,
  learnerId,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const [showNewLNLPanel, setShowNewLNLPanel] = useState(false);
  const { currentLearner } = useContext(LearnerContext);
  useEffect(() => {
    setShowNewLNLPanel(false);
  }, [currentLearner]);
  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">
              Lowercase Letter Name Identification
            </Typography>
          </Grid>
          <Grid item>
            {showNewLNLPanel ? (
              <CancelButton onClick={() => setShowNewLNLPanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton onClick={() => setShowNewLNLPanel(true)}>
                Add New Score
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewLNLPanel && (
          <Grid item container direction="column">
            <LNLScorePanel
              purpose={"addNew"}
              learnerId={learnerId}
              setShowNewLNLPanel={setShowNewLNLPanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {lnlScores && lnlScores.length ? (
          _.orderBy(lnlScores, ["createdAt"], ["desc"]).map((lnlScoreSet) => (
            <LNLScorePanel
              key={lnlScoreSet.createdAt}
              learnerId={learnerId}
              lnlScoreSet={lnlScoreSet}
              purpose={"view"}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          ))
        ) : (
          <Typography>No LNL Scores recorded yet...</Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default LNLScoresContainer;
