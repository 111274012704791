import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a general comment (purpose === 'addNew')
2.) used to view / edit an existing general comment (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
  },
  checked: {},
  track: {},
})(Switch);

const addNewInitialState = {
  generalComment: "",
};

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      width: "5.5em",
      [theme.breakpoints.down("md")]: { width: "5em" },
      [theme.breakpoints.down("xs")]: { width: "4em" },
    },
    root: {
      flexGrow: 1,
    },
  };
});

const CommPanel = ({
  commScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewCommPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeCommScoreData, setActiveCommScoreData] = useState(null);
  const [originalCommScoreData, setOriginalCommScoreData] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (purpose === "view") {
      setOriginalCommScoreData({ ...commScoreSet });
      setActiveCommScoreData({ ...commScoreSet });
    } else {
      setActiveCommScoreData({ ...commScoreSet });
      setIsEditing(true);
    }
  }, [commScoreSet, purpose]);

  const saveNewCommScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeCommScoreData, "commScores");
    handleLoadingClose();
    setShowNewCommPanel(false);
  };

  const editCommScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalCommScoreData, activeCommScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the current comment...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeCommScoreData, "commScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteCommScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeCommScoreData, "commScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveCommScoreData({
      ...activeCommScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };

  const handleRadioChange = (e) => {
    setActiveCommScoreData({
      ...activeCommScoreData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTextChange = (e) => {
    setActiveCommScoreData({
      ...activeCommScoreData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewCommScore(activeCommScoreData);
    } else {
      editCommScore();
    }
  };

  const handleCancel = () => {
    setActiveCommScoreData(originalCommScoreData);
    setIsEditing(false);
  };

  return activeCommScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction='column' alignItems='flex-start' xs={6}>
          {activeCommScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeCommScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeCommScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeCommScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeCommScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeCommScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent='flex-end' xs={6}>
          {isEditing ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeCommScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-comment'
                aria-describedby='alert-dialog-delete-comment'
              >
                <DialogTitle id='alert-dialog-delete-comment'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-comment'>
                    You are about to blow away this comment in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteCommScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-comment'
                aria-describedby='alert-dialog-delete-comment'
              >
                <DialogTitle id='alert-dialog-delete-comment'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-comment'>
                    You are about to blow away this comment in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteCommScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid container>
        <Grid item xs={8}>
          <TextField
            disabled={!isEditing}
            id='general-comment'
            margin='normal'
            fullWidth
            placeholder='Provide a general comment here...'
            label='General Comment'
            name='generalComment'
            value={activeCommScoreData.generalComment}
            onChange={handleTextChange}
            multiline
            minRows={4}
          />
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default CommPanel;
