import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import dashTrace from "../../assets/dashTrace.png";
import crossTrace from "../../assets/crossTrace.png";
import circleTrace from "../../assets/circleTrace.png";
import triangleTrace from "../../assets/triangleTrace.png";
import rectTrace from "../../assets/rectTrace.png";
import { currentSchoolYear } from "../../config/currentSchoolYear";
import { LearnerContext } from "../../contexts/LearnerContext";

/*
This component has two potential uses:
1.) used to display a panel to create a Fine Motor observation (purpose === 'addNew')
2.) used to view / edit an existing Fione Motor observation (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  fmIsUnsatisfactory: false,
  dashIsMissed: false,
  crossIsMissed: false,
  circleIsMissed: false,
  triangleIsMissed: false,
  rectIsMissed: false,
  fmWritingName: null,
  fmCutHold: null,
  fmCutLine: null,
  fmPenGrasp: null,
  fmDominantHand: "Not Evaluated",
};

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      width: "5.5em",
      [theme.breakpoints.down("md")]: { width: "5em" },
      [theme.breakpoints.down("xs")]: { width: "4em" },
    },
    root: {
      flexGrow: 1,
    },
  };
});

const FineMotorPanel = ({
  isScreener = isScreener,
  fmScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewFMPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeFMScoreData, setActiveFMScoreData] = useState(null);
  const [originalFMScoreData, setOriginalFMScoreData] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (purpose === "view") {
      setOriginalFMScoreData({ ...fmScoreSet });
      setActiveFMScoreData({ ...fmScoreSet });
    } else {
      setActiveFMScoreData({ ...fmScoreSet });
      setIsEditing(true);
    }
  }, [fmScoreSet, purpose]);

  const saveNewFMScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeFMScoreData, "fmScores");
    handleLoadingClose();
    setShowNewFMPanel(false);
  };

  const editFMScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalFMScoreData, activeFMScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the Fine Motor observation...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeFMScoreData, "fmScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteFMScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeFMScoreData, "fmScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveFMScoreData({
      ...activeFMScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };

  const handleRadioChange = (e) => {
    setActiveFMScoreData({
      ...activeFMScoreData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewFMScore(activeFMScoreData);
    } else {
      editFMScore();
    }
  };

  const handleCancel = () => {
    setActiveFMScoreData(originalFMScoreData);
    setIsEditing(false);
  };

  return activeFMScoreData ? (
    <Fragment>
      {/* container for score block header */}
      {isScreener && isScreener === "screener" ? (
        <Container>
          <Grid item container>
            {/* container for score and date info */}
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              xs={6}
            >
              {activeFMScoreData.createdAt && (
                <Grid item>
                  <Typography>
                    Created:
                    {new Date(activeFMScoreData.createdAt).toLocaleDateString(
                      "en-US"
                    )}{" "}
                    by: {activeFMScoreData.recordedByName}
                  </Typography>
                </Grid>
              )}
              {activeFMScoreData.lastEdited && (
                <Grid item>
                  <Typography>
                    Last Edited:
                    {new Date(activeFMScoreData.lastEdited).toLocaleDateString(
                      "en-US"
                    )}{" "}
                    by: {activeFMScoreData.lastEditedName}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {/* container for buttons */}
            <Grid item container justifyContent="flex-end" xs={6}>
              {isEditing ? (
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <SaveButton onClick={handleSave}>Save</SaveButton>
                  </Grid>
                  {purpose === "view" && (
                    <Grid item style={{ marginLeft: "10px" }}>
                      <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                    </Grid>
                  )}
                </Grid>
              ) : activeFMScoreData.schoolYear === currentSchoolYear ? (
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <EditButton onClick={handleEdit}>Edit</EditButton>
                  </Grid>
                  <Grid item>
                    <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                      Delete
                    </DeleteButton>
                  </Grid>
                  <Dialog
                    open={deleteAlertOpen}
                    onClose={() => setDeleteAlertOpen(false)}
                    aria-labelledby="alert-dialog-delete-fmscore"
                    aria-describedby="alert-dialog-delete-fmscore-description"
                  >
                    <DialogTitle id="alert-dialog-delete-fmscore">
                      {"Friendly Warning..."}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-delete-fmscore-description">
                        You are about to blow away this observation in a forever
                        kind of way. Take a deep breath, are you sure about
                        this?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        style={{ backgroundColor: "green" }}
                        onClick={() => setDeleteAlertOpen(false)}
                        color="primary"
                      >
                        Whoops!
                      </Button>
                      <Button
                        style={{ backgroundColor: "red" }}
                        onClick={() => {
                          deleteFMScore();
                          setDeleteAlertOpen(false);
                        }}
                        color="primary"
                        autoFocus
                      >
                        Blow It Away!
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              ) : (
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                      Delete
                    </DeleteButton>
                  </Grid>
                  <Dialog
                    open={deleteAlertOpen}
                    onClose={() => setDeleteAlertOpen(false)}
                    aria-labelledby="alert-dialog-delete-fmscore"
                    aria-describedby="alert-dialog-delete-fmscore-description"
                  >
                    <DialogTitle id="alert-dialog-delete-fmscore">
                      {"Friendly Warning..."}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-delete-fmscore-description">
                        You are about to blow away this observation in a forever
                        kind of way. Take a deep breath, are you sure about
                        this?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        style={{ backgroundColor: "green" }}
                        onClick={() => setDeleteAlertOpen(false)}
                        color="primary"
                      >
                        Whoops!
                      </Button>
                      <Button
                        style={{ backgroundColor: "red" }}
                        onClick={() => {
                          deleteFMScore();
                          setDeleteAlertOpen(false);
                        }}
                        color="primary"
                        autoFocus
                      >
                        Blow It Away!
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* container for granular metric components */}
          <Grid container>
            <Grid item>
              <Typography
                variant="h5"
                style={{ marginBottom: "10px", marginLeft: "20px" }}
              >
                Shapes
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={3} justifyContent="center">
              <Grid item container direction="column" align="center" xs={2}>
                <Grid item>
                  <img
                    alt="drawn dash"
                    className={classes.logo}
                    src={dashTrace}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="dashIsMissed"
                        onChange={handleSwitchToggle}
                      />
                    }
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                    checked={activeFMScoreData.dashIsMissed}
                    value={activeFMScoreData.dashIsMissed}
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" align="center" xs={2}>
                <Grid item>
                  <img
                    alt="drawn cross"
                    className={classes.logo}
                    src={crossTrace}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="crossIsMissed"
                        onChange={handleSwitchToggle}
                      />
                    }
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                    checked={activeFMScoreData.crossIsMissed}
                    value={activeFMScoreData.crossIsMissed}
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" align="center" xs={2}>
                <Grid item>
                  <img
                    alt="drawn circle"
                    className={classes.logo}
                    src={circleTrace}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="circleIsMissed"
                        onChange={handleSwitchToggle}
                      />
                    }
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                    checked={activeFMScoreData.circleIsMissed}
                    value={activeFMScoreData.circleIsMissed}
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" align="center" xs={2}>
                <Grid item>
                  <img
                    alt="drawn triangle"
                    className={classes.logo}
                    src={triangleTrace}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="triangleIsMissed"
                        onChange={handleSwitchToggle}
                      />
                    }
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                    checked={activeFMScoreData.triangleIsMissed}
                    value={activeFMScoreData.triangleIsMissed}
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" align="center" xs={2}>
                <Grid item>
                  <img
                    alt="drawn rectangle"
                    className={classes.logo}
                    src={rectTrace}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="rectIsMissed"
                        onChange={handleSwitchToggle}
                      />
                    }
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                    checked={activeFMScoreData.rectIsMissed}
                    value={activeFMScoreData.rectIsMissed}
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography
                variant="h5"
                style={{ marginBottom: "10px", marginLeft: "20px" }}
              >
                Writing and Manual Dexterity
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              style={{ marginLeft: "40px" }}
            >
              <Grid item>
                <Typography variant="h6">Ability to Write Name</Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="name-writing-ability"
                    name="fmWritingName"
                    value={activeFMScoreData.fmWritingName}
                    onChange={handleRadioChange}
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          value="noLetters"
                          disabled={!isEditing}
                          control={<Radio />}
                          label="No letters"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="atLeast2Letters"
                          disabled={!isEditing}
                          control={<Radio />}
                          label="At Least 2 Letters"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="allLetters"
                          disabled={!isEditing}
                          control={<Radio />}
                          label="All Letters"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography variant="h6">Cutting Ability</Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="scissors-cutting-hold"
                    name="fmCutHold"
                    value={activeFMScoreData.fmCutHold}
                    onChange={handleRadioChange}
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="awkward"
                          control={<Radio />}
                          label="Holds Scissors Awkwardly"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="correct"
                          control={<Radio />}
                          label="Holds Scissors Correctly"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="scissors-cutting-line"
                    name="fmCutLine"
                    value={activeFMScoreData.fmCutLine}
                    onChange={handleRadioChange}
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="unable"
                          control={<Radio />}
                          label="Unable to Cut Along Line"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="able"
                          control={<Radio />}
                          label="Cuts Along Line"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography variant="h6">Pen Grasp</Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="pen-grasp"
                    name="fmPenGrasp"
                    value={activeFMScoreData.fmPenGrasp}
                    onChange={handleRadioChange}
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="awkward"
                          control={<Radio />}
                          label="Holds Pen Awkwardly"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="correct"
                          control={<Radio />}
                          label="Holds Pen Correctly"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography variant="h6">Dominant Hand</Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="dominant-hand"
                    name="fmDominantHand"
                    value={activeFMScoreData.fmDominantHand}
                    onChange={handleRadioChange}
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="left"
                          control={<Radio />}
                          label="Left-Handed"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="right"
                          control={<Radio />}
                          label="Right-Handed"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="unknown"
                          control={<Radio />}
                          label="Unable to Determine"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        //
        //
        //
        //
        //
        //
        //
        //
        <Container>
          <Grid item container>
            {/* container for score and date info */}
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              xs={6}
            >
              {activeFMScoreData.createdAt && (
                <Grid item>
                  <Typography>
                    Created:
                    {new Date(activeFMScoreData.createdAt).toLocaleDateString(
                      "en-US"
                    )}{" "}
                    by: {activeFMScoreData.recordedByName}
                  </Typography>
                </Grid>
              )}
              {activeFMScoreData.lastEdited && (
                <Grid item>
                  <Typography>
                    Last Edited:
                    {new Date(activeFMScoreData.lastEdited).toLocaleDateString(
                      "en-US"
                    )}{" "}
                    by: {activeFMScoreData.lastEditedName}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {/* container for buttons */}
            <Grid item container justifyContent="flex-end" xs={6}>
              {isEditing ? (
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <SaveButton onClick={handleSave}>Save</SaveButton>
                  </Grid>
                  {purpose === "view" && (
                    <Grid item style={{ marginLeft: "10px" }}>
                      <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                    </Grid>
                  )}
                </Grid>
              ) : activeFMScoreData.schoolYear === currentSchoolYear ? (
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <EditButton onClick={handleEdit}>Edit</EditButton>
                  </Grid>
                  <Grid item>
                    <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                      Delete
                    </DeleteButton>
                  </Grid>
                  <Dialog
                    open={deleteAlertOpen}
                    onClose={() => setDeleteAlertOpen(false)}
                    aria-labelledby="alert-dialog-delete-fmscore"
                    aria-describedby="alert-dialog-delete-fmscore-description"
                  >
                    <DialogTitle id="alert-dialog-delete-fmscore">
                      {"Friendly Warning..."}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-delete-fmscore-description">
                        You are about to blow away this observation in a forever
                        kind of way. Take a deep breath, are you sure about
                        this?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        style={{ backgroundColor: "green" }}
                        onClick={() => setDeleteAlertOpen(false)}
                        color="primary"
                      >
                        Whoops!
                      </Button>
                      <Button
                        style={{ backgroundColor: "red" }}
                        onClick={() => {
                          deleteFMScore();
                          setDeleteAlertOpen(false);
                        }}
                        color="primary"
                        autoFocus
                      >
                        Blow It Away!
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              ) : (
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                      Delete
                    </DeleteButton>
                  </Grid>
                  <Dialog
                    open={deleteAlertOpen}
                    onClose={() => setDeleteAlertOpen(false)}
                    aria-labelledby="alert-dialog-delete-fmscore"
                    aria-describedby="alert-dialog-delete-fmscore-description"
                  >
                    <DialogTitle id="alert-dialog-delete-fmscore">
                      {"Friendly Warning..."}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-delete-fmscore-description">
                        You are about to blow away this observation in a forever
                        kind of way. Take a deep breath, are you sure about
                        this?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        style={{ backgroundColor: "green" }}
                        onClick={() => setDeleteAlertOpen(false)}
                        color="primary"
                      >
                        Whoops!
                      </Button>
                      <Button
                        style={{ backgroundColor: "red" }}
                        onClick={() => {
                          deleteFMScore();
                          setDeleteAlertOpen(false);
                        }}
                        color="primary"
                        autoFocus
                      >
                        Blow It Away!
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* container for granular metric components */}

          <Grid container>
            <Grid
              item
              container
              direction="column"
              style={{ marginLeft: "40px" }}
            >
              <Grid item>
                <Typography variant="h6">Ability to Write Name</Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="name-writing-ability"
                    name="fmWritingName"
                    value={activeFMScoreData.fmWritingName}
                    onChange={handleRadioChange}
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          value="noLetters"
                          disabled={!isEditing}
                          control={<Radio />}
                          label="No letters"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="atLeast2Letters"
                          disabled={!isEditing}
                          control={<Radio />}
                          label="At Least 2 Letters"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="allLetters"
                          disabled={!isEditing}
                          control={<Radio />}
                          label="All Letters"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography variant="h6">Cutting Ability</Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="scissors-cutting-hold"
                    name="fmCutHold"
                    value={activeFMScoreData.fmCutHold}
                    onChange={handleRadioChange}
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="awkward"
                          control={<Radio />}
                          label="Holds Scissors Awkwardly"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="correct"
                          control={<Radio />}
                          label="Holds Scissors Correctly"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item>
                <Typography variant="h6">Pen Grasp</Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="pen-grasp"
                    name="fmPenGrasp"
                    value={activeFMScoreData.fmPenGrasp}
                    onChange={handleRadioChange}
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="awkward"
                          control={<Radio />}
                          label="Holds Pen Awkwardly"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          disabled={!isEditing}
                          value="correct"
                          control={<Radio />}
                          label="Holds Pen Correctly"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default FineMotorPanel;
