import React, { useState } from "react";
import {
  Button,
  Grid,
  Avatar,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import GoogleIcon from "@mui/icons-material/Google";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";

const NavButtonBar = () => {
  const { googleSignIn, googleSignOut, currentUser } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleAvatarClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid item>
      <Grid item container direction="row" alignItems="center" spacing={1}>
        {currentUser && (currentUser.roster || currentUser.learnerDirectory || currentUser.role === "interventionist") ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/");
                }}
                startIcon={<HomeIcon />}
              >
                Dashboard
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/learner-progress-report");
                }}
                startIcon={<Inventory2Icon />}
              >
                Progress Reports
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/learner-screening-selector");
                }}
                startIcon={<Inventory2Icon />}
              >
                Learner Screening
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/learner-screening-reports");
                }}
                startIcon={<Inventory2Icon />}
              >
                Screening Reports
              </Button>
            </Grid>
          </>
        ) : (
          <></>
        )}
        {currentUser ? (
          <Grid item sx={{ marginLeft: "5px" }}>
            <Grid item lg={1} md={2} xs={3}>
              <Tooltip title="Account Menu">
                <IconButton
                  onClick={handleAvatarClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    alt="Avatar"
                    src={currentUser.photoURL}
                    sx={{ width: 60, height: 60 }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<GoogleIcon fontSize="large" />}
              onClick={async () => {
                await googleSignIn();
                navigate("/");
              }}
            >
              Sign In
            </Button>
          </Grid>
        )}
      </Grid>

      {/* User avatar menu */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleAvatarClose}
        onClick={handleAvatarClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={async () => {
            await googleSignOut();
            navigate("/");
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default NavButtonBar;
