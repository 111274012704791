import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import NumIdScorePanel from "./NumIdScorePanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { LearnerContext } from "../../contexts/LearnerContext";
import { useContext, useEffect } from "react";

const NumIdScoreContainer = ({
  numIdScores,
  learnerId,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const [showNewNumIdPanel, setShowNewNumIdPanel] = useState(false);
  const { currentLearner } = useContext(LearnerContext);
  useEffect(() => {
    setShowNewNumIdPanel(false);
  }, [currentLearner]);
  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">Number Identification</Typography>
          </Grid>
          <Grid item>
            {showNewNumIdPanel ? (
              <CancelButton onClick={() => setShowNewNumIdPanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton onClick={() => setShowNewNumIdPanel(true)}>
                Add New Score
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewNumIdPanel && (
          <Grid item container direction="column">
            <NumIdScorePanel
              purpose={"addNew"}
              learnerId={learnerId}
              setShowNewNumIdPanel={setShowNewNumIdPanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {numIdScores && numIdScores.length ? (
          _.orderBy(numIdScores, ["createdAt"], ["desc"]).map(
            (numIdScoreSet) => (
              <NumIdScorePanel
                key={numIdScoreSet.createdAt}
                learnerId={learnerId}
                numIdScoreSet={numIdScoreSet}
                purpose={"view"}
                handleLoadingOpen={handleLoadingOpen}
                handleLoadingClose={handleLoadingClose}
              />
            )
          )
        ) : (
          <Typography>
            No Number Identification Scores recorded yet...
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default NumIdScoreContainer;
