import React, { Fragment, useState, useContext, useEffect } from "react";
import { Container, Typography, Grid, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import { useAuth } from "../../contexts/AuthContext";
import LearnerDetailsPage from "./LearnerDetailsPage";
import { LearnerContext } from "../../contexts/LearnerContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import { LoadingContext } from "../../contexts/LoadingContext";

const LearnerScreeningSelector = () => {
  const { currentUser } = useAuth();
  const { currentLearner, setCurrentLearnerId } = useContext(LearnerContext);
  const [selectedLearner, setSelectedLearner] = useState(null);
  const { setIsLoading } = useContext(LoadingContext);
  const [learnerList, setLearnerList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { getScreeners } = useContext(TransactionContext);

  const fetchScreeners = async () => {
    setIsLoading(true);
    const screeners = await getScreeners();
    setLearnerList(screeners);
    const listing = screeners.map((learner) => {
      return {
        learnerName: `${learner.lastName}, ${learner.firstName}`,
        learnerId: learner.learnerId,
      };
    });
    setIsLoading(false);
    setLearnerList(_.orderBy(listing, ["learnerName"], ["asc"]));
  };
  useEffect(() => {
    setIsLoading(false);
  }, [currentLearner]);
  useEffect(() => {
    setCurrentLearnerId(null);
    fetchScreeners();
  }, []);

  const handleLearnerSelect = (event, newLearner) => {
    if (newLearner) {
      setIsLoading(true);
      setCurrentLearnerId(newLearner.learnerId);
      setSelectedLearner(newLearner);
    } else setSelectedLearner(null);
  };

  return (
    <Container>
      <Grid item container direction="column">
        <Grid item style={{ marginTop: "65px" }}>
          <Typography variant="h2">Learner Screening</Typography>
        </Grid>
        {currentUser && learnerList && learnerList.length ? (
          <Fragment>
            <Grid item style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Autocomplete
                id="learners"
                value={selectedLearner}
                onChange={handleLearnerSelect}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={learnerList}
                getOptionLabel={(option) => option.learnerName}
                style={{ width: 400 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Learner to Screen"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {currentLearner && (
              <Grid item container>
                <LearnerDetailsPage currentLearner={currentLearner} />
              </Grid>
            )}
          </Fragment>
        ) : (
          <Typography component="p">
            At this time there are no Learners to screen for Kindergarten next
            year.
          </Typography>
        )}
      </Grid>
    </Container>
  );
};

export default LearnerScreeningSelector;
