import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

// const firebaseApp = initializeApp({
//   apiKey: "AIzaSyCcRNBDoKBp71LqzDZNCTd0R-FaRN4Smvk",
//   authDomain: "kinderbase-dev.firebaseapp.com",
//   databaseURL: "https://kinderbase-dev.firebaseio.com",
//   projectId: "kinderbase-dev",
//   storageBucket: "kinderbase-dev.appspot.com",
//   messagingSenderId: "682080471976",
//   appId: "1:682080471976:web:7e5ca52390848e2c0d2b9d",
// });

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
