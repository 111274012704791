import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a new LSI score (purpose === 'addNew')
2.) used to view / edit an existing LSI score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: 26,
  dIsMissed: false,
  aIsMissed: false,
  tIsMissed: false,
  mIsMissed: false,
  cIsMissed: false,
  xIsMissed: false,
  zIsMissed: false,
  bIsMissed: false,
  lIsMissed: false,
  pIsMissed: false,
  vIsMissed: false,
  sIsMissed: false,
  kIsMissed: false,
  fIsMissed: false,
  jIsMissed: false,
  nIsMissed: false,
  gIsMissed: false,
  iIsMissed: false,
  oIsMissed: false,
  qIsMissed: false,
  hIsMissed: false,
  rIsMissed: false,
  uIsMissed: false,
  wIsMissed: false,
  eIsMissed: false,
  yIsMissed: false,
};

const upDateInitialState = {
  score: 0,
  aIsMissed: true,
  bIsMissed: true,
  cIsMissed: true,
  dIsMissed: true,
  eIsMissed: true,
  fIsMissed: true,
  gIsMissed: true,
  hIsMissed: true,
  iIsMissed: true,
  jIsMissed: true,
  kIsMissed: true,
  lIsMissed: true,
  mIsMissed: true,
  nIsMissed: true,
  oIsMissed: true,
  pIsMissed: true,
  qIsMissed: true,
  rIsMissed: true,
  sIsMissed: true,
  tIsMissed: true,
  uIsMissed: true,
  vIsMissed: true,
  wIsMissed: true,
  xIsMissed: true,
  yIsMissed: true,
  zIsMissed: true,
};

const LSIScorePanel = ({
  lsiScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewLSIPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeLSIScoreData, setActiveLSIScoreData] = useState(null);
  const [originalLSIScoreData, setOriginalLSIScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalLSIScoreData({ ...lsiScoreSet });
      setActiveLSIScoreData({ ...lsiScoreSet });
    } else {
      setActiveLSIScoreData({ ...lsiScoreSet });
      setIsEditing(true);
    }
  }, [lsiScoreSet, purpose]);

  const saveNewLSIScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeLSIScoreData, "lsiScores");
    handleLoadingClose();
    setShowNewLSIPanel(false);
  };

  const editLSIScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalLSIScoreData, activeLSIScoreData)) {
      setMessage("warning", `No changes were made to the LSI Score...`, 3000);
    } else {
      handleLoadingOpen();
      await editScore(activeLSIScoreData, "lsiScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteLSIScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeLSIScoreData, "lsiScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveLSIScoreData({
      ...activeLSIScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeLSIScoreData.score - 1
          : activeLSIScoreData.score + 1,
    });
  };

  const handleToggle = () => {
    if (activeLSIScoreData.score === 26) {
      setActiveLSIScoreData(upDateInitialState);
    } else {
      setActiveLSIScoreData(addNewInitialState);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewLSIScore(activeLSIScoreData);
    } else {
      editLSIScore();
    }
  };

  const handleCancel = () => {
    setActiveLSIScoreData(originalLSIScoreData);
    setIsEditing(false);
  };

  return activeLSIScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction="column" alignItems="flex-start" xs={6}>
          <Grid item>
            <Typography>Score: {activeLSIScoreData.score}</Typography>
          </Grid>
          {activeLSIScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeLSIScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeLSIScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeLSIScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeLSIScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeLSIScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent="flex-end" xs={6}>
          {isEditing ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeLSIScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeLSIScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-lsiscore"
                aria-describedby="alert-dialog-delete-lsiscore-description"
              >
                <DialogTitle id="alert-dialog-delete-lsiscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-lsiscore-description">
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteLSIScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-lsiscore"
                aria-describedby="alert-dialog-delete-lsiscore-description"
              >
                <DialogTitle id="alert-dialog-delete-lsiscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-lsiscore-description">
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteLSIScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction="column">
        <Grid item container>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="dIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="D"
                labelPlacement="bottom"
                checked={activeLSIScoreData.dIsMissed}
                value={activeLSIScoreData.dIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="aIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="A"
                labelPlacement="bottom"
                checked={activeLSIScoreData.aIsMissed}
                value={activeLSIScoreData.aIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="tIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="T"
                labelPlacement="bottom"
                checked={activeLSIScoreData.tIsMissed}
                value={activeLSIScoreData.tIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="mIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="M"
                labelPlacement="bottom"
                checked={activeLSIScoreData.mIsMissed}
                value={activeLSIScoreData.mIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="cIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="C"
                labelPlacement="bottom"
                checked={activeLSIScoreData.cIsMissed}
                value={activeLSIScoreData.cIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="xIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="X"
                labelPlacement="bottom"
                checked={activeLSIScoreData.xIsMissed}
                value={activeLSIScoreData.xIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="zIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="Z"
                labelPlacement="bottom"
                checked={activeLSIScoreData.zIsMissed}
                value={activeLSIScoreData.zIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="bIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="B"
                labelPlacement="bottom"
                checked={activeLSIScoreData.bIsMissed}
                value={activeLSIScoreData.bIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="lIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="L"
                labelPlacement="bottom"
                checked={activeLSIScoreData.lIsMissed}
                value={activeLSIScoreData.lIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="pIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="P"
                labelPlacement="bottom"
                checked={activeLSIScoreData.pIsMissed}
                value={activeLSIScoreData.pIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="vIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="V"
                labelPlacement="bottom"
                checked={activeLSIScoreData.vIsMissed}
                value={activeLSIScoreData.vIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="sIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="S"
                labelPlacement="bottom"
                checked={activeLSIScoreData.sIsMissed}
                value={activeLSIScoreData.sIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="kIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="K"
                labelPlacement="bottom"
                checked={activeLSIScoreData.kIsMissed}
                value={activeLSIScoreData.kIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="fIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="F"
                labelPlacement="bottom"
                checked={activeLSIScoreData.fIsMissed}
                value={activeLSIScoreData.fIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="jIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="J"
                labelPlacement="bottom"
                checked={activeLSIScoreData.jIsMissed}
                value={activeLSIScoreData.jIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="nIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="N"
                labelPlacement="bottom"
                checked={activeLSIScoreData.nIsMissed}
                value={activeLSIScoreData.nIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="gIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="G"
                labelPlacement="bottom"
                checked={activeLSIScoreData.gIsMissed}
                value={activeLSIScoreData.gIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="iIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="I"
                labelPlacement="bottom"
                checked={activeLSIScoreData.iIsMissed}
                value={activeLSIScoreData.iIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="oIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="O"
                labelPlacement="bottom"
                checked={activeLSIScoreData.oIsMissed}
                value={activeLSIScoreData.oIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="qIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="Q"
                labelPlacement="bottom"
                checked={activeLSIScoreData.qIsMissed}
                value={activeLSIScoreData.qIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="hIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="H"
                labelPlacement="bottom"
                checked={activeLSIScoreData.hIsMissed}
                value={activeLSIScoreData.hIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="rIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="R"
                labelPlacement="bottom"
                checked={activeLSIScoreData.rIsMissed}
                value={activeLSIScoreData.rIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="uIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="U"
                labelPlacement="bottom"
                checked={activeLSIScoreData.uIsMissed}
                value={activeLSIScoreData.uIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="wIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="W"
                labelPlacement="bottom"
                checked={activeLSIScoreData.wIsMissed}
                value={activeLSIScoreData.wIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="eIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="E"
                labelPlacement="bottom"
                checked={activeLSIScoreData.eIsMissed}
                value={activeLSIScoreData.eIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="yIsMissed"
                    onChange={handleSwitchToggle}
                  />
                }
                label="Y"
                labelPlacement="bottom"
                checked={activeLSIScoreData.yIsMissed}
                value={activeLSIScoreData.yIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default LSIScorePanel;
