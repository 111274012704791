import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import { SystemSettingsContext } from "../../contexts/SystemSettingsContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a new Sight Words score (purpose === 'addNew')
2.) used to view / edit an existing Sight Words score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const IrregularWordsPanel = ({
  iwScoreSet = null,
  purpose = "view",
  setShowNewIWPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { systemSettings } = useContext(SystemSettingsContext);
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeIWScoreData, setActiveIWScoreData] = useState(null);
  const [originalIWScoreData, setOriginalIWScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalIWScoreData({ ...iwScoreSet });
      setActiveIWScoreData({ ...iwScoreSet });
    } else {
      setActiveIWScoreData(setInitialState());

      setIsEditing(true);
    }
  }, [iwScoreSet, purpose]);

  const setInitialState = () => {
    let addNewInitialState = {};
    addNewInitialState["score"] = 25;
    systemSettings.irregularWords.map(
      (irregularWord) =>
        (addNewInitialState[`${irregularWord}IsMissed`] = false)
    );
    return addNewInitialState;
  };

  const setUpdateState = () => {
    let updateInitialState = {};
    updateInitialState["score"] = 0;
    systemSettings.irregularWords.map(
      (irregularWord) => (updateInitialState[`${irregularWord}IsMissed`] = true)
    );
    return updateInitialState;
  };

  const saveNewIWScore = async () => {
    saveNewScore(activeIWScoreData, "iwScores");
    handleLoadingOpen();
    await setShowNewIWPanel(false);
    handleLoadingClose();
  };

  const editIWScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalIWScoreData, activeIWScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the Sight Words Score...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeIWScoreData, "iwScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteIWScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeIWScoreData, "iwScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveIWScoreData({
      ...activeIWScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeIWScoreData.score - 1
          : activeIWScoreData.score + 1,
    });
  };

  const handleToggle = () => {
    if (activeIWScoreData.score === 25) {
      setActiveIWScoreData(setUpdateState());
    } else {
      setActiveIWScoreData(setInitialState());
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewIWScore(activeIWScoreData);
    } else {
      editIWScore();
    }
  };

  const handleCancel = () => {
    setActiveIWScoreData(originalIWScoreData);
    setIsEditing(false);
  };

  return activeIWScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction="column" alignItems="flex-start" xs={6}>
          <Grid item>
            <Typography>Score: {activeIWScoreData.score}</Typography>
          </Grid>
          {activeIWScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeIWScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeIWScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeIWScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeIWScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeIWScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent="flex-end" xs={6}>
          {isEditing ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeIWScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeIWScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-iwscore"
                aria-describedby="alert-dialog-delete-iwscore-description"
              >
                <DialogTitle id="alert-dialog-delete-iwscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-iwscore-description">
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteIWScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby="alert-dialog-delete-iwscore"
                aria-describedby="alert-dialog-delete-iwscore-description"
              >
                <DialogTitle id="alert-dialog-delete-iwscore">
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-delete-iwscore-description">
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color="primary"
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteIWScore();
                      setDeleteAlertOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction="column">
        <Grid item container>
          <Grid item>
            <FormGroup row>
              {systemSettings && systemSettings.irregularWords ? (
                systemSettings.irregularWords.map((irregularWord) => (
                  <FormControlLabel
                    key={irregularWord}
                    control={
                      <StyledSwitch
                        name={`${irregularWord}IsMissed`}
                        onChange={handleSwitchToggle}
                      />
                    }
                    label={`${irregularWord}`}
                    labelPlacement="bottom"
                    checked={activeIWScoreData[`${irregularWord}IsMissed`]}
                    value={activeIWScoreData[`${irregularWord}IsMissed`]}
                    disabled={!isEditing}
                  />
                ))
              ) : (
                <span>Loading...</span>
              )}
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default IrregularWordsPanel;
