import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import LearnerDetailsPage from "./LearnerDetailsPage";
import { LearnerContext } from "../../contexts/LearnerContext";

const LearnerDetailsContainer = () => {
  const { learnerId } = useParams();
  const { currentLearner, setCurrentLearnerId } = useContext(LearnerContext);
  useEffect(() => {
    setCurrentLearnerId(learnerId);
    return () => {
      setCurrentLearnerId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerId]);
  return (
    <Container>
      {currentLearner ? (
        <LearnerDetailsPage currentLearner={currentLearner} />
      ) : (
        <p>Loading...</p>
      )}
    </Container>
  );
};

export default LearnerDetailsContainer;
