import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import { MessageProvider } from "./contexts/MessageContext";
import AuthProvider from "./contexts/AuthContext";
import { LearnerProvider } from "./contexts/LearnerContext";
import { TransactionProvider } from "./contexts/TransactionContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import App from "./App";
import { SystemSettingsProvider } from "./contexts/SystemSettingsContext";

const kbOrange = "#f9943b";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8C8C94",
      dark: "#485B5E",
      contrastText: "#ECEDF4",
    },
    secondary: {
      main: "#8BB394",
      light: "#C9DCD1",
      dark: "#486554",
      contrastText: "#FFF",
    },
    background: {
      default: "#ECEDF4",
    },
    common: {
      kbOrange: kbOrange,
    },
  },
  typography: {
    heroText: {
      fontFamily: "Architects Daughter",
      fontWeight: 900,
      fontSize: "100px",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // Until libraries are up to date for react, removing strict mode to stop console errors.
  //  <React.StrictMode>
  <>
    <CssBaseline />

    <MessageProvider>
      <AuthProvider>
        <LoadingProvider>
          <SystemSettingsProvider>
            <LearnerProvider>
              <TransactionProvider>
                <BrowserRouter>
                  <ThemeProvider theme={theme}>
                    <App />{" "}
                  </ThemeProvider>
                </BrowserRouter>
              </TransactionProvider>
            </LearnerProvider>
          </SystemSettingsProvider>
        </LoadingProvider>
      </AuthProvider>
    </MessageProvider>
  </>
  // </React.StrictMode>
);
