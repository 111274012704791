import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CancelButton = styled(Button)({
  background: "#FF3300",
  margin: "5px 5px",
  borderRadius: 3,
  "&:hover": {
    backgroundColor: "#CC0000",
  },
  color: "white",
});

export const DeleteButton = styled(Button)({
  background: "#CC3300",
  margin: "5px 5px",
  borderRadius: 3,
  "&:hover": {
    backgroundColor: "#FF3300",
  },
  color: "white",
});

export const EditButton = styled(Button)({
  background: "#0066FF",
  margin: "5px 5px",
  borderRadius: 3,
  "&:hover": {
    backgroundColor: "#66CCFF",
  },
  color: "white",
});

export const AddScoreButton = styled(Button)({
  borderRadius: 3,
  padding: "7px 10px",
  margin: "5px 5px",
  background: "#33CC00",
  "&:hover": {
    backgroundColor: "#66FF33",
  },
  color: "white",
});

export const SaveButton = styled(Button)({
  borderRadius: 3,
  margin: "5px 5px",
  background: "#339900",
  "&:hover": {
    backgroundColor: "#006600",
  },
  color: "white",
});
