import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import FineMotorPanel from "./FineMotorPanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { useContext, useEffect } from "react";
import { LearnerContext } from "../../contexts/LearnerContext";

const FineMotorContainer = ({
  fmScores,
  learnerId,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const [showNewFMPanel, setShowNewFMPanel] = useState(false);
  const { currentLearner } = useContext(LearnerContext);
  useEffect(() => {
    setShowNewFMPanel(false);
  }, [currentLearner]);
  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">Fine Motor Observations</Typography>
          </Grid>
          <Grid item>
            {showNewFMPanel ? (
              <CancelButton onClick={() => setShowNewFMPanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton onClick={() => setShowNewFMPanel(true)}>
                Add New Observation
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewFMPanel && (
          <Grid item container direction="column">
            <FineMotorPanel
              purpose={"addNew"}
              isScreener={currentLearner.teacherId}
              learnerId={learnerId}
              setShowNewFMPanel={setShowNewFMPanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {fmScores && fmScores.length ? (
          _.orderBy(fmScores, ["createdAt"], ["desc"]).map((fmScoreSet) => (
            <FineMotorPanel
              isScreener={currentLearner.teacherId}
              key={fmScoreSet.createdAt}
              learnerId={learnerId}
              fmScoreSet={fmScoreSet}
              purpose={"view"}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          ))
        ) : (
          <Typography>No Fine Motor Observations recorded yet...</Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default FineMotorContainer;
