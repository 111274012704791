import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import {
  Typography,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  Tooltip,
  Grid,
  Chip,
  Link,
} from "@mui/material";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useAuth } from "../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.kbOrange,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const NumberOverviewTable = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { currentUser } = useAuth();

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="roster overview table"
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="center">Learner</StyledTableCell>
            <StyledTableCell align="center">Counting</StyledTableCell>
            <StyledTableCell align="center">
              Number Identification
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {_.orderBy(currentUser.roster, ["lastName"], ["asc"]).map(
            (learner) => {
              let rReadinessStatusColor = "grey";
              let rReadinessDetails = "Undetermined";
              let nReadinessStatusColor = "grey";
              let nReadinessDetails = "Undetermined";

              // setup rReadiness details
              if (
                learner.latestReadingReadinessData &&
                learner.latestReadingReadinessData.createdAt
              ) {
                rReadinessDetails = `Score: ${
                  learner.latestReadingReadinessData.score
                }, determined ${formatDistanceToNow(
                  learner.latestReadingReadinessData.createdAt
                )} ago`;
              }
              if (
                learner.latestReadingReadinessData &&
                learner.latestReadingReadinessData.status === "u"
              ) {
                rReadinessStatusColor = "red";
              } else if (
                learner.latestReadingReadinessData &&
                learner.latestReadingReadinessData.status === "s"
              ) {
                rReadinessStatusColor = "green";
              }

              // setup nReadiness details
              if (
                learner.latestNumberReadinessData &&
                learner.latestNumberReadinessData.createdAt
              ) {
                nReadinessDetails = `Score: ${
                  learner.latestNumberReadinessData.score
                }, determined ${formatDistanceToNow(
                  learner.latestNumberReadinessData.createdAt
                )} ago`;
              }
              if (
                learner.latestNumberReadinessData &&
                learner.latestNumberReadinessData.status === "u"
              ) {
                nReadinessStatusColor = "red";
              } else if (
                learner.latestNumberReadinessData &&
                learner.latestNumberReadinessData.status === "s"
              ) {
                nReadinessStatusColor = "green";
              }
              return (
                <StyledTableRow key={learner.learnerId}>
                  <TableCell component="th" scope="row">
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      style={{
                        width: "16em",
                        backgroundColor: theme.palette.secondary.light,
                        padding: "7px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid item container align="center">
                        <Grid item>
                          <Link
                            component={RouterLink}
                            to={`/learner-details/${learner.learnerId}`}
                          >
                            <Typography
                              style={{ ...theme.typography.learnerLink }}
                            >
                              {learner.nickName
                                ? `"${learner.nickName}" ${learner.lastName}`
                                : `${learner.firstName} ${learner.lastName}`}
                            </Typography>
                          </Link>
                        </Grid>
                        {learner.IEPStatus &&
                          learner.IEPStatus.toLowerCase() === "y" && (
                            <Grid
                              item
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              <Chip
                                size="small"
                                label="I"
                                style={{
                                  backgroundColor: "blue",
                                  color: "white",
                                  width: "23px",
                                  height: "20px",
                                }}
                              />
                            </Grid>
                          )}
                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        style={{
                          backgroundColor: theme.palette.common.grey,
                          padding: "5px",
                          borderRadius: "10px",
                        }}
                      >
                        <Grid item container>
                          <Grid item>
                            <Typography style={{ fontSize: 14 }}>
                              Reading Readiness:
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginLeft: "5px" }}>
                            <Tooltip
                              title={rReadinessDetails}
                              placement="right"
                            >
                              <Chip
                                size="small"
                                style={{
                                  backgroundColor: rReadinessStatusColor,
                                  width: "14px",
                                  height: "14px",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid item container>
                          <Grid item>
                            <Typography style={{ fontSize: 14 }}>
                              Number Readiness:
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginLeft: "5px" }}>
                            <Tooltip
                              title={nReadinessDetails}
                              placement="right"
                            >
                              <Chip
                                size="small"
                                style={{
                                  backgroundColor: nReadinessStatusColor,
                                  width: "14px",
                                  height: "14px",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    {learner.countingScoresLatestScore ||
                    learner.countingScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.countingScoresLatestScoreDate
                        )} ago`}
                        placement="top"
                      >
                        <Typography>{`${learner.countingScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {learner.numIdScoresLatestScore ||
                    learner.numIdScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.numIdScoresLatestScoreDate
                        )} ago`}
                        placement="top"
                      >
                        <Typography>{`${learner.numIdScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </StyledTableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NumberOverviewTable;
