import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a new SPC score (purpose === 'addNew')
2.) used to view / edit an existing SPC score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: 26,
  dIsMissed: false,
  aIsMissed: false,
  tIsMissed: false,
  mIsMissed: false,
  cIsMissed: false,
  xIsMissed: false,
  zIsMissed: false,
  bIsMissed: false,
  lIsMissed: false,
  pIsMissed: false,
  vIsMissed: false,
  sIsMissed: false,
  kIsMissed: false,
  fIsMissed: false,
  jIsMissed: false,
  nIsMissed: false,
  gIsMissed: false,
  iIsMissed: false,
  oIsMissed: false,
  qIsMissed: false,
  hIsMissed: false,
  rIsMissed: false,
  uIsMissed: false,
  wIsMissed: false,
  eIsMissed: false,
  yIsMissed: false,
};

const upDateInitialState = {
  score: 0,
  dIsMissed: true,
  aIsMissed: true,
  tIsMissed: true,
  mIsMissed: true,
  cIsMissed: true,
  xIsMissed: true,
  zIsMissed: true,
  bIsMissed: true,
  lIsMissed: true,
  pIsMissed: true,
  vIsMissed: true,
  sIsMissed: true,
  kIsMissed: true,
  fIsMissed: true,
  jIsMissed: true,
  nIsMissed: true,
  gIsMissed: true,
  iIsMissed: true,
  oIsMissed: true,
  qIsMissed: true,
  hIsMissed: true,
  rIsMissed: true,
  uIsMissed: true,
  wIsMissed: true,
  eIsMissed: true,
  yIsMissed: true,
};

const SPCScorePanel = ({
  spcScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewSPCPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeSPCScoreData, setActiveSPCScoreData] = useState(null);
  const [originalSPCScoreData, setOriginalSPCScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalSPCScoreData({ ...spcScoreSet });
      setActiveSPCScoreData({ ...spcScoreSet });
    } else {
      setActiveSPCScoreData({ ...spcScoreSet });
      setIsEditing(true);
    }
  }, [spcScoreSet, purpose]);

  const saveNewSPCScore = async () => {
    saveNewScore(activeSPCScoreData, "spcScores");
    setShowNewSPCPanel(false);
  };

  const editSPCScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalSPCScoreData, activeSPCScoreData)) {
      setMessage("warning", `No changes were made to the SPC Score...`, 3000);
    } else {
      handleLoadingOpen();
      await editScore(activeSPCScoreData, "spcScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteSPCScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeSPCScoreData, "spcScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveSPCScoreData({
      ...activeSPCScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeSPCScoreData.score - 1
          : activeSPCScoreData.score + 1,
    });
  };

  const handleToggle = () => {
    if (activeSPCScoreData.score === 26) {
      setActiveSPCScoreData(upDateInitialState);
    } else {
      setActiveSPCScoreData(addNewInitialState);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewSPCScore(activeSPCScoreData);
    } else {
      editSPCScore();
    }
  };

  const handleCancel = () => {
    setActiveSPCScoreData(originalSPCScoreData);
    setIsEditing(false);
  };

  return activeSPCScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction='column' alignItems='flex-start' xs={6}>
          <Grid item>
            <Typography>Score: {activeSPCScoreData.score}</Typography>
          </Grid>
          {activeSPCScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeSPCScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeSPCScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeSPCScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeSPCScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeSPCScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent='flex-end' xs={6}>
          {isEditing ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeSPCScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeSPCScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-spcscore'
                aria-describedby='alert-dialog-delete-spcscore-description'
              >
                <DialogTitle id='alert-dialog-delete-spcscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-spcscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteSPCScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-spcscore'
                aria-describedby='alert-dialog-delete-spcscore-description'
              >
                <DialogTitle id='alert-dialog-delete-spcscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-spcscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteSPCScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction='column'>
        <Grid item container>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='dIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='D'
                labelPlacement='bottom'
                checked={activeSPCScoreData.dIsMissed}
                value={activeSPCScoreData.dIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='aIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='A'
                labelPlacement='bottom'
                checked={activeSPCScoreData.aIsMissed}
                value={activeSPCScoreData.aIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='tIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='T'
                labelPlacement='bottom'
                checked={activeSPCScoreData.tIsMissed}
                value={activeSPCScoreData.tIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='mIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='M'
                labelPlacement='bottom'
                checked={activeSPCScoreData.mIsMissed}
                value={activeSPCScoreData.mIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='cIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='C'
                labelPlacement='bottom'
                checked={activeSPCScoreData.cIsMissed}
                value={activeSPCScoreData.cIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='xIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='X'
                labelPlacement='bottom'
                checked={activeSPCScoreData.xIsMissed}
                value={activeSPCScoreData.xIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='zIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='Z'
                labelPlacement='bottom'
                checked={activeSPCScoreData.zIsMissed}
                value={activeSPCScoreData.zIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='bIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='B'
                labelPlacement='bottom'
                checked={activeSPCScoreData.bIsMissed}
                value={activeSPCScoreData.bIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='lIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='L'
                labelPlacement='bottom'
                checked={activeSPCScoreData.lIsMissed}
                value={activeSPCScoreData.lIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='pIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='P'
                labelPlacement='bottom'
                checked={activeSPCScoreData.pIsMissed}
                value={activeSPCScoreData.pIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='vIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='V'
                labelPlacement='bottom'
                checked={activeSPCScoreData.vIsMissed}
                value={activeSPCScoreData.vIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='sIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='S'
                labelPlacement='bottom'
                checked={activeSPCScoreData.sIsMissed}
                value={activeSPCScoreData.sIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='kIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='K'
                labelPlacement='bottom'
                checked={activeSPCScoreData.kIsMissed}
                value={activeSPCScoreData.kIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='fIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='F'
                labelPlacement='bottom'
                checked={activeSPCScoreData.fIsMissed}
                value={activeSPCScoreData.fIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='jIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='J'
                labelPlacement='bottom'
                checked={activeSPCScoreData.jIsMissed}
                value={activeSPCScoreData.jIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='nIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='N'
                labelPlacement='bottom'
                checked={activeSPCScoreData.nIsMissed}
                value={activeSPCScoreData.nIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='gIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='G'
                labelPlacement='bottom'
                checked={activeSPCScoreData.gIsMissed}
                value={activeSPCScoreData.gIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='iIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='I'
                labelPlacement='bottom'
                checked={activeSPCScoreData.iIsMissed}
                value={activeSPCScoreData.iIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='oIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='O'
                labelPlacement='bottom'
                checked={activeSPCScoreData.oIsMissed}
                value={activeSPCScoreData.oIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='qIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='Q'
                labelPlacement='bottom'
                checked={activeSPCScoreData.qIsMissed}
                value={activeSPCScoreData.qIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='hIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='H'
                labelPlacement='bottom'
                checked={activeSPCScoreData.hIsMissed}
                value={activeSPCScoreData.hIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='rIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='R'
                labelPlacement='bottom'
                checked={activeSPCScoreData.rIsMissed}
                value={activeSPCScoreData.rIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='uIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='U'
                labelPlacement='bottom'
                checked={activeSPCScoreData.uIsMissed}
                value={activeSPCScoreData.uIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='wIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='W'
                labelPlacement='bottom'
                checked={activeSPCScoreData.wIsMissed}
                value={activeSPCScoreData.wIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='eIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='E'
                labelPlacement='bottom'
                checked={activeSPCScoreData.eIsMissed}
                value={activeSPCScoreData.eIsMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='yIsMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='Y'
                labelPlacement='bottom'
                checked={activeSPCScoreData.yIsMissed}
                value={activeSPCScoreData.yIsMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default SPCScorePanel;
