import React from "react";
import { Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import LandingPage from "./LandingPage";

const LandingContainer = () => {
  const { currentUser } = useAuth();

  return (
    <Container>
      {currentUser && currentUser.role === "teacher" ? (
        <Navigate to="/teacher-landing" replace />
      ) : currentUser && currentUser.role === "interventionist" ? (
        <Navigate to="/interventionist-landing" replace />
      ) : currentUser && currentUser.role === "admin" ? (
        <Navigate to="/admin-landing" replace />
      ) : (
        <LandingPage />
      )}
    </Container>
  );
};

export default LandingContainer;
