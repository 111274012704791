import React, { Component } from "react";
import _ from "lodash";

class PrintableLearnerScreenerReport extends Component {
  render() {
    const {
      currentLearner,
      readingReadyScore,
      numberReadyScore,
      compiledLNUScores,
      compiledLNLScores,
      compiledLSIScores,
      compiledSWScores,
      compiledRhymIdScores,
      compiledRhymProdScores,
      compiledNumIdScores,
      compiledBehaviorScores,
      compiledCountingScores,
      compiledFmScores,
    } = this.props;

    return (
      <div style={{ pageBreakAfter: "always" }}>
        <h1>{`KinderBase Student Screening Report for ${currentLearner.firstName} ${currentLearner.lastName}`}</h1>
        Birthday:{" "}
        {new Date(currentLearner.birthday).toLocaleString().slice(0, 9)}{" "}
        <p>
          Recommend Kindergarten Camp:{" "}
          {currentLearner.campRecommend ? "Yes" : "No"}
        </p>
        <p>
          Kinderbase is a system that we use in the Aurora Schools to screen and
          track the progress of all learners and their early foundational
          skills. This report summarizes your child's screening results and
          shows you what we will be targeting at the beginning of kindergarten.
          This screening report is an opportunity to get a glimpse into what
          your child already knows and what we will target during the first
          months of their educational journey.
        </p>
        <h2>Reading Readiness Score: {readingReadyScore.score}/78</h2>
        {readingReadyScore.text}
        <h2>Letternames Uppercase</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Letters Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledLNUScores && compiledLNUScores.length ? (
              _.orderBy(compiledLNUScores, ["createdAt"], ["desc"]).map(
                (lnuScoreSet) => {
                  return (
                    <tr key={lnuScoreSet.createdAt}>
                      <td align="left">
                        {new Date(lnuScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{lnuScoreSet.score}</td>
                      <td align="left">{lnuScoreSet.missedLetters}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Letternames Lowercase</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Letters Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledLNLScores && compiledLNLScores.length ? (
              _.orderBy(compiledLNLScores, ["createdAt"], ["desc"]).map(
                (lnlScoreSet) => {
                  return (
                    <tr key={lnlScoreSet.createdAt}>
                      <td align="left">
                        {new Date(lnlScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{lnlScoreSet.score}</td>
                      <td align="left">{lnlScoreSet.missedLetters}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Letter Sounds</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Letters Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledLSIScores && compiledLSIScores.length ? (
              _.orderBy(compiledLSIScores, ["createdAt"], ["desc"]).map(
                (lsiScoreSet) => {
                  return (
                    <tr key={lsiScoreSet.createdAt}>
                      <td align="left">
                        {new Date(lsiScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{lsiScoreSet.score}</td>
                      <td align="left">{lsiScoreSet.missedLetters}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <h2>Sight Words</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Words Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledSWScores && compiledSWScores.length ? (
              _.orderBy(compiledSWScores, ["createdAt"], ["desc"]).map(
                (swScoreSet) => {
                  return (
                    <tr key={swScoreSet.createdAt}>
                      <td align="left">
                        {new Date(swScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{swScoreSet.score}</td>
                      <td align="left">{swScoreSet.missedWords}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table> */}
        <h2>Rhyming Identification Score: {compiledRhymIdScores[0].score}/7</h2>
        {compiledRhymIdScores[0].text}
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Word Combinations Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledRhymIdScores && compiledRhymIdScores.length ? (
              _.orderBy(compiledRhymIdScores, ["createdAt"], ["desc"]).map(
                (rhymIdScoreSet) => {
                  return (
                    <tr key={rhymIdScoreSet.createdAt}>
                      <td align="left">
                        {new Date(rhymIdScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{rhymIdScoreSet.score}</td>
                      <td align="left">{rhymIdScoreSet.missedWords}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Rhyming Production Score: {compiledRhymProdScores[0].score}/5</h2>
        {compiledRhymProdScores[0].text}
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Words Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledRhymProdScores && compiledRhymProdScores.length ? (
              _.orderBy(compiledRhymProdScores, ["createdAt"], ["desc"]).map(
                (rhymProdScoreSet) => {
                  return (
                    <tr key={rhymProdScoreSet.createdAt}>
                      <td align="left">
                        {new Date(
                          rhymProdScoreSet.createdAt
                        ).toLocaleDateString("en-US")}
                      </td>
                      <td align="left">{rhymProdScoreSet.score}</td>
                      <td align="left">{rhymProdScoreSet.missedWords}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Number Readiness Score: {numberReadyScore.score}/41</h2>
        {numberReadyScore.text}
        <h2>Number Identification</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Overall Score</th>
              <th align="left">Numbers Missed</th>
            </tr>
          </thead>
          <tbody>
            {compiledNumIdScores && compiledNumIdScores.length ? (
              _.orderBy(compiledNumIdScores, ["createdAt"], ["desc"]).map(
                (numIdScoreSet) => {
                  return (
                    <tr key={numIdScoreSet.createdAt}>
                      <td align="left">
                        {new Date(numIdScoreSet.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{numIdScoreSet.score}</td>
                      <td align="left">{numIdScoreSet.missedNumbers}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Number Counting</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Score</th>
              <th align="left">One to One Matching</th>
            </tr>
          </thead>
          <tbody>
            {compiledCountingScores && compiledCountingScores.length ? (
              _.orderBy(compiledCountingScores, ["createdAt"], ["desc"]).map(
                (CountingScoreSet) => {
                  return (
                    <tr key={CountingScoreSet.createdAt}>
                      <td align="left">
                        {new Date(
                          CountingScoreSet.createdAt
                        ).toLocaleDateString("en-US")}
                      </td>
                      <td align="left">{CountingScoreSet.score}</td>
                      <td align="left">
                        {CountingScoreSet.oneToOneMatching ? "Yes" : "No"}
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table>
        <h2>Behavior Observations Result: </h2>
        {compiledBehaviorScores[0].missed.length === 0
          ? compiledBehaviorScores[0].score
          : compiledBehaviorScores[0].missed}
        <h2>
          Fine Motor Ability Result: {compiledFmScores[0].isUnsatisfactory}
        </h2>
        {currentLearner.firstName} is{" "}
        {compiledFmScores[0].dominant.toLowerCase()} hand dominant.{" "}
        {compiledFmScores[0].text}
        {/* <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Dominant Hand</th>
              <th align="left">Result</th>
              <th align="left">Recommendations</th>
            </tr>
          </thead>
          <tbody>
            {compiledFmScores && compiledFmScores.length ? (
              _.orderBy(compiledFmScores, ["createdAt"], ["desc"]).map(
                (fmAbility) => {
                  return (
                    <tr key={fmAbility.createdAt}>
                      <td align="left">
                        {new Date(fmAbility.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{fmAbility.dominant}</td>
                      <td align="left">{fmAbility.isUnsatisfactory}</td>
                      <td align="left">{fmAbility.text}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table> */}
        {/* <h2>Comments from Screener:</h2>
        <table border="1px solid #dddddd" width="70%">
          <thead>
            <tr>
              <th align="left">Recorded</th>
              <th align="left">Comment</th>
            </tr>
          </thead>
          <tbody>
            {compiledComments && compiledComments.length ? (
              _.orderBy(compiledComments, ["createdAt"], ["desc"]).map(
                (comment) => {
                  return (
                    <tr key={comment.createdAt}>
                      <td align="left">
                        {new Date(comment.createdAt).toLocaleDateString(
                          "en-US"
                        )}
                      </td>
                      <td align="left">{comment.comment}</td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td>No scores have been collected yet.</td>
              </tr>
            )}
          </tbody>
        </table> */}
        <p>
          Parent/Guardian Signature: ________________________________________
        </p>{" "}
        <p>
          Date of Signature: _________________________________________________
        </p>
      </div>
    );
  }
}

export default PrintableLearnerScreenerReport;
