import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a new Rhyming Id score (purpose === 'addNew')
2.) used to view / edit an existing Rhyming Id score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: 7,
  dadSadMissed: false,
  houseMoonMissed: false,
  handCarMissed: false,
  pigBigMissed: false,
  beepDeepMissed: false,
  sunCakeMissed: false,
  bedFedMissed: false,
};

const upDateInitialState = {
  score: 0,
  dadSadMissed: true,
  houseMoonMissed: true,
  handCarMissed: true,
  pigBigMissed: true,
  beepDeepMissed: true,
  sunCakeMissed: true,
  bedFedMissed: true,
};

const RhymIdPanel = ({
  rhymIdScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewRhymIdPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeRhymIdScoreData, setActiveRhymIdScoreData] = useState(null);
  const [originalRhymIdScoreData, setOriginalRhymIdScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalRhymIdScoreData({ ...rhymIdScoreSet });
      setActiveRhymIdScoreData({ ...rhymIdScoreSet });
    } else {
      setActiveRhymIdScoreData({ ...rhymIdScoreSet });
      setIsEditing(true);
    }
  }, [rhymIdScoreSet]);

  const saveNewRhymIdScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeRhymIdScoreData, "rhymIdScores");
    handleLoadingClose();
    setShowNewRhymIdPanel(false);
  };

  const editRhymIdScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalRhymIdScoreData, activeRhymIdScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the RhymId Score...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeRhymIdScoreData, "rhymIdScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteRhymIdScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeRhymIdScoreData, "rhymIdScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveRhymIdScoreData({
      ...activeRhymIdScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeRhymIdScoreData.score - 1
          : activeRhymIdScoreData.score + 1,
    });
  };

  const handleToggle = () => {
    if (activeRhymIdScoreData.score === 7) {
      setActiveRhymIdScoreData(upDateInitialState);
    } else {
      setActiveRhymIdScoreData(addNewInitialState);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewRhymIdScore(activeRhymIdScoreData);
    } else {
      editRhymIdScore();
    }
  };

  const handleCancel = () => {
    setActiveRhymIdScoreData(originalRhymIdScoreData);
    setIsEditing(false);
  };

  return activeRhymIdScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction='column' alignItems='flex-start' xs={6}>
          <Grid item>
            <Typography>Score: {activeRhymIdScoreData.score}</Typography>
          </Grid>
          {activeRhymIdScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeRhymIdScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeRhymIdScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeRhymIdScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeRhymIdScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeRhymIdScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent='flex-end' xs={6}>
          {isEditing ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeRhymIdScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeRhymIdScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-rhymidscore'
                aria-describedby='alert-dialog-delete-rhymidscore-description'
              >
                <DialogTitle id='alert-dialog-delete-rhymidscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-rhymidscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteRhymIdScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-rhymidscore'
                aria-describedby='alert-dialog-delete-rhymidscore-description'
              >
                <DialogTitle id='alert-dialog-delete-rhymidscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-rhymidscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteRhymIdScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction='column'>
        <Grid item container>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='dadSadMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='dad/sad'
                labelPlacement='bottom'
                checked={activeRhymIdScoreData.dadSadMissed}
                value={activeRhymIdScoreData.dadSadMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='houseMoonMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='house/moon'
                labelPlacement='bottom'
                checked={activeRhymIdScoreData.houseMoonMissed}
                value={activeRhymIdScoreData.houseMoonMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='handCarMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='hand/car'
                labelPlacement='bottom'
                checked={activeRhymIdScoreData.handCarMissed}
                value={activeRhymIdScoreData.handCarMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='pigBigMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='pig/big'
                labelPlacement='bottom'
                checked={activeRhymIdScoreData.pigBigMissed}
                value={activeRhymIdScoreData.pigBigMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='beepDeepMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='beep/deep'
                labelPlacement='bottom'
                checked={activeRhymIdScoreData.beepDeepMissed}
                value={activeRhymIdScoreData.beepDeepMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='sunCakeMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='sun/cake'
                labelPlacement='bottom'
                checked={activeRhymIdScoreData.sunCakeMissed}
                value={activeRhymIdScoreData.sunCakeMissed}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    name='bedFedMissed'
                    onChange={handleSwitchToggle}
                  />
                }
                label='bed/fed'
                labelPlacement='bottom'
                checked={activeRhymIdScoreData.bedFedMissed}
                value={activeRhymIdScoreData.bedFedMissed}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default RhymIdPanel;
