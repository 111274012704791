import React, { Fragment, useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MessageContext } from "../../contexts/MessageContext";
import { TransactionContext } from "../../contexts/TransactionContext";
import {
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
} from "./StyledButtons";
import { currentSchoolYear } from "../../config/currentSchoolYear";

/*
This component has two potential uses:
1.) used to display a panel to create a new NumId score (purpose === 'addNew')
2.) used to view / edit an existing NumId score (purpose === 'view')
*/

const StyledSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
    "&$disabled": {
      color: "green",
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const addNewInitialState = {
  score: 21,
  missed2: false,
  missed4: false,
  missed6: false,
  missed7: false,
  missed9: false,
  missed10: false,
  missed0: false,
  missed3: false,
  missed5: false,
  missed1: false,
  missed8: false,
  missed12: false,
  missed14: false,
  missed11: false,
  missed13: false,
  missed15: false,
  missed17: false,
  missed19: false,
  missed16: false,
  missed18: false,
  missed20: false,
};

const upDateInitialState = {
  score: 0,
  missed2: true,
  missed4: true,
  missed6: true,
  missed7: true,
  missed9: true,
  missed10: true,
  missed0: true,
  missed3: true,
  missed5: true,
  missed1: true,
  missed8: true,
  missed12: true,
  missed14: true,
  missed11: true,
  missed13: true,
  missed15: true,
  missed17: true,
  missed19: true,
  missed16: true,
  missed18: true,
  missed20: true,
};

const NumIdScorePanel = ({
  numIdScoreSet = addNewInitialState,
  purpose = "view",
  setShowNewNumIdPanel = null,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { setMessage } = useContext(MessageContext);
  const { saveNewScore, editScore, deleteScore } =
    useContext(TransactionContext);

  const [isEditing, setIsEditing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activeNumIdScoreData, setActiveNumIdScoreData] = useState(null);
  const [originalNumIdScoreData, setOriginalNumIdScoreData] = useState(null);

  useEffect(() => {
    if (purpose === "view") {
      setOriginalNumIdScoreData({ ...numIdScoreSet });
      setActiveNumIdScoreData({ ...numIdScoreSet });
    } else {
      setActiveNumIdScoreData({ ...numIdScoreSet });
      setIsEditing(true);
    }
  }, [numIdScoreSet, purpose]);

  const saveNewNumIdScore = async () => {
    handleLoadingOpen();
    await saveNewScore(activeNumIdScoreData, "numIdScores");
    handleLoadingClose();
    setShowNewNumIdPanel(false);
  };

  const editNumIdScore = async () => {
    // let's make sure something was actually changed before doing anything
    if (_.isEqual(originalNumIdScoreData, activeNumIdScoreData)) {
      setMessage(
        "warning",
        `No changes were made to the Number Identification Score...`,
        3000
      );
    } else {
      handleLoadingOpen();
      await editScore(activeNumIdScoreData, "numIdScores");
      handleLoadingClose();
      setIsEditing(false);
    }
  };

  const deleteNumIdScore = async () => {
    handleLoadingOpen();
    await deleteScore(activeNumIdScoreData, "numIdScores");
    handleLoadingClose();
  };

  const handleSwitchToggle = (e) => {
    setActiveNumIdScoreData({
      ...activeNumIdScoreData,
      [e.target.name]: e.target.value === "false" ? true : false,
      score:
        e.target.value === "false"
          ? activeNumIdScoreData.score - 1
          : activeNumIdScoreData.score + 1,
    });
  };

  const handleToggle = () => {
    if (activeNumIdScoreData.score === 21) {
      setActiveNumIdScoreData(upDateInitialState);
    } else {
      setActiveNumIdScoreData(addNewInitialState);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (purpose === "addNew") {
      saveNewNumIdScore(activeNumIdScoreData);
    } else {
      editNumIdScore();
    }
  };

  const handleCancel = () => {
    setActiveNumIdScoreData(originalNumIdScoreData);
    setIsEditing(false);
  };

  return activeNumIdScoreData ? (
    <Fragment>
      {/* container for score block header */}
      <Grid item container>
        {/* container for score and date info */}
        <Grid item container direction='column' alignItems='flex-start' xs={6}>
          <Grid item>
            <Typography>Score: {activeNumIdScoreData.score}</Typography>
          </Grid>
          {activeNumIdScoreData.createdAt && (
            <Grid item>
              <Typography>
                Created:
                {new Date(activeNumIdScoreData.createdAt).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeNumIdScoreData.recordedByName}
              </Typography>
            </Grid>
          )}
          {activeNumIdScoreData.lastEdited && (
            <Grid item>
              <Typography>
                Last Edited:
                {new Date(activeNumIdScoreData.lastEdited).toLocaleDateString(
                  "en-US"
                )}{" "}
                by: {activeNumIdScoreData.lastEditedName}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* container for buttons */}
        <Grid item container justifyContent='flex-end' xs={6}>
          {isEditing ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <SaveButton onClick={handleSave}>Save</SaveButton>
              </Grid>
              {!activeNumIdScoreData.docRefId && (
                <Grid item>
                  <EditButton onClick={handleToggle}>Toggle</EditButton>
                </Grid>
              )}
              {purpose === "view" && (
                <Grid item style={{ marginLeft: "10px" }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
              )}
            </Grid>
          ) : activeNumIdScoreData.schoolYear === currentSchoolYear ? (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <EditButton onClick={handleEdit}>Edit</EditButton>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-numidscore'
                aria-describedby='alert-dialog-delete-numidscore-description'
              >
                <DialogTitle id='alert-dialog-delete-numidscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-numidscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteNumIdScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : (
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <DeleteButton onClick={() => setDeleteAlertOpen(true)}>
                  Delete
                </DeleteButton>
              </Grid>
              <Dialog
                open={deleteAlertOpen}
                onClose={() => setDeleteAlertOpen(false)}
                aria-labelledby='alert-dialog-delete-numidscore'
                aria-describedby='alert-dialog-delete-numidscore-description'
              >
                <DialogTitle id='alert-dialog-delete-numidscore'>
                  {"Friendly Warning..."}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-delete-numidscore-description'>
                    You are about to blow away this score in a forever kind of
                    way. Take a deep breath, are you sure about this?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setDeleteAlertOpen(false)}
                    color='primary'
                  >
                    Whoops!
                  </Button>
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      deleteNumIdScore();
                      setDeleteAlertOpen(false);
                    }}
                    color='primary'
                    autoFocus
                  >
                    Blow It Away!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* container for granular metric components */}
      <Grid item container direction='column'>
        <Grid item container>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch name='missed2' onChange={handleSwitchToggle} />
                }
                label='2'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed2}
                value={activeNumIdScoreData.missed2}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed4' onChange={handleSwitchToggle} />
                }
                label='4'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed4}
                value={activeNumIdScoreData.missed4}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed6' onChange={handleSwitchToggle} />
                }
                label='6'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed6}
                value={activeNumIdScoreData.missed6}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed7' onChange={handleSwitchToggle} />
                }
                label='7'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed7}
                value={activeNumIdScoreData.missed7}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch name='missed9' onChange={handleSwitchToggle} />
                }
                label='9'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed9}
                value={activeNumIdScoreData.missed9}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed10' onChange={handleSwitchToggle} />
                }
                label='10'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed10}
                value={activeNumIdScoreData.missed10}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed0' onChange={handleSwitchToggle} />
                }
                label='0'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed0}
                value={activeNumIdScoreData.missed0}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed3' onChange={handleSwitchToggle} />
                }
                label='3'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed3}
                value={activeNumIdScoreData.missed3}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch name='missed5' onChange={handleSwitchToggle} />
                }
                label='5'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed5}
                value={activeNumIdScoreData.missed5}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed1' onChange={handleSwitchToggle} />
                }
                label='1'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed1}
                value={activeNumIdScoreData.missed1}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed8' onChange={handleSwitchToggle} />
                }
                label='8'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed8}
                value={activeNumIdScoreData.missed8}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed12' onChange={handleSwitchToggle} />
                }
                label='12'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed12}
                value={activeNumIdScoreData.missed12}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch name='missed14' onChange={handleSwitchToggle} />
                }
                label='14'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed14}
                value={activeNumIdScoreData.missed14}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed11' onChange={handleSwitchToggle} />
                }
                label='11'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed11}
                value={activeNumIdScoreData.missed11}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed13' onChange={handleSwitchToggle} />
                }
                label='13'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed13}
                value={activeNumIdScoreData.missed13}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed15' onChange={handleSwitchToggle} />
                }
                label='15'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed15}
                value={activeNumIdScoreData.missed15}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch name='missed17' onChange={handleSwitchToggle} />
                }
                label='17'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed17}
                value={activeNumIdScoreData.missed17}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed19' onChange={handleSwitchToggle} />
                }
                label='19'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed19}
                value={activeNumIdScoreData.missed19}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed16' onChange={handleSwitchToggle} />
                }
                label='16'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed16}
                value={activeNumIdScoreData.missed16}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={
                  <StyledSwitch name='missed18' onChange={handleSwitchToggle} />
                }
                label='18'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed18}
                value={activeNumIdScoreData.missed18}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <StyledSwitch name='missed20' onChange={handleSwitchToggle} />
                }
                label='20'
                labelPlacement='bottom'
                checked={activeNumIdScoreData.missed20}
                value={activeNumIdScoreData.missed20}
                disabled={!isEditing}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default NumIdScorePanel;
