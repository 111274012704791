import React from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import {
  Typography,
  Grid,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  Table,
  TableRow,
  Tooltip,
  Link,
  Chip,
} from "@mui/material";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useAuth } from "../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  table: {},
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.kbOrange,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ReadingOverviewTable = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { currentUser } = useAuth();

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label='roster overview table'
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align='center'>Learner</StyledTableCell>
            <StyledTableCell align='center'>UC Letters</StyledTableCell>
            <StyledTableCell align='center'>LC Letters</StyledTableCell>
            <StyledTableCell align='center'>Picture Cues</StyledTableCell>
            <StyledTableCell align='center'>Sight Words</StyledTableCell>
            <StyledTableCell align='center'>Letter Sounds</StyledTableCell>
            <StyledTableCell align='center'>Rhyming Id</StyledTableCell>
            <StyledTableCell align='center'>Rhyming Prod</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {_.orderBy(currentUser.roster, ["lastName"], ["asc"]).map(
            (learner) => {
              let rReadinessStatusColor = "grey";
              let rReadinessDetails = "Undetermined";
              let nReadinessStatusColor = "grey";
              let nReadinessDetails = "Undetermined";

              // setup rReadiness details
              if (
                learner.latestReadingReadinessData &&
                learner.latestReadingReadinessData.createdAt
              ) {
                rReadinessDetails = `Score: ${
                  learner.latestReadingReadinessData.score
                }, determined ${formatDistanceToNow(
                  learner.latestReadingReadinessData.createdAt
                )} ago`;
              }
              if (
                learner.latestReadingReadinessData &&
                learner.latestReadingReadinessData.status === "u"
              ) {
                rReadinessStatusColor = "red";
              } else if (
                learner.latestReadingReadinessData &&
                learner.latestReadingReadinessData.status === "s"
              ) {
                rReadinessStatusColor = "green";
              }

              // setup nReadiness details
              if (
                learner.latestNumberReadinessData &&
                learner.latestNumberReadinessData.createdAt
              ) {
                nReadinessDetails = `Score: ${
                  learner.latestNumberReadinessData.score
                }, determined ${formatDistanceToNow(
                  learner.latestNumberReadinessData.createdAt
                )} ago`;
              }
              if (
                learner.latestNumberReadinessData &&
                learner.latestNumberReadinessData.status === "u"
              ) {
                nReadinessStatusColor = "red";
              } else if (
                learner.latestNumberReadinessData &&
                learner.latestNumberReadinessData.status === "s"
              ) {
                nReadinessStatusColor = "green";
              }
              return (
                <StyledTableRow key={learner.learnerId}>
                  <TableCell component='th' scope='row'>
                    <Grid
                      container
                      direction='column'
                      justifyContent='center'
                      style={{
                        width: "16em",
                        backgroundColor: theme.palette.secondary.light,
                        padding: "7px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid item container align='center'>
                        <Grid item>
                          <Link
                            component={RouterLink}
                            to={`/learner-details/${learner.learnerId}`}
                          >
                            <Typography
                              style={{ ...theme.typography.learnerLink }}
                            >
                              {learner.nickName
                                ? `"${learner.nickName}" ${learner.lastName}`
                                : `${learner.firstName} ${learner.lastName}`}
                            </Typography>
                          </Link>
                        </Grid>
                        {learner.IEPStatus &&
                          learner.IEPStatus.toLowerCase() === "y" && (
                            <Grid item style={{ marginLeft: "10px" }}>
                              <Chip
                                size='small'
                                label='I'
                                style={{
                                  backgroundColor: "blue",
                                  color: "white",
                                  width: "23px",
                                  height: "20px",
                                }}
                              />
                            </Grid>
                          )}
                      </Grid>
                      <Grid
                        item
                        container
                        direction='column'
                        justifyContent='center'
                        style={{
                          backgroundColor: theme.palette.common.grey,
                          padding: "5px",
                          borderRadius: "10px",
                        }}
                      >
                        <Grid item container>
                          <Grid item>
                            <Typography style={{ fontSize: 14 }}>
                              Reading Readiness:
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginLeft: "5px" }}>
                            <Tooltip
                              title={rReadinessDetails}
                              placement='right'
                            >
                              <Chip
                                size='small'
                                style={{
                                  backgroundColor: rReadinessStatusColor,
                                  width: "14px",
                                  height: "14px",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid item container>
                          <Grid item>
                            <Typography style={{ fontSize: 14 }}>
                              Number Readiness:
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginLeft: "5px" }}>
                            <Tooltip
                              title={nReadinessDetails}
                              placement='right'
                            >
                              <Chip
                                size='small'
                                style={{
                                  backgroundColor: nReadinessStatusColor,
                                  width: "14px",
                                  height: "14px",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='center'>
                    {learner.lnuScoresLatestScore ||
                    learner.lnuScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.lnuScoresLatestScoreDate
                        )} ago`}
                        placement='top'
                      >
                        <Typography>{`${learner.lnuScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {learner.lnlScoresLatestScore ||
                    learner.lnlScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.lnlScoresLatestScoreDate
                        )} ago`}
                        placement='top'
                      >
                        <Typography>{`${learner.lnlScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {learner.spcScoresLatestScore ||
                    learner.spcScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.spcScoresLatestScoreDate
                        )} ago`}
                        placement='top'
                      >
                        <Typography>{`${learner.spcScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {learner.swScoresLatestScore ||
                    learner.swScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.swScoresLatestScoreDate
                        )} ago`}
                        placement='top'
                      >
                        <Typography>{`${learner.swScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {learner.lsiScoresLatestScore ||
                    learner.lsiScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.lsiScoresLatestScoreDate
                        )} ago`}
                        placement='top'
                      >
                        <Typography>{`${learner.lsiScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {learner.rhymIdScoresLatestScore ||
                    learner.rhymIdScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.rhymIdScoresLatestScoreDate
                        )} ago`}
                        placement='top'
                      >
                        <Typography>{`${learner.rhymIdScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {learner.rhymProdScoresLatestScore ||
                    learner.rhymProdScoresLatestScore === 0 ? (
                      <Tooltip
                        title={`${formatDistanceToNow(
                          learner.rhymProdScoresLatestScoreDate
                        )} ago`}
                        placement='top'
                      >
                        <Typography>{`${learner.rhymProdScoresLatestScore}`}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </StyledTableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReadingOverviewTable;
