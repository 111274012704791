import React, { useContext } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { LoadingContext } from "../contexts/LoadingContext";

const WorkingIndicator = () => {
  const { isLoading } = useContext(LoadingContext);

  return (
    <Dialog open={isLoading}>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item>
          <DialogTitle id="working-dialog-title">Working on it...</DialogTitle>
        </Grid>
        <Grid item>
          <DialogContent>
            <CircularProgress color="secondary" />
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default WorkingIndicator;
