import React, { Fragment, useState, useContext, useEffect } from "react";
import { Container, Typography, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import _ from "lodash";
import { Autocomplete } from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { TransactionContext } from "../../../contexts/TransactionContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { LearnerContext } from "../../../contexts/LearnerContext";
import LearnerDetailsPage from "../LearnerDetailsPage";

const InterventionistLandingPage = () => {
  const { currentUser } = useAuth();
  const { setIsLoading } = useContext(LoadingContext);
  const { getAllLearners } = useContext(TransactionContext);
  const { currentLearner, setCurrentLearnerId } = useContext(LearnerContext);
  const [selectedLearner, setSelectedLearner] = useState(null);
  const [learnerList, setLearnerList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetchAllLearners = async () => {
    setIsLoading(true);
    setSelectedLearner(null);
    setCurrentLearnerId(null);
    const learners = await getAllLearners();
    const listing = learners.map((learner) => {
      return {
        learnerName: `${learner.lastName}, ${learner.firstName}`,
        learnerId: learner.learnerId,
      };
    });
    setIsLoading(false);
    setLearnerList(_.orderBy(listing, ["lastName"], ["asc"]));
  };

  useEffect(() => {
    if (currentUser && currentUser.role === "interventionist") {
      fetchAllLearners();
    }
  }, []);

  const handleLearnerSelect = (event, newLearner) => {
    if (newLearner) {
      setCurrentLearnerId(newLearner.learnerId);
    } else setCurrentLearnerId(null);
    setSelectedLearner(newLearner);
  };
  return currentUser && currentUser.role === "interventionist" ? (
    <Container>
      <Grid item container direction='column'>
        <Grid item style={{ marginTop: "65px" }}>
          <Typography variant='h2'>
            Interventionist Learner Selection
          </Typography>
        </Grid>
        {currentUser && (learnerList.length || currentUser.learnerDirectory) ? (
          <Fragment>
            <Grid item style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Autocomplete
                id='learners'
                value={selectedLearner}
                onChange={handleLearnerSelect}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={
                  currentUser.learnerDirectory
                    ? currentUser.learnerDirectory
                    : learnerList
                }
                renderOption={(props, option) => (
                  <Box component='li' {...props} key={option.learnerId}>
                    {option.learnerName}
                  </Box>
                )}
                getOptionLabel={(option) => option.learnerName}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select a Learner'
                    variant='outlined'
                  />
                )}
              />
            </Grid>
            {currentLearner && (
              <Grid item container>
                <LearnerDetailsPage currentLearner={currentLearner} />
              </Grid>
            )}
          </Fragment>
        ) : (
          <Typography component='p'>No Data to Load...</Typography>
        )}
      </Grid>
    </Container>
  ) : (
    <Container>
      <Grid container style={{ marginTop: "80px" }}>
        <Grid item>
          <Typography variant='h3'>Building Your Profile</Typography>
        </Grid>
        <Grid item style={{ marginTop: "30px" }}>
          <Typography variant='body1'>
            If this is the first time logging in, give us another few seconds
            while we build your profile. If after 15 seconds you don't see a
            roster, contact support by emailing integration@aurora-schools.org
            when able.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InterventionistLandingPage;
