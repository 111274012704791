import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import SGWHPanel from "./SGWHPanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { useContext, useEffect } from "react";
import { LearnerContext } from "../../contexts/LearnerContext";

const SGWHContainer = ({
  sgwhScores,
  learnerId,
  handleLoadingOpen,
  handleLoadingClose,
}) => {
  const { currentLearner } = useContext(LearnerContext);
  const [showNewSGWHPanel, setShowNewSGWHPanel] = useState(false);
  useEffect(() => {
    setShowNewSGWHPanel(false);
  }, [currentLearner]);

  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">
              Social Growth and Work Habits Observations
            </Typography>
          </Grid>
          <Grid item>
            {showNewSGWHPanel ? (
              <CancelButton onClick={() => setShowNewSGWHPanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton onClick={() => setShowNewSGWHPanel(true)}>
                Add New Observation
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewSGWHPanel && (
          <Grid item container direction="column">
            <SGWHPanel
              purpose={"addNew"}
              learnerId={learnerId}
              setShowNewSGWHPanel={setShowNewSGWHPanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {sgwhScores && sgwhScores.length ? (
          _.orderBy(sgwhScores, ["createdAt"], ["desc"]).map((sgwhScoreSet) => (
            <SGWHPanel
              key={sgwhScoreSet.createdAt}
              learnerId={learnerId}
              sgwhScoreSet={sgwhScoreSet}
              purpose={"view"}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          ))
        ) : (
          <Typography>No SGWH Observations recorded yet...</Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default SGWHContainer;
