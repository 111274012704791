import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/HomeRounded";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {
  Avatar,
  CircularProgress,
  SwipeableDrawer,
  Collapse,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import GoogleIcon from "@mui/icons-material/Google";
import LogoutIcon from "@mui/icons-material/Logout";
import CollectionsIcon from "@mui/icons-material/Collections";

const useStyles = makeStyles((theme) => {
  return {
    authButton: {
      minWidth: "10em",
      backgroundColor: theme.palette.common.grey,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.common.googleBlue,
      },
      borderRadius: "50px",
      marginLeft: "30px",
      marginRight: "20px",
    },
    toolbarMargin: {
      ...theme.mixins.toolbar,
      marginBottom: "4em",
      [theme.breakpoints.down("md")]: { marginBottom: "3em" },
      [theme.breakpoints.down("xs")]: { marginBottom: "2em" },
    },
    drawer: {
      backgroundColor: theme.palette.common.darkPurple,
    },
    drawerListItem: {
      ...theme.typography.tab,
      color: "black",
      opacity: 0.7,
    },
    drawerIconContainer: {
      marginLeft: "auto",
    },
    drawerIcon: {
      height: "50px",
      width: "50px",
    },
    drawerItemNested: {
      ...theme.typography.tab,
      color: "white",
      opacity: 0.7,
      paddingLeft: theme.spacing(4),
    },
  };
});

const NavDrawer = ({
  handleDrawerClose,
  handleDrawerMenuItemClick,
  openDrawer,
  setOpenDrawer,
  drawerItemExpanded,
}) => {
  const navigate = useNavigate();
  const { googleSignIn, googleSignOut, currentUser } = useAuth();
  const classes = useStyles();
  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(!isDrawerOpen);
  };

  const list = () => (
    <Box
      role='presentation'
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <ListItem
        divider
        button
        className={classes.drawerListItem}
        onClick={handleDrawerClose}
        component={NavLink}
        to='/'
      >
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary='Home' />
      </ListItem>
      <ListItem
        divider
        button
        className={classes.drawerListItem}
        onClick={handleDrawerMenuItemClick}
      >
        <ListItem
          divider
          button
          className={classes.drawerListItem}
          onClick={handleDrawerClose}
          component={NavLink}
          to='/learner-progress-report'
        >
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText disableTypography primary='Progress Reports' />
        </ListItem>
        <ListItem
          divider
          button
          className={classes.drawerListItem}
          onClick={handleDrawerMenuItemClick}
        ></ListItem>
      </ListItem>
      <ListItem
        divider
        button
        className={classes.drawerListItem}
        onClick={handleDrawerMenuItemClick}
      >
        <ListItem
          divider
          button
          className={classes.drawerListItem}
          onClick={handleDrawerClose}
          component={NavLink}
          to='/learner-screening-selector'
        >
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText disableTypography primary='Screening' />
        </ListItem>
        <ListItem
          divider
          button
          className={classes.drawerListItem}
          onClick={handleDrawerMenuItemClick}
        ></ListItem>
      </ListItem>
    </Box>
  );

  return (
    <Grid item>
      <Button variant='contianed' color='secondary' onClick={toggleDrawer()}>
        <MenuIcon />
      </Button>
      <Drawer anchor='right' open={isDrawerOpen} onClose={toggleDrawer()}>
        {list()}
      </Drawer>
    </Grid>
  );
};

export default NavDrawer;
