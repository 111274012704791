import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography, Paper } from "@mui/material";
import CountingScorePanel from "./CountingScorePanel";
import { CancelButton, AddScoreButton } from "./StyledButtons";
import { LearnerContext } from "../../contexts/LearnerContext";
import { useContext } from "react";
import { useEffect } from "react";

const CountingScoreContainer = ({
  countingScores,
  learnerId,
  handleLoadingClose,
  handleLoadingOpen,
}) => {
  const [showNewCountingScorePanel, setShowNewCountingScorePanel] =
    useState(false);
  const { currentLearner } = useContext(LearnerContext);

  useEffect(() => {
    setShowNewCountingScorePanel(false);
  }, [currentLearner]);

  return (
    <Grid item container direction="column">
      <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          <Grid item>
            <Typography variant="h5">Counting Scores</Typography>
          </Grid>
          <Grid item>
            {showNewCountingScorePanel ? (
              <CancelButton onClick={() => setShowNewCountingScorePanel(false)}>
                Cancel
              </CancelButton>
            ) : (
              <AddScoreButton
                onClick={() => setShowNewCountingScorePanel(true)}
              >
                Add Counting Score
              </AddScoreButton>
            )}
          </Grid>
        </Grid>
        {showNewCountingScorePanel && (
          <Grid item container direction="column">
            <CountingScorePanel
              purpose={"addNew"}
              learnerId={learnerId}
              setShowNewCountingScorePanel={setShowNewCountingScorePanel}
              handleLoadingOpen={handleLoadingOpen}
              handleLoadingClose={handleLoadingClose}
            />
          </Grid>
        )}
        {countingScores && countingScores.length ? (
          _.orderBy(countingScores, ["createdAt"], ["desc"]).map(
            (countingScoreSet) => (
              <CountingScorePanel
                key={countingScoreSet.createdAt}
                learnerId={learnerId}
                countingScoreSet={countingScoreSet}
                purpose={"view"}
                handleLoadingOpen={handleLoadingOpen}
                handleLoadingClose={handleLoadingClose}
              />
            )
          )
        ) : (
          <Typography>No Counting Scores recorded yet...</Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default CountingScoreContainer;
